import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

const UpdatePassword = () => {
  // all state

  // user State
  const { user, error } = useSelector((state) => ({
    ...state.user,
  }));
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();
  // error state


  // ref
  const passwordRef = useRef();
  const newPasswordRefRef = useRef();
  const confirmPasswordRef = useRef();

  // navigate

  const handleUpdatePassword = async () => {
    
    const user_profile_id = user[0]?.data?.user_profile_id
    const email = user[0]?.data?.email
    const password = passwordRef.current.value;
    const new_password = newPasswordRefRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    if (new_password !== confirmPassword) {
      toast.error("Your New password And Confirm Password Not Match");
    } else {
      if (password.length) {
        if (new_password.length < 8) {
          toast.error("New password Must be 8 Char");
        } else {
          const updatedPasswords = {email,user_profile_id, password, new_password };
          fetch(
            "https://kdaback.nescostore.com/api/pass-update",
            await handlePostApiHeader(updatedPasswords)
          )
            .then((res) => res.json())
            .then(async (data) => {
               
              const flag = await handleUpdateResponse(data, "/editprofile");
              if (flag) {
                toast.success("Password updated Successfully");
              }
            })

            .catch((error) => {
              console.log("error:", error);
            });
        }
      } else {
        toast.error("Old password Can Not Be empty");
      }
    }
  };

  return (
    <div>
      <h2 className="text-xl text-center my-4 font-semibold">
        Update Password
      </h2>
      <div className="mx-auto ">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Password</span>
            <input
              type="password"
              placeholder="Old Password"
              className="input w-4/6 input-bordered"
              ref={passwordRef}
            />
          </label>
        </div>

        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">New Password</span>
            <input
              type="password"
              placeholder="New Password"
              className="input w-4/6 input-bordered"
              ref={newPasswordRefRef}
            />
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Confirm Password</span>
            <input
              type="password"
              placeholder="New Password"
              className="input w-4/6 input-bordered"
              ref={confirmPasswordRef}
            />
          </label>
        </div>
      </div>
      <button
        className="btn btn-primary my-10 mx-auto lg:w-44"
        onClick={handleUpdatePassword}
      >
        Update Password
      </button>
    </div>
  );
};

export default UpdatePassword;
