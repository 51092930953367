import React, { useRef, useState } from 'react';
import { usePostApiHeader, usePostApiResponse } from '../../helper/customHook';

const AddLocation = () => {
    // all state
    // post api 
    const [handlePostApiHeader] = usePostApiHeader()
    const [handlePostResponse] = usePostApiResponse()

    const [locationNameError, setLocationNameError] = useState("");
  
    // ref
    const locationNameRef = useRef();
    const activityRef = useRef()

  
    const handleLocationName = () => {
      const locationName = locationNameRef.current.value;
      if (locationName.length) {
        setLocationNameError("");
      } else {
        setLocationNameError("This Field can not be Empty");
      }
      
    };
    const handleAddLocation = async () => {
      const trip_location_name = locationNameRef.current.value;
      const is_active = activityRef.current.value;
      const newLocation = {trip_location_name,is_active}
      fetch("https://kdaback.nescostore.com/api/store-trip-location", await handlePostApiHeader(newLocation) )
        .then((res) => res.json())
        .then((data) => handlePostResponse(data,"/alllocation"))
        .catch((error) => {
          console.log("error:",error);
        });

      
    }
    return (
      <div>
        <h2 className="text-xl my-4 font-semibold">Add a Location</h2>
        <div className="mx-auto">
          <div className="form-control my-3">
            <label className="input-group">
              <span className="w-2/6">Location Name</span>
              <input
                type="text"
                placeholder="Khulna"
                className="input w-4/6 input-bordered"
                ref={locationNameRef}
                onBlur={handleLocationName}
              />
            </label>
          </div>
          {locationNameError && (
            <div className="alert shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <span>{locationNameError}</span>
              </div>
            </div>
          )}
          <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Activity</span>
            <select
              className="select select-bordered w-4/6"
              ref={activityRef}
            >
              <option value="1">
                Active
              </option>
              
              <option value="0">
                Not Active
              </option>
              
            </select>
          </label>
        </div>
        </div>
        <button className="btn btn-primary my-10 mx-auto lg:w-44" onClick={handleAddLocation}>
          Add Location
        </button>
      </div>
    );
  };

export default AddLocation;