import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    useGetApiHeader,
    useLogout
} from "../../helper/customHook";
import Loading from "../Utility/Loading";
import AllRefuelRequest from "./AllRefuelRequest";

const CompletedReFuelRequest = () => {
    // user state
    const { user, loading, error } = useSelector((state) => ({
      ...state.user,
    }));
  // get Header State
  const [getApiHeader] = useGetApiHeader();


  const [logout] = useLogout();

  // all State

  const [refuelRequests, setRefuelRequests] = useState([]);
  const [refuelRequestLoading, setRefuelRequestLoading] = useState(true);

  useEffect(() => {
    if (user[0]?.data?.staff_cat_id === "1") {
      fetch(
        `https://kdaback.nescostore.com/api/all-refuelling-request`,
        getApiHeader
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data?.message) {
            setRefuelRequests(data);
            setRefuelRequestLoading(false);
          } else {
            logout();
          }
        });
    } else {
      fetch(
        `https://kdaback.nescostore.com/api/driverWise-refuelling-request/${user[0]?.data?.user_profile_id}`,
        getApiHeader
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data?.message) {
            setRefuelRequests(data);
            setRefuelRequestLoading(false);
          } else {
            logout();
          }
        });
    }
  }, [user[0]?.data?.staff_cat_id]);

  if(refuelRequestLoading){
    return <Loading></Loading>
  }
  else{
  return (
    <div>
        <AllRefuelRequest refuelRequests={refuelRequests.filter(r=> r.status==="3")} setRefuelRequests={setRefuelRequests} pageTitle="Completed Request"></AllRefuelRequest>
    </div>
  );
  }
};

export default CompletedReFuelRequest;
