import React from "react";

const Footer = () => {
  return (
    <div className="w-full flex">
      <div className="w-2/12"> </div>
      <footer class="footer footer-center w-10/12 p-4 text-base-content my-2 text-center">
        <div>
          
          <p>
            Copyright © {new Date().getFullYear()} - Powered By Unicorn Software
            and Solutions{" "}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
