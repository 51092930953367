import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGetApiHeader } from "../../helper/customHook";
import Loading from "../Utility/Loading";
import AllCarSchedule from "./AllCarSchedule";

const CompletedSchedule = () => {
  // all state
  // user State
  const { user, error } = useSelector((state) => ({
    ...state.user,
  }));

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // Schedule

  const [allSchedules, setAllSchedules] = useState([]);

  // loading State
  const [loading, setLoading] = useState(true);

  // all use Effect

  useEffect(() => {
    // for admin and chaiman
    const userId = user[0].data.user_profile_id;
    setLoading(true);
    if (
      user[0].data.approval_level === "2" ||
      user[0].data.approval_level === "1"
    ) {
      fetch("https://kdaback.nescostore.com/api/all-trip-infos", getApiHeader)
        .then((res) => res.json())
        .then((data) => {
          setAllSchedules(data);
          setLoading(false);
        });
    }
    // for officer
    else if (
      user[0].data.approval_level === "3" &&
      user[0].data.staff_cat_id === "1"
    ) {
      fetch(
        `https://kdaback.nescostore.com/api/all-trip-infos-officer/${userId}`,
        getApiHeader
      )
        .then((res) => res.json())
        .then((data) => {
          setAllSchedules(data);
          setLoading(false);
        });
    }
    // for driver
    else if (
      user[0].data.approval_level === "3" &&
      user[0].data.staff_cat_id === "2"
    ) {
      fetch(
        `https://kdaback.nescostore.com/api/all-trip-infos-driver/${userId}`,
        getApiHeader
      )
        .then((res) => res.json())
        .then((data) => {
          setAllSchedules(data);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <Loading></Loading>;
  } else {
    return (
      <div>
        <AllCarSchedule
          setAllSchedules={setAllSchedules}
          pageTitle = "All Completed Trips"
          allSchedules={allSchedules.filter(
            (sch) => sch.trip_status_id === "6"
          )}
        ></AllCarSchedule>
      </div>
    );
  }
};

export default CompletedSchedule;
