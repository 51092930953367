import React, { useRef, useState } from "react";

const AddModelYear = () => {
  // all state
  const [modelYearError, setModelYearError] = useState("");

  // ref
  const modelYearRef = useRef();

  const handleModelYear = () => {
    let modelYear = modelYearRef.current.value;
    if (modelYear.length) {
      if (isNaN(modelYear)) {
        setModelYearError("Model Year must be a number");
      } else {
        modelYear = parseInt(modelYear);
        if (modelYear > 1900 && modelYear < 2025) {
          setModelYearError("");
        } else {
          setModelYearError("This is not a valid model year");
        }
      }
    } else {
      setModelYearError("This Field can not be Empty");
    }
  };
  return (
    <div>
        <h2 className="text-xl my-4 font-semibold">Add a New Model Year</h2>
      <div className="mx-auto lg:w-1/2">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Model Year</span>
            <input
              type="text"
              placeholder="Ex: 2011"
              className="input w-4/6 input-bordered"
              ref={modelYearRef}
              onBlur={handleModelYear}
            />
          </label>
        </div>
        {modelYearError && (
          <div className="alert shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{modelYearError}</span>
            </div>
          </div>
        )}

      </div>
      <button className="btn btn-primary my-10 mx-auto lg:w-44">ADD Model Year</button>

    </div>
  );
};

export default AddModelYear;
