import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

const AllModel = () => {
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // update api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  // data State
  const [brandOptions, setBrandOptions] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState([]);
  // field State
  const [modalField, setModalField] = useState(false);
  // loading State
  const [brandOptionLoading, setBrandOptionsLoading] = useState(true);
  const [modelLoading, setModelLoading] = useState(true);

  // logout state
  const [logout] = useLogout();
  // all Ref

  const brandRef = useRef();
  const modelRef = useRef();
  const activityRef = useRef();

  // use Effect
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-models", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setModels(data);
          setModelLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-brands", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setBrandOptions(data);
          setBrandOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all Function

  const handleSelectedModel = (id) => {
    setSelectedModel({})
    setModalField(true);
    fetch(`https://kdaback.nescostore.com/api/edit-car-model/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedModel(data)
      });
  };

  const handleModelEdit = async (car_model_id) => {
    const car_model_name =
      modelRef.current.value || selectedModel.car_model_name;
    const car_brand_id = brandRef.current.value;
    const is_active = activityRef.current.value;

    const updatedModel = {
      car_model_name,
      car_brand_id,
      is_active,
      car_model_id,
    };

    fetch(
      "https://kdaback.nescostore.com/api/update-car-model",
      await handlePostApiHeader(updatedModel)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allmodel");
        let updatedModels = [];
        if (flag) {
          models.map((m) => {
            if (m.car_model_id === car_model_id) {
              updatedModels.push(updatedModel);
            } else {
              updatedModels.push(m);
            }
          });

          setModels(updatedModels);
          setModalField(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Model Name",
      selector: (row) => row.car_model_name,
      sortable: true,
    },
    {
      name: "Brand Name",
      selector: (row) => row.car_brand_name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          onClick={() => handleSelectedModel(row.car_model_id)}
          className="text-base"
        >
          <label className=" btn-link mx-2" for="edit-model">
            <i title="Edit" class="fa-regular fa-pen-to-square"></i>
          </label>
          <label className="btn-link mx-2 modal-button" for="model-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold"> All Vehicle Model</h2>
      <DataTable
        pagination
        columns={columns}
        data={models}
        progressPending={modelLoading}
        selectableRows
      />
      {modalField && (
        <>
          {" "}
          {/* edit form  */}
          <div>
            <input type="checkbox" id="edit-model" class="modal-toggle" />
            <label for="model-details" class="modal cursor-pointer">
              <label
                class="modal-box relative w-3/6 max-w-5xl text-justify"
                for=""
              >
                <label
                  htmlFor="edit-model"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <div className="py-4">
                  <div className="overflow-x-auto">
                    <div>
                      <h2 className="text-xl my-4 text-center font-semibold">
                        Edit Model
                      </h2>
                      <div className="mx-auto lg:w-1/2">
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Brand</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={brandRef}
                              key={selectedModel.car_brand_id}
                              defaultValue={selectedModel.car_brand_id}
                            >
                              <option disabled>
                                {" "}
                                ------ Select Brand ------{" "}
                              </option>
                              {brandOptionLoading ? (
                                <option disabled>Loading ....</option>
                              ) : (
                                brandOptions.map((brand) =>
                                  selectedModel.car_brand_id ===
                                  brand.car_brand_id ? (
                                    <option selected value={brand.car_brand_id}>
                                      {" "}
                                      {brand.car_brand_name}{" "}
                                    </option>
                                  ) : (
                                    <option value={brand.car_brand_id}>
                                      {" "}
                                      {brand.car_brand_name}{" "}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                          </label>
                        </div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Model Name</span>
                            <input
                              type="text"
                              ref={modelRef}
                              key={selectedModel.car_model_name}
                              defaultValue={selectedModel.car_model_name}
                              className="input w-4/6 input-bordered"
                            />
                          </label>
                        </div>

                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Activity</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={activityRef}
                              key={selectedModel.is_active}
                              defaultValue={selectedModel.is_active}
                            >
                              <option value="1">Active</option>

                              <option value="0">Not Active</option>
                            </select>
                          </label>
                        </div>
                        <button
                          className="btn btn-primary my-10 mx-auto lg:w-full"
                          onClick={() =>
                            handleModelEdit(selectedModel.car_model_id)
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* details view  */}
          <div>
            <input type="checkbox" id="model-details" class="modal-toggle" />
            <label for="model-details" class="modal cursor-pointer">
              <label
                class="modal-box relative lg:w-2/6 max-w-5xl text-justify"
                for=""
              >
                <label
                  htmlFor="model-details"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <h3 class="text-lg text-center font-bold">
                  Details About This Model
                </h3>

                <div className="py-4">
                  <div className="overflow-x-auto">
                    <table className="table table-zebra w-full">
                      <tbody>
                        <tr>
                          <td>Model Name</td>
                          <td>{selectedModel?.car_model_name}</td>
                        </tr>
                        <tr>
                          <td>Brand Name</td>
                          <td>{selectedModel?.car_brand_name}</td>
                        </tr>

                        <tr>
                          <td>Status</td>
                          <td>
                            {selectedModel.is_active === "1"
                              ? "Active"
                              : "Not Active"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </label>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default AllModel;
