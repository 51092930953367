import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import OfficerWiseBills from "./Pages/Bills/OfficerWiseBills";
import AddBrand from "./Pages/Car/AddBrand";
import AddCarCategory from "./Pages/Car/AddCarCategory";
import AddLocation from "./Pages/Car/AddLocation";
import AddModel from "./Pages/Car/AddModel";
import AddModelYear from "./Pages/Car/AddModelYear";
import AddOwner from "./Pages/Car/AddOwner";
import AddRunOn from "./Pages/Car/AddRunOn";
import AllBrand from "./Pages/Car/AllBrand";
import AllCar from "./Pages/Car/AllCar";
import AllCarCategory from "./Pages/Car/AllCarCategory";
import AllLocation from "./Pages/Car/AllLocation";
import AllModel from "./Pages/Car/AllModel";
import AllOwner from "./Pages/Car/AllOwner";
import AllRunOn from "./Pages/Car/AllRunOn";
import CarEntry from "./Pages/Car/CarEntry";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AddDesignation from "./Pages/Employees/AddDesignation";
import AddDriverCategory from "./Pages/Employees/AddDriverCategory";
import AllDesignation from "./Pages/Employees/AllDesignation";
import AllDriverCategory from "./Pages/Employees/AllDriverCatagory";
import AllEmployee from "./Pages/Employees/AllEmployee";
import EmployeeRegistrstion from "./Pages/Employees/EmployeeRegistrstion";
import AddPetrolPump from "./Pages/Fuel/AddPetrolPump";
import AllPetrolPump from "./Pages/Fuel/AllPetrolPump";
import ApprovedReFuelRequest from "./Pages/Fuel/ApprovedRefuelRequest";
import CompletedReFuelRequest from "./Pages/Fuel/CompltedRefuelRequest";
import OverDueRefuelRequest from "./Pages/Fuel/OverDueRefuelRequest";
import Refuelling from "./Pages/Fuel/Refuelling";
import RejectedReFuelRequest from "./Pages/Fuel/RejectedRefuelRequest";
import RequestedReFuel from "./Pages/Fuel/RequestedReFuel";
import Login from "./Pages/Login/Login";
import { DashboardLayout } from "./Pages/Navigation/Components/Layout";
import NotFoundErrorPage from "./Pages/NotFoundErrorPage";
import CompletedTripReport from "./Pages/Report/CompletedTripReport";
import OfficerWiseReport from "./Pages/Report/OfficerWiseReport";
import RejectedTripReport from "./Pages/Report/RejectedTripReport";
import OfficerAuth from "./Pages/RequireAuth/OfficerAuth";
import RequireAuth from "./Pages/RequireAuth/RequireAuth";
import Footer from "./Pages/Shared/Footer";
import Navbar from "./Pages/Shared/Navbar";
import AddScheduleJob from "./Pages/Trip/AddScheduleJob";
import AllScheduleJob from "./Pages/Trip/AllScheduleJob";
import ApprovedSchedule from "./Pages/Trip/ApprovedSchedule";
import CarScheduleRequest from "./Pages/Trip/CarScheduleRequest";
import CompletedSchedule from "./Pages/Trip/CompletedSchedule";
import EndTrip from "./Pages/Trip/EndTrip";
import OngoingSchedule from "./Pages/Trip/OngoingSchedule";
import RejectedSchedule from "./Pages/Trip/RejectedSchedule";
import RequestedSchedule from "./Pages/Trip/RequestedSchedule";
import StartTrip from "./Pages/Trip/StartTrip";
import EditProfile from "./Pages/UpdateProfile/EditProfile";
import UpdateImage from "./Pages/UpdateProfile/UpdateImage";
import UpdateName from "./Pages/UpdateProfile/UpdateInfo";
import UpdatePassword from "./Pages/UpdateProfile/UpdatePassword";

function App() {
  const allAuthRoutes = [
    { route_id: 5, path: "/allcarschedule", element: <ApprovedSchedule /> },
    { route_id: 39, path: "/dashboard", element: <Dashboard /> },
    { route_id: 40, path: "/requestedrefuel", element: <RequestedReFuel /> },
    { route_id: 41, path: "/completedrefuel", element: <CompletedReFuelRequest /> },
    { route_id: 42, path: "/rejectedrefuel", element: <RejectedReFuelRequest /> },
    { route_id: 43, path: "/overduerefuel", element: <OverDueRefuelRequest /> },
    { route_id: 36, path: "/approvedrefuel", element: <ApprovedReFuelRequest /> },
  ];

  const allDriverRoutes = [
    { route_id: 36, path: "/refuelling", element: <Refuelling /> },
    
  ];

  const allOfficerAuthRoutes = [
    { route_id: 4, path: "/carschedulereq", element: <CarScheduleRequest /> },
    // { route_id: 6, path: "/carschedule", element: <CarSchedule /> },
    { route_id: 1, path: "/allcar", element: <AllCar /> },
    { route_id: 2, path: "/carentry", element: <CarEntry /> },
    { route_id: 3, path: "/employeereg", element: <EmployeeRegistrstion /> },
    { route_id: 7, path: "/starttrip", element: <StartTrip /> },
    { route_id: 8, path: "/endtrip", element: <EndTrip /> },
    { route_id: 9, path: "/addmodelyear", element: <AddModelYear /> },
    { route_id: 11, path: "/addbrand", element: <AddBrand /> },
    { route_id: 12, path: "/addmodel", element: <AddModel /> },
    { route_id: 13, path: "/addowner", element: <AddOwner /> },
    { route_id: 14, path: "/addlocation", element: <AddLocation /> },
    { route_id: 15, path: "/allemployee", element: <AllEmployee /> },
    { route_id: 16, path: "/allmodel", element: <AllModel /> },
    { route_id: 17, path: "allOwner", element: <AllOwner /> },
    { route_id: 18, path: "/allBrand", element: <AllBrand /> },
    { route_id: 19, path: "/allLocation", element: <AllLocation /> },
    { route_id: 20, path: "/adddesignation", element: <AddDesignation /> },
    { route_id: 21, path: "/addcarcat", element: <AddCarCategory /> },
    { route_id: 21, path: "/adddrivercat", element: <AddDriverCategory /> },
    { route_id: 22, path: "/alldesignation", element: <AllDesignation /> },
    { route_id: 23, path: "/alldrivercat", element: <AllDriverCategory /> },
    { route_id: 24, path: "/allcarcat", element: <AllCarCategory /> },
    { route_id: 25, path: "/addrunon", element: <AddRunOn /> },
    { route_id: 26, path: "/allrunon", element: <AllRunOn /> },
    {
      route_id: 27,
      path: "/completedtripreport",
      element: <CompletedTripReport />,
    },
    {
      route_id: 28,
      path: "/rejectedtripreport",
      element: <RejectedTripReport />,
    },
    {
      route_id: 29,
      path: "/officerwisereport",
      element: <OfficerWiseReport />,
    },
    { route_id: 30, path: "/officerwisebills", element: <OfficerWiseBills /> },
    { route_id: 31, path: "/approvedtrips", element: <ApprovedSchedule /> },
    { route_id: 32, path: "/requestedtrips", element: <RequestedSchedule /> },
    { route_id: 33, path: "/ongoingtrips", element: <OngoingSchedule /> },
    { route_id: 34, path: "/completedtrips", element: <CompletedSchedule /> },
    { route_id: 35, path: "/rejectedtrips", element: <RejectedSchedule /> },
    { route_id: 37, path: "/addschedulejob", element: <AddScheduleJob /> },
    { route_id: 38, path: "/addpetrolpump", element: <AddPetrolPump /> },
    { route_id: 39, path: "/allpetrolpump", element: <AllPetrolPump /> },
    { route_id: 44, path: "/allschdulejob", element: <AllScheduleJob /> },

  ];


  return (
    <div className="App sm:mx-auto lg:max-h-screen overflow-y-scroll">
      <Navbar></Navbar>

      <Routes>
        {/* all authinticate Routes  */}
        {allAuthRoutes.map((route) => (
          <Route
            key={route.route_id}
            path={route.path}
            element={
              <RequireAuth>
                <DashboardLayout>{route.element}</DashboardLayout>
              </RequireAuth>
            }
          />
        ))}
        {allOfficerAuthRoutes.map((route) => (
          <Route
            key={route.route_id}
            path={route.path}
            element={
              <OfficerAuth>
                <DashboardLayout>{route.element}</DashboardLayout>
              </OfficerAuth>
            }
          />
        ))}
        {allDriverRoutes.map((route) => (
          <Route
            key={route.route_id}
            path={route.path}
            element={
              <RequireAuth>
                <DashboardLayout>{route.element}</DashboardLayout>
              </RequireAuth>
            }
          />
        ))}

        <Route path="/" element={<Login />} />
        <Route
          path="/editprofile"
          element={
            <RequireAuth>
              <DashboardLayout>
                <EditProfile />
              </DashboardLayout>
            </RequireAuth>
          }
        >
          <Route index element={<UpdatePassword />} />
          <Route path="updateinfo" element={<UpdateName />} />
          <Route path="profilepicture" element={<UpdateImage />} />
        </Route>

        <Route path="/*" element={<NotFoundErrorPage />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
