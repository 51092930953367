import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePostApiHeader, usePostApiResponse } from "../../helper/customHook";

const AddDesignation = () => {
  // all state
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();
  // error State
  const [designationError, setDesignationError] = useState("");
  // data State
  const [levelOptions, setLevelOptions] = useState([]);
  // loading State
  const [levelOptionLoading, setLevelOptionLoading] = useState(true);

  // ref
  const designationRef = useRef();
  const shortDesignationRef = useRef();
  const approveLevelRef = useRef();
  const activityRef = useRef();

  // navigate State
  const navigate = useNavigate();

  // all Use useEffect
  useEffect(() => {
    fetch("approveLevel.json")
      .then((res) => res.json())
      .then((data) => {
        setLevelOptions(data);
        setLevelOptionLoading(false);
      });
  }, []);

  const handleDesignation = () => {
    let designation = designationRef.current.value;
    if (designation.length) {
      setDesignationError("");
    } else {
      setDesignationError("This Field can not be Empty");
    }
  };

  const handleAddDesignation = async () => {
    const designation = designationRef.current.value;
    const short_designation = shortDesignationRef.current.value;
    const approval_level = approveLevelRef.current.value;
    const is_active = activityRef.current.value;
    const newDesignation = {
      designation,
      short_designation,
      approval_level,
      is_active,
    };
    fetch(
      "https://kdaback.nescostore.com/api/store-designation",
      await handlePostApiHeader(newDesignation)
    )
      .then((res) => res.json())
      .then((data) => {
        
        handlePostResponse(data, "/alldesignation");
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };
  return (
    <div>
      <h2 className="text-xl my-4 text-center font-semibold">
        Add Employee Designation
      </h2>
      <div className="mx-auto lg:w-1/2">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Designation</span>
            <input
              type="text"
              placeholder="ex: Member"
              className="input w-4/6 input-bordered"
              ref={designationRef}
              onBlur={handleDesignation}
            />
          </label>
        </div>
        {designationError && (
          <div className="alert shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{designationError}</span>
            </div>
          </div>
        )}
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Short Designation</span>
            <input
              type="text"
              placeholder="Ex: CEO"
              className="input w-4/6 input-bordered"
              ref={shortDesignationRef}
            />
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Approve Level</span>
            <select
              className="select select-bordered w-4/6"
              ref={approveLevelRef}
            >
              <option disabled selected>
                {" "}
                ------ Select Approve Level ------{" "}
              </option>
              {levelOptionLoading ? (
                <option disabled>Loading ....</option>
              ) : (
                levelOptions.map((levelOption) => (
                  <option value={levelOption.level_id}>
                    {" "}
                    {levelOption.level_name}{" "}
                  </option>
                ))
              )}
            </select>
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Activity</span>
            <select className="select select-bordered w-4/6" ref={activityRef}>
              <option value="1">Active</option>

              <option value="0">Not Active</option>
            </select>
          </label>
        </div>
        <div className="w-48 mx-auto">
          <button
            className="btn btn-primary content-center w-full my-10 mx-auto"
            onClick={handleAddDesignation}
          >
            ADD Designation
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDesignation;
