import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetApiHeader, useLogout, usePostApiHeader, useUpdateApiResponse } from "../../helper/customHook";

const AllDesignation = () => {
  // get Header State
  const [getApiHeader] = useGetApiHeader();

   // update api
    const [handlePostApiHeader] = usePostApiHeader();
    const [handleUpdateResponse] = useUpdateApiResponse();

  const [logout] = useLogout();

  // all State

  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState({});
  const [designationLoading, setDesignationLoading] = useState(true);
  const [modalField,setModalField] = useState(false)
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-designations", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDesignations(data);
          setDesignationLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all Ref
  const nameRef = useRef();
  const shortDesignationRef = useRef();
  const activityRef = useRef();

  // all function
  const handleSelectedDesignation = (id) => {
    setModalField(true)
    setSelectedDesignation({})
    fetch(`https://kdaback.nescostore.com/api/edit-designation/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedDesignation(data)
      });
  };

  const handleDesignationEdit = async (designation_id) => {
    const designation =
      nameRef.current.value || selectedDesignation.designation;
    const short_designation =
    shortDesignationRef.current.value || selectedDesignation.short_designation;
    const approval_level = selectedDesignation.approval_level 
    const is_active = activityRef.current.value;

    const updatedDesignation = { designation, short_designation,approval_level,designation_id, is_active }
    

    fetch(
      "https://kdaback.nescostore.com/api/update-designation",
      await handlePostApiHeader(updatedDesignation)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/alldesignation");
        let updatedDesignations = [];
        if (flag) {
          designations.map((d) => {
            if (d.designation_id === designation_id) {
              updatedDesignations.push(updatedDesignation);
            } else {
              updatedDesignations.push(d);
            }
          });

          setDesignations(updatedDesignations);
          setModalField(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });


  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "Sort Name",
      selector: (row) => row.short_designation,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active==="1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <label className=" btn-link mx-1" for="edit-brand">
          <i
            title="Edit"
            onClick={() => handleSelectedDesignation(row.designation_id)}
            class="fa-regular fa-pen-to-square"
          ></i>
        </label>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">
        {" "}
        All Designation{" "}
      </h2>
      <DataTable
        pagination
        columns={columns}
        data={designations}
        progressPending={designationLoading}
        selectableRows
      />
      {modalField && (
        <div>
          <input type="checkbox" id="edit-brand" class="modal-toggle" />
          <label for="car-schedule-details" class="modal cursor-pointer">
            <label
              class="modal-box relative w-3/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-brand"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Designation Info
                    </h2>
                    <div className="mx-auto lg:w-3/4">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Designation</span>
                          <input
                            type="text"
                            ref={nameRef}
                            key={selectedDesignation.designation}
                           defaultValue={selectedDesignation.designation}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Short Designation</span>
                          <input
                            type="text"
                            ref={shortDesignationRef}
                            key={selectedDesignation.short_designation}
                           defaultValue={selectedDesignation.short_designation}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>

                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Activity</span>
                          <select
                            key={selectedDesignation.is_active}
                            defaultValue={selectedDesignation.is_active}
                            className="select select-bordered w-4/6"
                            ref={activityRef}
                          >
                            <option value="1">Active</option>

                            <option value="0">Not Active</option>
                          </select>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary my-10 mx-auto lg:w-full"
                        onClick={() =>
                          handleDesignationEdit(
                            selectedDesignation.designation_id
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
    </div>
  );
};

export default AllDesignation;
