import React from "react";
import { useState } from "react";

const UpdateImage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div>
      {selectedImage && (
        <div>
          <div className="avatar">
            <div className="w-32 rounded">
              <img src={URL.createObjectURL(selectedImage)} />
            </div>
          </div>
          <br />
          <button
            onClick={() => console.log(URL.createObjectURL(selectedImage))}
          >
            Upload
          </button>
          <button className="mx-3"
            onClick={() => setSelectedImage(null)}
          >
            Remove
          </button>
        </div>
      )}
      <br />

      <br />
      {!selectedImage&&<input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage(event.target.files[0]);
        }}
      />}
    </div>
  );
};

export default UpdateImage;
