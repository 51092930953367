import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

const AllBrand = () => {
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // update api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  // logout state
  const [logout] = useLogout();
  // data State
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [brandLoading, setBrandLoading] = useState(true);

// all fieldState
const [modalField,setModalField] = useState(false)

  // all Ref
  const nameRef = useRef();
  const activityRef = useRef();

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-brands", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setBrands(data);
          setBrandLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all function
  const handleSelectedBrand = (id) => {
    setModalField(true)
    fetch(`https://kdaback.nescostore.com/api/edit-car-brand/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedBrand(data)
      });
  };

  const handleBrandEdit = async (car_brand_id) => {
    const car_brand_name =
      nameRef.current.value || selectedBrand.car_brand_name;
    const is_active = activityRef.current.value;
    const updatedBrand = { car_brand_id, car_brand_name, is_active };
    fetch(
      "https://kdaback.nescostore.com/api/update-car-brand",
      await handlePostApiHeader(updatedBrand)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allbrand");
        let updatedBrands = []
        if (flag) {
          
            brands.map((b)=>{
              if(b.car_brand_id === car_brand_id){
                updatedBrands.push(updatedBrand)
              }
              else{
                updatedBrands.push(b)
              }
            })
          
          setBrands(updatedBrands);
          setModalField(false)
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Brand Name",
      selector: (row) => row.car_brand_name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          onClick={() => handleSelectedBrand(row.car_brand_id)}
          className="text-base"
        >
          <label className=" btn-link mx-2" for="edit-brand">
            <i title="Edit" class="fa-regular fa-pen-to-square"></i>
          </label>
          <label className="btn-link mx-2 modal-button" for="brand-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold"> All Vehicle Brand</h2>
      <DataTable
        pagination
        columns={columns}
        data={brands}
        progressPending={brandLoading}
        selectableRows
      />
      {/* edit brand  */}
      {modalField && (
        <div>
          <input type="checkbox" id="edit-brand" class="modal-toggle" />
          <label for="edit-brand" class="modal cursor-pointer">
            <label
              class="modal-box relative w-3/6 max-w-5xl text-justify"
              for="edit-brand"
            >
              <label
                htmlFor="edit-brand"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Brand Info
                    </h2>
                    <div className="mx-auto lg:w-1/2">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Name</span>
                          <input
                            type="text"
                            key={selectedBrand.car_brand_name}
                            ref={nameRef}
                            defaultValue={selectedBrand.car_brand_name}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>

                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Activity</span>
                          <select
                            className="select select-bordered w-4/6"
                            ref={activityRef}
                            key={selectedBrand.is_active}
                            defaultValue={selectedBrand.is_active}
                          >
                            <option value="1">Active</option>

                            <option value="0">Not Active</option>
                          </select>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary my-10 mx-auto lg:w-full"
                        onClick={() =>
                          handleBrandEdit(selectedBrand.car_brand_id)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
      {/* View Details  */}
      <div>
        <input type="checkbox" id="brand-details" class="modal-toggle" />
        <label for="brand-details" class="modal cursor-pointer">
          <label class="modal-box relative lg:w-2/6 max-w-5xl text-justify" for="">
            <label
              htmlFor="brand-details"
              className="btn btn-sm btn-circle absolute right-2 top-4"
            >
              ✕
            </label>
            <h3 class="text-lg text-center font-bold">
              Details About This Brand
            </h3>

            <div className="py-4">
              <div className="overflow-x-auto">
                <table className="table table-zebra w-full overflow-x-scroll">
                  <tbody>
                    <tr>
                      <td>Brand Name</td>
                      <td>{selectedBrand?.car_brand_name}</td>
                    </tr>

                    <tr>
                      <td>Status</td>
                      <td>
                        {selectedBrand.is_active === "1"
                          ? "Active"
                          : "Not Active"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </label>
        </label>
      </div>
    </div>
  );
};

export default AllBrand;
