import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLogout } from "../../helper/customHook";

const Navbar = () => {
  // user state
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));

  // logout state
  const [logout] = useLogout();

  const menuList = (
    <>
      {user[0] && (
        <>
          <li tabIndex={0}>
            <a>
              Vehicle
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </a>
            <ul className="p-2">
              <li className="h-fit">
                <Link to="/allcar">All Vehicle</Link>
                <Link to="/carentry">Vehicle Entry</Link>
                <Link to="/addowner">Add Owner</Link>
                <Link to="/addmodel">Add Model</Link>
                <Link to="/addbrand">Add brand</Link>
                <Link to="/addcarcat">Add Vehicle Category</Link>
              </li>
            </ul>
          </li>
          <li tabIndex={0}>
            <a>
              Employee
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </a>
            <ul className="p-2">
              <li className="h-fit">
                <Link to="/employeereg">Add Employee</Link>
                <Link to="/allemployee">All Employee</Link>
                <Link to="/adddesignation">Add Designation</Link>
                <Link to="/adddrivercat">Add Driver Category</Link>
              </li>
            </ul>
          </li>
          <li tabIndex={0}>
            <a>
              Add Vehicle Schedule
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </a>
            <ul className="p-2">
              <li className="h-fit">
                <Link to="/carschedule">Vehicle Schedule</Link>
                <Link to="/carschedulereq">Vehicle Request</Link>
                <Link to="/allcarschedule">All Vehicle Schedule</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/starttrip">Start Trip</Link>
          </li>
          <li>
            <Link to="/endtrip">End Trip</Link>
          </li>
          <li tabIndex={0}>
            <a>
              Utility
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </a>
            <ul className="p-2">
              <li className="h-fit">
                <Link to="/addlocation">Add Location</Link>
                <Link to="/alllocation">All Location</Link>
                <Link to="/allmodel">All Model</Link>
                <Link to="/allowner">All Owner</Link>
                <Link to="/allbrand">All Brand</Link>
              </li>
            </ul>
          </li>
        </>
      )}
    </>
  );
  return (
    <div className="bg-info p-1">
      <div className="navbar sm:static sticky">
        <div className="navbar-start">
          <div className="dropdown">
            {/* <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label> */}
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              {/* {menuList} */}
            </ul>
          </div>
          
          <div className="avatar">
            <div className="w-20 my-[-20px]">
            </div>
          </div>
          <Link to="/" className="btn btn-ghost normal-case text-xl">VMS</Link>
        </div>

        <div className="navbar-end">
          {user[0] && (
            <>
              <div class="avatar mr-6">
                <div class="w-14 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"  alt="..."/>
                </div>
              </div>
              <div className="dropdown">
                <label tabIndex="0" className="btn m-1 flex flex-1">
                  <p>{user[0]?.data?.full_name}</p>
                </label>
                <p className="text-base ml-5 font-semibold">{user[0]?.data?.designation}</p>
                <ul
                  tabIndex="0"
                  className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  <li>
                    <Link to="/editprofile">Edit Profile</Link>
                  </li>
                  <li>
                    <p onClick={logout}>Logout</p>
                  </li>
                  
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
