import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { reset } from "../features/user/userSlice";

// header hook
// Get Api
export const useGetApiHeader = () => {
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  const [getApiHeader, setGetApiHeader] = useState({
    method: "GET",
    headers: {
      authorization: `Bearer ${user[0]?.token}`,
      Accept: "application/json",
    },
  });
  return [getApiHeader, setGetApiHeader];
};

// post Api
export const usePostApiHeader = () => {
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));

  const handlePostApiHeader = async (data) => {
    return {
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${user[0]?.token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    };
  };

  return [handlePostApiHeader];
};
// logout hook
export const useLogout = () => {
  // user State
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  const [handlePostApiHeader] = usePostApiHeader();

  // dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    fetch("https://kdaback.nescostore.com/api/logout", {
      method: "POST",
      headers: {
        authorization: `Bearer ${user[0]?.token}`,
        Accept: "application/json",
      },
      body: {},
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(reset());
        navigate("/");
      });
  };

  return [logout];
};

export const usePostApiResponse = () => {
  const [logout] = useLogout();
  const navigate = useNavigate();
  const handlePostResponse = (data, toNavigate) => {
    if (data?.status) {
      toast.success(data.message);
      navigate(toNavigate);
    } else {
      if (data.message !== "Unauthenticated.") {
        toast.error(data.message);
      } else {
        if (typeof data?.errors === "object" && data?.errors) {
          for (const key in data?.errors) {
            toast.error(data?.errors[key][0]);
          }
        } else if (data?.message === "Unauthenticated.") {
          logout();
        }
      }
    }
  };
  return [handlePostResponse];
};

// if (!data?.message) {
//   if(typeof(data)==="string"){
//     toast.success(data)
//     navigate(toNavigate)
//   }
// } else {
//   if(typeof(data)==="object" && data?.errors ){
//     for (const key in data?.errors) {
//       toast.error(data?.errors[key][0])
//     }
//     }
//     else{
//       logout()
//     }

// }

// update request

export const useUpdateApiResponse = () => {
  const [logout] = useLogout();
  const navigate = useNavigate();
  const handleUpdateResponse = async (data, toNavigate) => {
    if (data?.status) {
      toast.success(data.message);
      navigate(toNavigate);
      return true;
    } else {
      if (data.message !== "Unauthenticated.") {
        toast.error(data.message);
      } else {
        if (typeof data?.errors === "object" && data?.errors) {
          for (const key in data?.errors) {
            toast.error(data?.errors[key][0]);
          }
        } else if (data?.message === "Unauthenticated.") {
          logout();
        }
      }
      return false;
    }
  };
  return [handleUpdateResponse];
};
