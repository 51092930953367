import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetApiHeader,
  useLogout,
  usePostApiHeader,
  usePostApiResponse
} from "../../helper/customHook";

const AddScheduleJob = () => {
  // all state
  // user State
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // logout state
  const [logout] = useLogout();
  // error state


  // loading State
  const [employeeOptionLoading, setEmployeeOptionLoading] = useState([]);
  const [driverOptionLoading, setDriverOptionLoading] = useState([]);
  const [carOptionLoading, setCarOptionLoading] = useState([]);

 


  //  option State
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  // All use Ref

  const vehicleRef = useRef();
  const driverRef = useRef();
  const nameRef = useRef();
  const pickupRef = useRef({})
  const timeRef = useRef({})


  // all useEffect


  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setEmployeeOptions(data);
          setEmployeeOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-cars", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setCarOptions(data);
          setCarOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-drivers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDriverOptions(data);
          setDriverOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all Function

 
 


  const handleAddScheduleJob = async () => {
    
    const vehicle_id = vehicleRef.current.value
    const driver_id = driverRef.current.value
    const schedule_name = nameRef.current.value
    const created_by = user[0]?.data?.user_profile_id
    
    let schedule_details = []
    let dataFlag = true
    if(selectedEmployees.length===0){
      dataFlag = false
    }
    selectedEmployees.map(emp=> {
      const officer_id = emp.user_profile_id
      const pick_time = timeRef.current[emp.user_profile_id.toString()].value
      const location = pickupRef.current[emp.user_profile_id.toString()].value
      if(officer_id&&pick_time&&location){
      schedule_details.push({officer_id,pick_time,location})
      }
      else{
        dataFlag = false
      }
    })
    
    if(dataFlag){
      const newScheduleJob = {vehicle_id,driver_id,schedule_name,created_by,schedule_details}
    fetch(
      "https://kdaback.nescostore.com/api/store-schedule",
      await handlePostApiHeader(newScheduleJob)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/allschdulejob"))
      .catch((error) => {
        console.log("error:", error);
      });
    }
    else{
      toast.error("Please all Require Field")
    }
  };

  return (
    <div className="min-h-screen bg-base-100">
      <div>
        <h3 className="text-xl text-center font-semibold my-5">
          Set Schedule job
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
          <div className="">
          <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Schedule Name</span>
                <input
                  type="text"
                  ref={nameRef}
                  className="input w-4/6 input-bordered"                
                />
              </label>
            </div>
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Vehicle</span>
                <select className="select select-bordered w-4/6" ref={vehicleRef}>
                  <option disabled selected>
                    {" "}
                    ------ Select Vehicle ------{" "}
                  </option>
                  {carOptionLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    carOptions.map((car) => (
                      <option value={car.car_info_id}>
                        {" "}
                        {car.vehicle_no}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            <div className="form-control">
              <label className="input-group">
                <span className="w-2/6">Driver</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={driverRef}
                >
                  <option disabled selected>
                    {" "}
                    ------ Select Driver ------{" "}
                  </option>
                  {driverOptionLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    driverOptions.map((driver) => (
                      <option value={driver.user_id}>
                        {" "}
                        {driver.full_name}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            
          </div>
          <div className="">
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Officer</span>

                <Multiselect
                  className="w-4/6"
                  displayValue="full_name"
                  loading={employeeOptionLoading}
                  onRemove={(event) => {
                    setSelectedEmployees(event);
                  }}
                  onSelect={(event) => {
                    setSelectedEmployees(event);
                  }}
                  selectedValues={selectedEmployees}
                  options={employeeOptions}
                />
              </label>
            </div>
            
            {/* <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">To Time</span>
                <input
                  type="time"
                  className="input w-4/6 input-bordered"
                  ref={toDateRef}
                  onBlur={handleToDate}
                  
                />
              </label>
            </div> */}
            {/* <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Pick Location</span>
                <input
                  type="text"
                  className="input w-4/6 input-bordered"
                //   ref={toDateRef}
                
                />
              </label>
            </div>
            {pickupError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{pickupError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Drop Location</span>
                <input
                  type="text"
                  className="input w-4/6 input-bordered"                
                />
              </label>
            </div>
            {destinationError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{destinationError}</span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        {selectedEmployees.length !== 0 && (
          <div className="overflow-x-auto mt-16">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Pick Time</th>
                  <th>Pick Location</th>
                </tr>
              </thead>
              <tbody>
                {selectedEmployees &&
                  selectedEmployees.map((emp,index) => (
                    <tr key={emp.user_profile_id}>
                      <td>{emp.full_name}</td>
                      <td>
                        <input
                          type="time"
                          className="input w-5/6 input-bordered"
                          ref={(element) => {
                            timeRef.current[emp.user_profile_id.toString()] = element;
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="input w-5/6 input-bordered"
                          placeholder="Pick up point"
                            ref={(element) => {
                              pickupRef.current[emp.user_profile_id.toString()] = element;
                            }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="w-48 mx-auto">
          <button
            onClick={handleAddScheduleJob}
            className="btn btn-primary my-10 mx-auto lg:w-44"
          >
            ADD Schedule
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddScheduleJob;
