import React, { useRef, useState } from "react";
import { usePostApiHeader, usePostApiResponse } from "../../helper/customHook";

const AddCarCategory = () => {
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // all state
  const [carCatError, setCarCatError] = useState("");
  // disable state
  const [submitFormDisable, setSubmitFormDisable] = useState(true);
  // ref
  const carCatRef = useRef();
  const activityRef = useRef();

  const handleCarCat = () => {
    const carCat = carCatRef.current.value;

    if (carCat.length) {
      setCarCatError("");
      setSubmitFormDisable(false);
    } else {
      setCarCatError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handleAddCarCat = async () => {
    const car_category_name = carCatRef.current.value;
    const is_active = activityRef.current.value;
    const newCarCategory = { car_category_name, is_active };
    fetch(
      "https://kdaback.nescostore.com/api/store-car-category",
      await handlePostApiHeader(newCarCategory)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/allcarcat"))
      .catch((error) => {
        console.log("error:", error);
      });
  };
  return (
    <div>
      <h2 className="text-xl my-4 text-center font-semibold">
        Add a Vehicle Category
      </h2>
      <div className="mx-auto lg:w-1/2">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Vehicle Category</span>
            <input
              type="text"
              placeholder="ex: Heavy"
              className="input w-4/6 input-bordered"
              ref={carCatRef}
              onBlur={handleCarCat}
            />
          </label>
        </div>
        {carCatError && (
          <div className="alert shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{carCatError}</span>
            </div>
          </div>
        )}
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Activity</span>
            <select className="select select-bordered w-4/6" ref={activityRef}>
              <option value="1">Active</option>

              <option value="0">Not Active</option>
            </select>
          </label>
        </div>
      </div>
      <div className="w-48 mx-auto">
        <button
          disabled={submitFormDisable}
          className="btn btn-primary my-10 mx-auto lg:w-44"
          onClick={handleAddCarCat}
        >
          ADD Category
        </button>
      </div>
    </div>
  );
};

export default AddCarCategory;
