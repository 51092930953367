import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetApiHeader,
  useLogout,
  usePostApiHeader,
  usePostApiResponse,
} from "../../helper/customHook";

import Loading from "../Utility/Loading";

const AllScheduleJob = () => {
  // get Header State
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // logout state
  const [logout] = useLogout();

  const [allScheduleJobs, setAllScheduleJobs] = useState([]);
  const [selectedScheduleJob, setSelectedScheduleJob] = useState({});
  const [modalField, setModalField] = useState(false);

  //  option State
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  // loading State
  const [employeeOptionLoading, setEmployeeOptionLoading] = useState(true);
  const [driverOptionLoading, setDriverOptionLoading] = useState(true);
  const [carOptionLoading, setCarOptionLoading] = useState(true);
  const [selectedLoading, setSelectedLoading] = useState(true);

  // All use Ref

  const vehicleRef = useRef();
  const driverRef = useRef();
  const nameRef = useRef();
  const officerRef = useRef();
  const pickupRef = useRef({});
  const timeRef = useRef({});

  // loading State
  const [schduleLoading, setSchduleLoading] = useState(true);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-schedule", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        let tempSchedules = [];
        setSchduleLoading(() => true);
        // mapping for single Schedule (d->single schedule)
        data.map((d, index) => {
          let tempObj = {};
          //  fetching by schedule_id for details
          fetch(
            `https://kdaback.nescostore.com/api/schedule_details/${d?.schedule_id}`,
            getApiHeader
          )
            .then((res) => res.json())
            .then(async (sch) => {
              tempObj = { ...d, schedule_details: sch };
              tempSchedules.push(tempObj);

              // if last element loading false
              if (index + 1 === data.length) {
                setAllScheduleJobs(() => tempSchedules);
                setSchduleLoading(() => false);
              }
            });
        });
      });
  }, []);
  // all useEffect

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setEmployeeOptions(data);
          setEmployeeOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-cars", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setCarOptions(data);
          setCarOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-drivers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDriverOptions(data);
          setDriverOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all function
  const handleSelectedScheduleJob = (id) => {
    setSelectedScheduleJob({});
    setSelectedLoading(true);
    setModalField(true);
    fetch(
      `https://kdaback.nescostore.com/api/edit-schedule/${id}`,
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        const tempEmployees = [];
        fetch(
          `https://kdaback.nescostore.com/api/schedule_details/${data[0].schedule_id}`,
          getApiHeader
        )
          .then((res) => res.json())
          .then((sch) => {
            const tempObj = { ...data[0], schedule_details: sch };

            sch.map((s) => {
              tempEmployees.push(
                employeeOptions.find((e) => e.user_profile_id === s.officer_id)
              );
            });

            setSelectedEmployees(tempEmployees);
            setSelectedScheduleJob(tempObj);
            setSelectedLoading(false);
          });
      });
  };

  const handleScheduleJobEdit = async () => {
    const vehicle_id = vehicleRef.current.value;
    const driver_id = driverRef.current.value;
    const schedule_name = nameRef.current.value;
    const created_by = user[0]?.data?.user_profile_id;
    let schedule_details = [];
    let dataFlag = true;
    if (selectedEmployees.length === 0) {
      dataFlag = false;
    }
    selectedEmployees.map((emp) => {
      const officer_id = emp.user_profile_id;
      const pick_time = timeRef.current[emp.user_profile_id.toString()].value;
      const location = pickupRef.current[emp.user_profile_id.toString()].value;
      if (officer_id && pick_time && location) {
        schedule_details.push({ officer_id, pick_time, location });
      } else {
        dataFlag = false;
      }
    });

    if (dataFlag) {
      const updatedScheduleJob = {
        vehicle_id,
        driver_id,
        schedule_name,
        created_by,
        schedule_details,
        schedule_id: selectedScheduleJob?.schedule_id,
      };
      fetch(
        "https://kdaback.nescostore.com/api/update-schedule",
        await handlePostApiHeader(updatedScheduleJob)
      )
        .then((res) => res.json())
        .then((data) => {
          handlePostResponse(data, "/allschdulejob");
        })
        .catch((error) => {
          console.error("error:", error);
        });
    } else {
      toast.error("Please all Require Field");
    }
  };

  const columns = [
    {
      name: "Schedule Name",
      selector: (row) => row.schedule_name,
      sortable: true,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.vehicle_no,
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) => row.driver_name,
      sortable: true,
    },
    {
      name: "Officer",
      selector: (row) =>
        row?.schedule_details?.map((s) => (
          <p className="pb-1">{s.officer_name}</p>
        )),
      sortable: true,
    },

    {
      name: "Pick Time",
      selector: (row) =>
        row?.schedule_details?.map((s) => (
          <p className="pb-1">
            {moment(s.pick_time, ["HH:mm"]).format("hh:mm A")}
          </p>
        )),

      // moment(row?.pick_time).format("h:mm A"),
      sortable: true,
    },
    {
      name: "Locations",
      selector: (row) =>
        row?.schedule_details?.map((s) => <p className="pb-1">{s.location}</p>),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          className="text-sm flex flex-auto"
          onClick={() => handleSelectedScheduleJob(row.schedule_id)}
        >
          <label
            title="End"
            className="btn-link px-1 modal-button"
            for="edit-modal"
          >
            <i title="Edit" class="fa-regular fa-pen-to-square"></i>{" "}
          </label>

          <label className="btn-link px-1 modal-button" for="schedule-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">
        All Schedule Job
      </h2>
      <DataTable
        pagination
        columns={columns}
        data={allScheduleJobs}
        progressPending={schduleLoading}
        selectableRows
      />

      {modalField && (
        <>
          {/* car schedule Details modal */}
          <div>
            <input type="checkbox" id="schedule-details" class="modal-toggle" />
            <label for="schedule-details" class="modal cursor-pointer">
              <label
                class="modal-box relative w-7/12 max-w-5xl text-justify"
                for="schedule-details"
              >
                <label
                  htmlFor="schedule-details"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <h3 class="text-lg text-center font-bold">
                  Details About This Request
                </h3>

                <div className="py-4">
                  <div className="overflow-x-auto">
                    {selectedLoading ? (
                      <Loading></Loading>
                    ) : (
                      <>
                        <table className="table w-full">
                          <tbody>
                            <tr className="w-12/12">
                              <td className="w-6/12">
                                <span className="font-semibold ">
                                  Vehicle No:
                                </span>{" "}
                                {selectedScheduleJob?.vehicle_no}
                              </td>
                              <td className="w-6/12">
                                {" "}
                                <span className="font-semibold ">
                                  Schedule Name:
                                </span>{" "}
                                {selectedScheduleJob?.schedule_name}
                              </td>
                              <td className="w-6/12">
                                {" "}
                                <span className="font-semibold ">
                                  Driver Name:
                                </span>{" "}
                                {selectedScheduleJob?.driver_name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table mt-10 table-zebra w-full">
                          <tbody>
                            <tr>
                              <th></th>
                              <th>Officer Name</th>
                              <th> Pick Time </th>
                              <th> Pick Location</th>
                            </tr>

                            {selectedScheduleJob?.schedule_details?.map(
                              (s, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{s.officer_name}</td>
                                  <td>
                                    {" "}
                                    {moment(s.pick_time, ["HH:mm"]).format(
                                      "hh:mm A"
                                    )}{" "}
                                  </td>
                                  <td> {s.location}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* edit modal */}
          <div>
            <input type="checkbox" id="edit-modal" class="modal-toggle" />
            <label for="edit-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-5/6 max-w-5xl text-justify"
                for=""
              >
                <label
                  htmlFor="edit-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <div className="py-4">
                  <div className="overflow-x-auto">
                    <div>
                      <h3 className="text-xl text-center font-semibold my-5">
                        Set Schedule job
                      </h3>
                      <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
                        <div className="">
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Schedule Name</span>
                              <input
                                type="text"
                                ref={nameRef}
                                className="input w-4/6 input-bordered"
                                key={selectedScheduleJob.schedule_name}
                                defaultValue={selectedScheduleJob.schedule_name}
                              />
                            </label>
                          </div>
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Vehicle</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={vehicleRef}
                                key={selectedScheduleJob.vehicle_id}
                                defaultValue={selectedScheduleJob.vehicle_id}
                              >
                                <option disabled selected>
                                  {" "}
                                  ------ Select Vehicle ------{" "}
                                </option>
                                {carOptionLoading ? (
                                  <option disabled>Loading ....</option>
                                ) : (
                                  carOptions.map((car) => (
                                    <option value={car.car_info_id}>
                                      {" "}
                                      {car.vehicle_no}{" "}
                                    </option>
                                  ))
                                )}
                              </select>
                            </label>
                          </div>
                          <div className="form-control">
                            <label className="input-group">
                              <span className="w-2/6">Driver</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={driverRef}
                                key={selectedScheduleJob.driver_id}
                                defaultValue={selectedScheduleJob.driver_id}
                              >
                                <option disabled selected>
                                  {" "}
                                  ------ Select Driver ------{" "}
                                </option>
                                {driverOptionLoading ? (
                                  <option disabled>Loading ....</option>
                                ) : (
                                  driverOptions.map((driver) => (
                                    <option value={driver.user_id}>
                                      {" "}
                                      {driver.full_name}{" "}
                                    </option>
                                  ))
                                )}
                              </select>
                            </label>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Officer</span>

                              <Multiselect
                                className="w-4/6"
                                displayValue="full_name"
                                loading={employeeOptionLoading}
                                onRemove={(event) => {
                                  setSelectedEmployees(event);
                                }}
                                onSelect={(event) => {
                                  setSelectedEmployees(event);
                                }}
                                selectedValues={selectedEmployees}
                                options={employeeOptions}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {selectedEmployees.length !== 0 && (
                        <div className="overflow-x-auto mt-16">
                          <table className="table w-full">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Pick Time</th>
                                <th>Pick Location</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedEmployees &&
                                selectedScheduleJob?.schedule_details &&
                                selectedEmployees.map((emp, index) =>
                                  selectedScheduleJob?.schedule_details[
                                    index
                                  ] ? (
                                    <tr key={emp.user_profile_id}>
                                      <td>{emp.full_name}</td>
                                      <td>
                                        <input
                                          type="time"
                                          className="input w-5/6 input-bordered"
                                          ref={(element) => {
                                            timeRef.current[
                                              emp.user_profile_id.toString()
                                            ] = element;
                                          }}
                                          key={
                                            selectedScheduleJob
                                              ?.schedule_details[index]
                                              .pick_time
                                          }
                                          defaultValue={
                                            selectedScheduleJob
                                              ?.schedule_details[index]
                                              .pick_time
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="input w-5/6 input-bordered"
                                          placeholder="Pick up point"
                                          ref={(element) => {
                                            pickupRef.current[
                                              emp.user_profile_id.toString()
                                            ] = element;
                                          }}
                                          key={
                                            selectedScheduleJob
                                              ?.schedule_details[index].location
                                          }
                                          defaultValue={
                                            selectedScheduleJob
                                              ?.schedule_details[index]
                                              ?.location
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr key={emp.user_profile_id}>
                                      <td>{emp.full_name}</td>
                                      <td>
                                        <input
                                          type="time"
                                          className="input w-5/6 input-bordered"
                                          ref={(element) => {
                                            timeRef.current[
                                              emp.user_profile_id.toString()
                                            ] = element;
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="input w-5/6 input-bordered"
                                          placeholder="Pick up point"
                                          ref={(element) => {
                                            pickupRef.current[
                                              emp.user_profile_id.toString()
                                            ] = element;
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      )}
                      <div className="w-48 mx-auto">
                        <button
                          onClick={handleScheduleJobEdit}
                          className="btn btn-primary my-10 mx-auto lg:w-44"
                        >
                          Update Schedule
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default AllScheduleJob;
