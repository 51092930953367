import * as EmailValidator from "email-validator";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser, reset } from "../../features/user/userSlice";
import Loading from "../Utility/Loading";
const Login = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const navigate = useNavigate();
  // all State
  // user state
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // error state
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  // all ref
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    if (user[0]) {
      navigate(from, { replace: true });
    }
  }, [user[0]]);

  // dispatch state
  const dispatch = useDispatch();

  // all function

  async function loginUser(credentials) {
    dispatch(getUser(credentials));
    localStorage.setItem("saved", new Date().getTime());
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const token = await loginUser({
      email,
      password,
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    alert(error);
    dispatch(reset());
  }

  const handleEmail = () => {
    const email = emailRef.current.value?.trim();
    if (email.length) {
      if (EmailValidator.validate(email)) {
        setEmailError("");
      } else {
        setEmailError("You give a Invalid Email");
      }
    } else {
      setEmailError("This Field can not be Empty");
    }
  };
  const handlePassword = () => {
    const password = passwordRef.current.value?.trim();
    if (password.length) {
      if (/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/.test(password)) {
        setPasswordError("");
      } else {
        setPasswordError("You give a Invalid Password");
      }
    } else {
      setPasswordError("This Field can not be Empty");
    }
  };
  if (user[0]) {
    navigate(from, { replace: true });
  } else {
    return (
      <div
        className="hero min-h-screen  mt-12 mb-[-100px] overflow-hidden"
        style={{
          backgroundImage: `url("https://www.gifcen.com/wp-content/uploads/2021/05/car-gif-7.gif")`,
        }}
      >
        <div className="hero-content lg:mt-[-300px] mt-[-300px] flex-col lg:flex-row-reverse lg:ml-8 opacity-100">
          <div className="text-center lg:text-left lg:mt-[-150px] lg:ml-12">
            <h1 className="text-2xl font-bold pt-3">VMS </h1>
            {/* <p className="pt-3 leading-4 text-justify text-base">
              Khulna Development Authority is an autonomous service organization
              under the control of the Ministry of Housing and Public Works. The
              organization was created on January 21, 1961, with the promise of
              planned urbanization and modern Khulna. Since its inception, the
              KDA Ordinance 1961 has empowered the organization to work on urban
              planning, development participation, and control of unplanned
              development. Apart from urban planning, KDA has made a significant
              contribution in building a modern and planned Khulna.
            </p> */}
            <p className="pt-6 text-justify leading-4 text-base">
              Vehicle management is a tedious and resource consuming task for
              any organization. A number of people and significant time is
              engaged in this regard. This effort and time can be saved using
              vehicle management software in this age. This software focuses on
              managing and maintaining vehicles of KDA through minimum
              engagement of people, effort and time. In addition various kinds
              of reports are just one click away.
            </p>
          </div>
          <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <div className="card-body">
              <form onSubmit={handleLogin}>
                <h1 className="text-xl font-bold">Login now!</h1>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    type="text"
                    placeholder="email"
                    className="input input-bordered"
                    ref={emailRef}
                    onBlur={handleEmail}
                    required
                  />
                </div>
                {emailError && (
                  <div className="alert shadow-lg">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span>{emailError}</span>
                    </div>
                  </div>
                )}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Password</span>
                  </label>
                  <input
                    type="password"
                    placeholder="password"
                    className="input input-bordered"
                    ref={passwordRef}
                    onBlur={handlePassword}
                    required
                  />
                </div>
                {passwordError && (
                  <div className="alert shadow-lg">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span>{passwordError}</span>
                    </div>
                  </div>
                )}
                <label className="label">
                  <a href="#" className="label-text-alt link link-hover">
                    Forgot password?
                  </a>
                </label>
                {loginError && (
                  <div className="alert shadow-lg">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span>{loginError}</span>
                    </div>
                  </div>
                )}
                <div className="form-control mt-6">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Login"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
