import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import {
  useGetApiHeader,
  useLogout,
  usePostApiHeader,
  useUpdateApiResponse,
} from "../../helper/customHook";

const AllRefuelRequest = ({ refuelRequests, setRefuelRequests, pageTitle }) => {
  // user state
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // update api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  const [logout] = useLogout();

  // all State

  const [petrolPumpOptions, setPetrolPumpOptions] = useState([]);
  const [petrolPumpOptionsLoading, setPetrolPumpOptionsLoading] =
    useState(true);
  const [selectedRefuelRequest, setSelectedRefuelRequest] = useState({});
  const [modalField, setModalField] = useState(false);

  // all Error State
  const [mileError, setMileError] = useState("");
  const [rateError, setRateError] = useState("");

  //   all Ref
  const pumpRef = useRef();
  const mileRef = useRef();
  const rateRef = useRef();

  //   all useEffect
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-petrol-pump", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setPetrolPumpOptions(data);
          setPetrolPumpOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all function

  const handleMile = () => {
    let mile = mileRef.current.value;
    if (mile.length) {
      setMileError("");
      if (isNaN(mile)) {
        setMileError("Miles must be a number");
      } else {
        setMileError("");
      }
    } else {
      setMileError("This Field can not be Empty");
    }
  };
  const handleRate = () => {
    let rate = rateRef.current.value;
    if (rate.length) {
      setRateError("");
      if (isNaN(rate)) {
        setRateError("Rate must be a number");
      } else {
        setRateError("");
      }
    } else {
      setRateError("This Field can not be Empty");
    }
  };

  const handleSelectedReFuelRequest = (id) => {
    setModalField(true);
    setSelectedRefuelRequest({});
    fetch(
      `https://kdaback.nescostore.com/api/edit-refuelling-request/${id}`,
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        setSelectedRefuelRequest(data);
      });
  };

  const handleRefuel = async (refuelling_id) => {
    const refuelled_at_reading = mileRef.current.value;
    const rate = rateRef.current.value;

    const approveObj = {
      refuelled_at_reading,
      rate,
      refuelling_id,
    };

    const updatedRefuel = { ...selectedRefuelRequest, status: "3" };

    fetch(
      "https://kdaback.nescostore.com/api/complete-refuelling-request",
      await handlePostApiHeader(approveObj)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/requestedrefuel");
        if (flag) {
          let updatedRefuelInfos = [];
          refuelRequests.map((r) => {
            if (r.refuelling_id === refuelling_id) {
              updatedRefuelInfos.push(updatedRefuel);
            } else {
              updatedRefuelInfos.push(r);
            }
          });

          setRefuelRequests(updatedRefuelInfos);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };
  const handleApprove = async (refuelling_id) => {
    const pump_id = pumpRef.current.value;

    const approveObj = {
      pump_id,
      refuelling_id,
    };

    const updatedRefuel = { ...selectedRefuelRequest, status: "2" };

    fetch(
      "https://kdaback.nescostore.com/api/accept-refuelling-request",
      await handlePostApiHeader(approveObj)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/requestedrefuel");
        if (flag) {
          let updatedRefuelInfos = [];
          refuelRequests.map((r) => {
            if (r.refuelling_id === refuelling_id) {
              updatedRefuelInfos.push(updatedRefuel);
            } else {
              updatedRefuelInfos.push(r);
            }
          });

          setRefuelRequests(updatedRefuelInfos);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };

  const handleReject = async (refuelling_id, refuelInfo) => {
    const confirmStatus = await window.confirm(
      "Are you sure Reject this request?"
    );

    const updatedRefuel = { ...refuelInfo, status: "4" };

    if (confirmStatus) {
      fetch(
        `https://kdaback.nescostore.com/api/reject-refuelling-request/${refuelling_id}`,
        await getApiHeader
      )
        .then((res) => res.json())
        .then(async (data) => {
          const flag = await handleUpdateResponse(data, "/requestedrefuel");
          if (flag) {
            let updatedRefuelInfos = [];

            refuelRequests.map((r) => {
              if (r.refuelling_id === refuelling_id) {
                updatedRefuelInfos.push(updatedRefuel);
              } else {
                updatedRefuelInfos.push(r);
              }
            });

            setRefuelRequests(updatedRefuelInfos);

            setModalField(false);
          }
        })

        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const handleApproveButton = (status) => {
    if (status === "1" && user[0]?.data?.staff_cat_id === "1") {
      return true;
    } else {
      return false;
    }
  };
  const handleRefuelButton = (status) => {
    if (status === "2" && user[0]?.data?.staff_cat_id === "2") {
      return true;
    } else {
      return false;
    }
  };

  //   const handlePetrolPumpEdit = async (pump_id) => {
  //     const pump_name = nameRef.current.value || selectedRefuelRequest.pump_name;
  //     const pump_address =
  //       addressRef.current.value || selectedRefuelRequest.pump_address;
  //     const contact_no =
  //       contactNoRef.current.value || selectedRefuelRequest.approval_level;
  //     const is_active = activityRef.current.value;

  //     const updatedPump = {
  //       pump_name,
  //       pump_id,
  //       pump_address,
  //       contact_no,
  //       is_active,
  //     };

  //     // fetch(
  //     //   "https://kdaback.nescostore.com/api/update-petrol-pump",
  //     //   await handlePostApiHeader(updatedPump)
  //     // )
  //     //   .then((res) => res.json())
  //     //   .then(async (data) => {
  //     //     const flag = await handleUpdateResponse(data, "/allpetrolpump");
  //     //     let updatedPumps = [];
  //     //     if (flag) {
  //     //       petrolPumps.map((p) => {
  //     //         if (p.pump_id === pump_id) {
  //     //           updatedPumps.push(updatedPump);
  //     //         } else {
  //     //           updatedPumps.push(p);
  //     //         }
  //     //       });

  //     //       setPetrolPumps(updatedPumps);
  //     //       setModalField(false);
  //     //     }
  //     //   })
  //     //   .catch((error) => {
  //     //     console.log("error:", error);
  //     //   });
  //   };

  const columns = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicle_no,
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) => row.driver_name,
      sortable: true,
    },
    {
      name: "Fuel (L)",
      selector: (row) => row.requested_fuel,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.refuelling_status,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div onClick={() => handleSelectedReFuelRequest(row.refuelling_id)}>
          {handleApproveButton(row.status) && (
            <label
              className="btn-link px-1"
              onClick={() => handleReject(row?.refuelling_id, row)}
            >
              <i title="Reject" class="fa-solid fa-xmark"></i>
            </label>
          )}
          {handleApproveButton(row.status) && (
            <label className="btn-link px-2" for="approve-modal">
              <i title="Approve" class="fa-solid fa-check"></i>
            </label>
          )}
          {handleRefuelButton(row.status) && (
            <label className="btn-link px-2" for="refuel-modal">
              <i title="Approve" class="fa-solid fa-check"></i>
            </label>
          )}
          <label className="btn-link px-1 modal-button" for="refuel-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">{pageTitle}</h2>
      <DataTable
        pagination
        columns={columns}
        data={refuelRequests}
        selectableRows
      />
      {modalField && (
        <>
          {/* details About this Request  */}
          <div>
            <input type="checkbox" id="refuel-details" class="modal-toggle" />
            <label for="refuel-details" class="modal cursor-pointer">
              <label
                class="modal-box relative lg:w-5/12 max-w-5xl text-justify"
                for=""
              >
                <label
                  htmlFor="refuel-details"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <h3 class="text-lg font-bold">Details About This Request</h3>

                <div className="py-4">
                  <div className="overflow-x-auto">
                    <table className="table table-zebra overflow-x-scroll w-full">
                      <tbody>
                        <tr>
                          <td>Vehicle No</td>
                          <td>{selectedRefuelRequest?.vehicle_no}</td>
                        </tr>
                        <tr>
                          <td>Driver</td>
                          <td>{selectedRefuelRequest?.driver_name}</td>
                        </tr>
                        <tr>
                          <td>Requested At</td>
                          <td>
                            {selectedRefuelRequest?.requested_at_reading} KM
                          </td>
                        </tr>
                        <tr>
                          <td>Requested Refuel</td>
                          <td>{selectedRefuelRequest?.requested_fuel} L</td>
                        </tr>
                        <tr>
                          <td>Request Date</td>
                          <td>
                            {/* {moment(selectedRefuelRequest?.request_date).format(
                            "DD/MM/YYYY"
                          )} */}
                            {selectedRefuelRequest?.request_date}
                          </td>
                        </tr>
                        {/* <tr>
                        <td>To Date</td>
                        <td>
                          {moment(selectedRefuelRequest?.to_date).format(
                            "DD/MM/YYYY, h:mm A"
                          )}
                        </td>
                      </tr> */}

                        {parseInt(selectedRefuelRequest.status) >= 2 && (
                          <>
                            <tr>
                              <td>Pump Name</td>
                              <td>{selectedRefuelRequest.pump_name}</td>
                            </tr>
                            <tr>
                              <td>Approve Date</td>
                              <td>{selectedRefuelRequest.approve_date}</td>
                            </tr>
                            <tr>
                              <td>Validity Date</td>
                              <td>{selectedRefuelRequest.validity_date}</td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td>Status</td>
                          <td>{selectedRefuelRequest.refuelling_status}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* Approve modal  */}
          <div>
            <input type="checkbox" id="approve-modal" class="modal-toggle" />
            <label for="approve-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-3/6 max-w-5xl text-justify"
                for="approve-modal"
              >
                <label
                  htmlFor="approve-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>

                <div className="py-4">
                  <div className="overflow-x-auto">
                    <div className="mx-auto my-4">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Fuel Pump</span>
                          <select
                            className="select select-bordered w-4/6"
                            ref={pumpRef}
                          >
                            <option disabled selected>
                              {" "}
                              ------ Select Fuel Pump ------{" "}
                            </option>
                            {petrolPumpOptionsLoading ? (
                              <option disabled>Loading ....</option>
                            ) : (
                              petrolPumpOptions.map((petrolPumpOption) => (
                                <option value={petrolPumpOption.pump_id}>
                                  {petrolPumpOption.pump_name}{" "}
                                </option>
                              ))
                            )}
                          </select>
                        </label>
                      </div>

                      <div class="form-control my-4">
                        <label className="input-group">
                          <button
                            onClick={() => {
                              handleApprove(
                                selectedRefuelRequest?.refuelling_id
                              );
                            }}
                            className="btn w-32 mx-auto btn-danger"
                          >
                            Approve
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* Refuel modal  */}
          <div>
            <input type="checkbox" id="refuel-modal" class="modal-toggle" />
            <label for="refuel-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-3/6 max-w-5xl text-justify"
                for="refuel-modal"
              >
                <label
                  htmlFor="refuel-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <div className="py-12">
                  <div className="overflow-x-auto">
                    <div className="mx-auto my-4">
                      <div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Meter Reading (KM) </span>
                            <input
                              type="text"
                              placeholder="Meter Reading In KM"
                              className="input w-4/6 input-bordered"
                              ref={mileRef}
                              onBlur={handleMile}
                            />
                          </label>
                        </div>
                        {mileError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{mileError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Rate</span>
                            <input
                              type="text"
                              placeholder="Rate Per Litter"
                              className="input w-4/6 input-bordered"
                              ref={rateRef}
                              onBlur={handleRate}
                            />
                          </label>
                        </div>
                        {rateError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{rateError}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div class="form-control my-4">
                        <label className="input-group">
                          <button
                            onClick={() => {
                              handleRefuel(
                                selectedRefuelRequest?.refuelling_id
                              );
                            }}
                            className="btn w-32 mx-auto btn-danger"
                          >
                            Approve
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default AllRefuelRequest;
