import React, { useRef } from "react";
import { usePostApiHeader, usePostApiResponse } from "../../helper/customHook";

const AddPetrolPump = () => {
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // all ref
  const nameRef = useRef();
  const addressRef = useRef();
  const contactNoRef = useRef();
  const activityRef = useRef();

  const handleAddPump = async () => {
    const pump_name = nameRef.current.value;
    const pump_address = addressRef.current.value;
    const contact_no = contactNoRef.current.value;
    const is_active = activityRef.current.value;

    const newPump = { pump_name, pump_address, contact_no, is_active };
    
    fetch(
      "https://kdaback.nescostore.com/api/store-pump",
      await handlePostApiHeader(newPump)
    )
      .then((res) => res.json())
      .then((data) => {
        
        handlePostResponse(data, "/allpetrolpump");
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  return (
    <div>
      <h2 className="text-xl my-4 text-center font-semibold">
        Fuel Pump Entry
      </h2>
      <div className="mx-auto lg:w-1/2">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Name </span>
            <input
              type="text"
              placeholder="Name of Fuel Pump"
              className="input w-4/6 input-bordered"
              ref={nameRef}
            />
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Address </span>
            <input
              type="text"
              placeholder="Address of Fuel Pump"
              className="input w-4/6 input-bordered"
              ref={addressRef}
            />
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Contact Number</span>
            <input
              type="text"
              placeholder="Contact Number of Fuel Pump"
              className="input w-4/6 input-bordered"
              ref={contactNoRef}
            />
          </label>
        </div>

        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Activity</span>
            <select className="select select-bordered w-4/6" ref={activityRef}>
              <option value="1">Active</option>

              <option value="0">Not Active</option>
            </select>
          </label>
        </div>
        <div className="w-48 mx-auto">
          <button
            className="btn btn-primary text-center w-full my-10 mx-auto"
            onClick={handleAddPump}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPetrolPump;
