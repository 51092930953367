import Multiselect from "multiselect-react-dropdown";
import React, { useRef, useState } from "react";

const EndTrip = () => {
  // all state
  const [milesReadingError, setMilesReadingError] = useState("");

  // All use Ref
  const milesReadingRef = useRef();
  const testRef = useRef();

  const testOptions = [
    {
      cat: "Group 1",
      key: "Option 1",
    },
    {
      cat: "Group 1",
      key: "Option 2",
    },
    {
      cat: "Group 1",
      key: "Option 3",
    },
    {
      cat: "Group 2",
      key: "Option 4",
    },
    {
      cat: "Group 2",
      key: "Option 5",
    },
    {
      cat: "Group 2",
      key: "Option 6",
    },
    {
      cat: "Group 2",
      key: "Option 7",
    },
  ]

  // all Function

  const handleMilesReading = () => {
    const milesReading = milesReadingRef.current.value;
    if (milesReading.length) {
      if (isNaN(milesReading)) {
        setMilesReadingError("miles must be a number");
      } else {
        setMilesReadingError("");
      }

    } else {
      setMilesReadingError("This Field can not be Empty");
    }
  };

  return (
    <div>
      <div className="w-2/4 mx-auto">
        <div className="mx-auto my-4">
          <div className="form-control my-4">
            <label className="input-group">
              <span className="w-2/6">Miles Reading</span>
              <input
                type="text"
                ref={milesReadingRef}
                placeholder="Final Miles Reading"
                className="input input-bordered w-4/6"
                onBlur={handleMilesReading}
              />
            </label>
          </div>
          {milesReadingError && (
            <div className="alert shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <span>{milesReadingError}</span>
              </div>
            </div>
          )}

          <div className="form-control my-4">
            <label className="input-group">
              <button className="btn w-32 mx-auto">End</button>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndTrip;
