import * as EmailValidator from "email-validator";
import React, { useEffect, useRef, useState } from "react";
import {
  useGetApiHeader,
  useLogout,
  usePostApiHeader,
  usePostApiResponse
} from "../../helper/customHook";

const EmployeeRegistrstion = () => {
  // all state
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // logout state
  const [logout] = useLogout();
  // error State
  const [nameError, setNameError] = useState("");
  const [employeeIdError, setEmployeeIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [joiningDateError, setJoiningDateError] = useState("");
  const [drivingLicenseError, setDrivingLicenseError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [alternateContactNoError, setAlternateContactNoError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [nidNoError, setNidNoError] = useState("");

  // field State and
  const [driverField, setDriverField] = useState(false);
  const [submitFormDisable, setSubmitFormDisable] = useState(true);
  const [dedicatedField, setDedicatedField] = useState(false);

  // data state
  const [designationOptions, setDesignationOptions] = useState([]);
  const [driverCategoryOptions, setDriverCategoryOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [officerOptions, setOfficerOptions] = useState([]);

  // loading state
  const [driverCategoryLoading, setDriverCategoryLoading] = useState(true);
  const [designationLoading, setDesignationLoading] = useState(true);
  const [officerOptionsLoading, setOfficerOptionsLoading] = useState(true);
  const [purposeOptionsLoading, setPurposeOptionsLoading] = useState(true);

  // All use Ref
  const nameRef = useRef();
  const employeeIdRef = useRef();
  const passwordRef = useRef();
  const joiningDateRef = useRef();
  const staffCategoryRef = useRef();
  const drivingLicenseRef = useRef();
  const contactNoRef = useRef();
  const alternateContactNoRef = useRef();
  const designationRef = useRef();
  const nidNoRef = useRef();
  const drivingLicenseCategoryRef = useRef();
  const statusRef = useRef();
  const availablityRef = useRef();
  const emailRef = useRef();
  const purposeRef = useRef();
  const dedicatedToRef = useRef();

  // all useEffect

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/actv-designations", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDesignationOptions(data);
          setDesignationLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch(
      "https://kdaback.nescostore.com/api/actv-driver-categories",
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDriverCategoryOptions(data);
          setDriverCategoryLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-purposes", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setPurposeOptions(data);
          setPurposeOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all Function

  const handleName = () => {
    const name = nameRef.current.value?.trim();
    if (name.length) {
      if (/^[a-zA-Z .]*$/.test(name)) {
        setNameError("");
        setSubmitFormDisable(false);
      } else {
        setNameError("Invalid Charecter In This field");
        setSubmitFormDisable(false);
      }
    } else {
      setNameError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handleEmployeeId = () => {
    const employeeId = employeeIdRef?.current?.value?.trim();
    if (employeeId.length) {
      if (/^[a-zA-Z0-9-]*$/.test(employeeId)) {
        setEmployeeIdError("");
        setSubmitFormDisable(false);
      } else {
        setEmployeeIdError("Invalid Charecter In This field");
        setSubmitFormDisable(false);
      }
    } else {
      setEmployeeIdError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handlePassword = () => {
    const password = passwordRef?.current?.value?.trim();
    if (password.length) {
      if (password.length >= 8) {
        if (/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/.test(password)) {
          setPasswordError("");
          setSubmitFormDisable(false);
        } else {
          setPasswordError("Invalid Charecter In This field");
          setSubmitFormDisable(false);
        }
      } else {
        setPasswordError("Password must be minimum 8 charecter");
        setSubmitFormDisable(true);
      }
    } else {
      setPasswordError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handleJoiningDate = () => {
    const joiningDate = joiningDateRef.current.value;
    const joiningDateTime = new Date(joiningDate);
    const nowTime = Date.now();

    if (nowTime < joiningDateTime.getTime()) {
      setJoiningDateError("joining Date must be Earlyer than today");
    } else {
      setJoiningDateError("");
    }
  };
  const handleDrivingLicense = () => {
    const drivingLicense = drivingLicenseRef?.current?.value?.trim();
    if (drivingLicense.length) {
      if (/^[a-zA-Z0-9]*$/.test(drivingLicense)) {
        setDrivingLicenseError("");
        setSubmitFormDisable(false);
      } else {
        setDrivingLicenseError("Invalid Charecter In This field");
        setSubmitFormDisable(true);
      }
    } else {
      setSubmitFormDisable(true);
      setDrivingLicenseError("This Field can not be Empty");
    }
  };
  const handleEmail = () => {
    const email = emailRef?.current?.value;
    if (email.length) {
      if (EmailValidator.validate(email)) {
        setEmailError("");
        setSubmitFormDisable(false);
      } else {
        setEmailError("You give a Invalid Email");
        setSubmitFormDisable(true);
      }
    } else {
      setEmailError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };

  const handleStaffCategory = () => {
    const staffCategory = staffCategoryRef.current.value;
    if (staffCategory === "2") {
      setDriverField(true);
    } else {
      setDriverField(false);
      setDrivingLicenseError("");
    }
  };
  const handleContactNo = () => {
    const contactNo = contactNoRef.current.value?.trim();
    if (contactNo.length) {
      if (/^[\d]{11}$/.test(contactNo)) {
        setContactNoError("");
        setSubmitFormDisable(false);
      } else {
        setContactNoError(
          "Invalid Charecter or your contact number not 11 digit"
        );
        setSubmitFormDisable(true);
      }
    } else {
      setContactNoError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handleAlternateContactNo = () => {
    const alternateContactNo = alternateContactNoRef.current.value?.trim();
    if (alternateContactNo.length) {
      if (/^[\d]{11}$/.test(alternateContactNo)) {
        setAlternateContactNoError("");
        setSubmitFormDisable(false);
      } else {
        setAlternateContactNoError(
          "Invalid Charecter or your contact number not 11 digit"
        );
        setSubmitFormDisable(true);
      }
    } else {
      setAlternateContactNoError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handleNidNo = () => {
    const nidNo = nidNoRef.current.value?.trim();
    if (nidNo.length) {
      if (/^[\d]{10,30}$/.test(nidNo)) {
        setNidNoError("");
        setSubmitFormDisable(false);
      } else {
        setNidNoError(
          "Invalid Charecter or your NID number not 10 or 13 digit"
        );
        setSubmitFormDisable(true);
      }
    } else {
      setNidNoError("This Field can not be Empty");
      setSubmitFormDisable(true);
    }
  };
  const handlePurpose = () => {
    let purpose = purposeRef.current.value;
    const dedicatedPurpuse = purposeOptions.filter(
      (purposeOption) => purposeOption.car_purpose_description === "Dedicated"
    );
    if (dedicatedPurpuse.length) {
      if (purpose === dedicatedPurpuse[0].car_purpose_id) {
        setDedicatedField(true);
      } else {
        setDedicatedField(false);
      }
    }
  };

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setOfficerOptions(data);
          setOfficerOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  const handleAddEmployee = async (event) => {
    event.preventDefault();
    

    const full_name = nameRef.current.value;
    const employee_id_no = employeeIdRef.current.value;
    const password = passwordRef.current.value;
    const joining_date = joiningDateRef.current.value;
    const mobile_no = contactNoRef.current.value;
    const alternate_mobile_no = alternateContactNoRef.current.value;
    const nid = nidNoRef.current.value;
    const email = emailRef?.current?.value;
    const staff_cat_id = staffCategoryRef.current.value;
    const is_active = statusRef.current.value;
    let newEmployee = {
      full_name,
      employee_id_no,
      password,
      joining_date,
      mobile_no,
      alternate_mobile_no,
      nid,
      staff_cat_id,
      email,
      is_active,};
    if (staff_cat_id === "1") {
      const designation_id = designationRef.current.value;
      newEmployee = {
        ...newEmployee , designation_id
      };


    } 
    else {
      const driver_lic_no = drivingLicenseRef.current.value;
      const driver_cat_id = drivingLicenseCategoryRef.current.value;
      const driver_availablity = availablityRef.current.value;
      let purpose_id = purposeRef.current.value;

      newEmployee = {
        ...newEmployee,
        driver_lic_no,
        driver_cat_id,
        driver_availablity,
        purpose_id
      };
      
      if(purpose_id==="2"){
        const dedicated_for_id = dedicatedToRef.current.value
        newEmployee = {...newEmployee,dedicated_for_id}

      }
    }

    fetch(
      "https://kdaback.nescostore.com/api/user-register",
      await handlePostApiHeader(newEmployee)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/allemployee"))
      .catch((error) => {
        console.log("error:", error);
      });
  };

  return (
    <div>
      <h3 className="text-xl text-center font-semibold my-5"> Add User </h3>
      <form onSubmit={handleAddEmployee}>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
          <div>
            <div class="form-control my-4">
              <label className="input-group">
                <span className="w-2/6">Full Name</span>
                <input
                  type="text"
                  placeholder="Name Of Officer"
                  class="input input-bordered w-4/6"
                  ref={nameRef}
                  onBlur={handleName}
                  required
                />
              </label>
            </div>
            {nameError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{nameError}</span>
                </div>
              </div>
            )}
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Employee ID</span>
                <input
                  type="text"
                  placeholder="Employee ID Number"
                  class="input input-bordered w-4/6"
                  ref={employeeIdRef}
                  onBlur={handleEmployeeId}
                  required
                />
              </label>
            </div>
            {employeeIdError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{employeeIdError}</span>
                </div>
              </div>
            )}

            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Joining Date</span>
                <input
                  type="date"
                  class="input input-bordered w-4/6"
                  ref={joiningDateRef}
                  onBlur={handleJoiningDate}
                  required
                />
              </label>
            </div>
            {joiningDateError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{joiningDateError}</span>
                </div>
              </div>
            )}
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Mobile Number</span>
                <input
                  type="text"
                  placeholder="Contact Number"
                  class="input input-bordered w-4/6"
                  ref={contactNoRef}
                  onBlur={handleContactNo}
                  required
                />
              </label>
            </div>
            {contactNoError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{contactNoError}</span>
                </div>
              </div>
            )}
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Alternate Mobile</span>
                <input
                  type="text"
                  placeholder="Alternate Contact Number"
                  class="input input-bordered w-4/6"
                  ref={alternateContactNoRef}
                  onBlur={handleAlternateContactNo}
                  required
                />
              </label>
            </div>
            {alternateContactNoError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{alternateContactNoError}</span>
                </div>
              </div>
            )}
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">NID No</span>
                <input
                  type="text"
                  placeholder="National Id Card Number"
                  class="input input-bordered w-4/6"
                  ref={nidNoRef}
                  onBlur={handleNidNo}
                  required
                />
              </label>
            </div>
            {nidNoError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{nidNoError}</span>
                </div>
              </div>
            )}
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Email</span>
                <input
                  type="email"
                  placeholder="Employee Email"
                  class="input input-bordered w-4/6"
                  ref={emailRef}
                  onBlur={handleEmail}
                  required
                />
              </label>
            </div>
            {emailError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{emailError}</span>
                </div>
              </div>
            )}
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Password</span>
                <input
                  type="password"
                  placeholder="Password"
                  class="input input-bordered w-4/6"
                  ref={passwordRef}
                  onBlur={handlePassword}
                  required
                />
              </label>
            </div>
            {passwordError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{passwordError}</span>
                </div>
              </div>
            )}
          </div>
          <div>
            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Staff Category</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={staffCategoryRef}
                  onChange={handleStaffCategory}
                >
                  <option value="1">Officer</option>
                  <option value="2">Driver</option>
                </select>
              </label>
            </div>
            {driverField ? (
              <>
                <div class="form-control my-4">
                  <label class="input-group">
                    <span className="w-2/6">Driving License</span>
                    <input
                      type="text"
                      placeholder="Driving License Number"
                      class="input input-bordered w-4/6"
                      ref={drivingLicenseRef}
                      onBlur={handleDrivingLicense}
                    />
                  </label>
                </div>
                {drivingLicenseError && (
                  <div className="alert shadow-lg">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current flex-shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span>{drivingLicenseError}</span>
                    </div>
                  </div>
                )}
                <div class="form-control my-4">
                  <label class="input-group">
                    <span className="w-2/6">Licenses Category</span>
                    <select
                      className="select select-bordered w-4/6"
                      ref={drivingLicenseCategoryRef}
                    >
                      <option disabled selected>
                        {" "}
                        ------ Driver Category ------{" "}
                      </option>
                      {driverCategoryLoading ? (
                        <option disabled>Loading ....</option>
                      ) : (
                        driverCategoryOptions.map((driverCategoryOption) => (
                          <option value={driverCategoryOption.driver_cat_id}>
                            {" "}
                            {driverCategoryOption.driver_cat_name}{" "}
                          </option>
                        ))
                      )}
                    </select>
                  </label>
                </div>
                <div className="form-control my-3">
                  <label className="input-group">
                    <span className="w-2/6">Purpose</span>
                    <select
                      className="select select-bordered w-4/6"
                      ref={purposeRef}
                      onChange={handlePurpose}
                    >
                      <option disabled selected>
                        {" "}
                        ------ Select Purpose ------{" "}
                      </option>
                      {purposeOptionsLoading ? (
                        <option disabled>Loading ....</option>
                      ) : (
                        purposeOptions.map((purposeOption) => (
                          <option value={purposeOption.car_purpose_id}>
                            {" "}
                            {purposeOption.car_purpose_description}{" "}
                          </option>
                        ))
                      )}
                    </select>
                  </label>
                </div>
                {dedicatedField && (
                        <>
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Dedicated To</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={dedicatedToRef}
                              >
                                <option disabled selected>
                                  {" "}
                                  ------ Select Officer ------{" "}
                                </option>
                                {officerOptionsLoading ? (
                                  <option disabled>Loading ....</option>
                                ) : (
                                  officerOptions.map((officerOption) => (
                                    <option value={officerOption.user_id}>
                                      {" "}
                                      {officerOption.full_name}{" "}
                                    </option>
                                  ))
                                )}
                              </select>
                            </label>
                          </div>
                        </>
                      )}
                <div class="form-control my-4">
                  <label class="input-group">
                    <span className="w-2/6">Availablity</span>
                    <select
                      className="select select-bordered w-4/6"
                      ref={availablityRef}
                    >
                      <option value="1">Available</option>
                      <option value="2">On-Leave</option>
                    </select>
                  </label>
                </div>
                
              </>
            ) : (
              <>
                <div class="form-control my-4">
                  <label class="input-group">
                    <span className="w-2/6">Designation</span>
                    <select
                      className="select select-bordered w-4/6"
                      ref={designationRef}
                    >
                      <option disabled selected>
                        {" "}
                        ------ Designation ------{" "}
                      </option>
                      {designationLoading ? (
                        <option disabled>Loading ....</option>
                      ) : (
                        designationOptions?.map((designationOption) => (
                          <option
                            key={designationOption.designation_id}
                            value={designationOption.designation_id}
                          >
                            {designationOption.designation}
                          </option>
                        ))
                      )}
                    </select>
                  </label>
                </div>
              </>
            )}

            <div class="form-control my-4">
              <label class="input-group">
                <span className="w-2/6">Status</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={statusRef}
                >
                  <option value={1}>Active</option>
                  <option value={2}>Idle</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        <div class="form-control my-4">
          <input
            className="btn btn-danger w-48 mx-auto"
            disabled={submitFormDisable}
            type="submit"
            value="Add Employee"
          />
        </div>
      </form>
    </div>
  );
};

export default EmployeeRegistrstion;
