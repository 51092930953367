import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Utility/Loading";
import { reset } from "../../features/user/userSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { getApiHeader, useGetApiHeader, useLogout } from "../../helper/customHook";

const StartTrip = () => {
  // all state
  // get Header State 
  const [getApiHeader] = useGetApiHeader()

  // logout state 
  const [logout] = useLogout()
  // all error State
  const [milesReadingError, setMilesReadingError] = useState("");

  // all data State
  const [driverOptions, setDriverOptions] = useState([]);
  // all Loading Error State
  const [driverLoading, setDriverLoading] = useState(true);

  // All use Ref
  const milesReadingRef = useRef();




  return (
    
    <div>

    </div>

  );
};

export default StartTrip;
