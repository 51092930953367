import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetApiHeader, useLogout, usePostApiHeader, useUpdateApiResponse } from "../../helper/customHook";

const AllCarCategory = () => {
  const [logout] = useLogout();

  // get api Headers
  const [getApiHeader] = useGetApiHeader();
    // update api
    const [handlePostApiHeader] = usePostApiHeader();
    const [handleUpdateResponse] = useUpdateApiResponse();

  // all State
    // field State
    const [modalField, setModalField] = useState(false);

  const [carCategories, setCarCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [carCategoryLoading, setCarCategoryLoading] = useState(true);
  

  // all Ref
  const nameRef = useRef();
  const activityRef = useRef();

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-categories", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setCarCategories(data);
          setCarCategoryLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // const all Function
  const handleSelectedCategory = (id) => {
    setModalField(true)
    setSelectedCategory({})
    fetch(`https://kdaback.nescostore.com/api/edit-car-category/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedCategory(data)
      });
  };
  const handleCategoryEdit = async (car_category_id) => {
    
    const car_category_name =
      nameRef.current.value || selectedCategory.car_category_name;
    const is_active = activityRef.current.value;

    const updatedCategory = { car_category_name, is_active,car_category_id };
    fetch(
      "https://kdaback.nescostore.com/api/update-car-category",
      await handlePostApiHeader(updatedCategory)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allcarcat");
        let updatedCategories = [];
        if (flag) {
          carCategories.map((c) => {
            if (c.car_category_id === car_category_id) {
              updatedCategories.push(updatedCategory);
            } else {
              updatedCategories.push(c);
            }
          });

          setCarCategories(updatedCategories);
          setModalField(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
    
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.car_category_name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active==="1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
        onClick={() => handleSelectedCategory(row.car_category_id)}
        className="text-base"
        >
          
        <label className=" btn-link px-2" for="edit-category">
          <i
            title="Edit"
            class="fa-regular fa-pen-to-square"
            ></i>
        </label>
        <label
            className="btn-link px-2 modal-button"
            for="car-category-details"
          >
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
      </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">
        {" "}
        Vehicle Category{" "}
      </h2>
      <DataTable
        pagination
        columns={columns}
        data={carCategories}
        progressPending={carCategoryLoading}
        selectableRows
      />
      {modalField && (
        <div>
          <input type="checkbox" id="edit-category" class="modal-toggle" />
          <label for="car-schedule-details" class="modal cursor-pointer">
            <label
              class="modal-box relative w-3/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-category"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Vehicle Category
                    </h2>
                    <div className="mx-auto lg:w-1/2">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Name</span>
                          <input
                            type="text"
                            ref={nameRef}
                            key={selectedCategory.car_category_name}
                            defaultValue={selectedCategory.car_category_name}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>

                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Activity</span>
                          <select
                            className="select select-bordered w-4/6"
                            ref={activityRef}
                            key={selectedCategory.is_active}
                            defaultValue={selectedCategory.is_active}
                          >
                            <option value="1">Active</option>

                            <option value="0">Not Active</option>
                          </select>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary my-10 mx-auto lg:w-full"
                        onClick={() =>
                          handleCategoryEdit(selectedCategory.car_category_id)
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
            {/* details view  */}
            <div>
        <input type="checkbox" id="car-category-details" class="modal-toggle" />
        <label for="car-category-details" class="modal cursor-pointer">
          <label class="modal-box relative w-2/6 max-w-5xl text-justify" for="">
            <label
              htmlFor="car-category-details"
              className="btn btn-sm btn-circle absolute right-2 top-4"
            >
              ✕
            </label>
            <h3 class="text-lg text-center font-bold">Details About This Vehicle Category</h3>

            <div className="py-4">
              <div className="overflow-x-auto">
                <table className="table table-zebra w-full overflow-x-scroll">
                  <tbody>
                    <tr>
                      <td>Car Category Name</td>
                      <td>{selectedCategory?.car_category_name}</td>
                    </tr>

                    <tr>
                      <td>Status</td>
                      <td>{(selectedCategory.is_active==="1")?"Active":"Not Active"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </label>
        </label>
      </div>
    </div>
  );
};

export default AllCarCategory;
