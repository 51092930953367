import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGetApiHeader } from "../../helper/customHook";

const OfficerWiseBills = () => {
  const [tripReportInfos, setTripReportInfos] = useState([]);
  const [pageTitle, setPageTitle] = useState("Officer Wise Bill");

  // get Header State
  const [getApiHeader] = useGetApiHeader();
  const [officerOptions, setOfficerOptions] = useState([]);
  const [officerOptionLoading, setOfficerOptionLoading] = useState(true);

  const officerRef = useRef();

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setOfficerOptions(data);
        setOfficerOptionLoading(false);
      });
  }, []);

  const handleGrandTotal = () => {
    let grandTotal = 0;
    tripReportInfos.map((trip) => {
      grandTotal = grandTotal + parseFloat(trip.total_bill);
    });
    return grandTotal.toFixed(2);
  };

  const handleSelectedOfficer = async () => {
    const user_profile_id = officerRef.current.value;
    //  vehicleRef.current.value = ""
    //  driverRef.current.value = ""

    fetch(
      `https://kdaback.nescostore.com/api/ow-bill-report/${user_profile_id}`,
      await getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        
        setTripReportInfos(data);
        if (data.length === 0) {
          toast.error("No trip done this officer");
        }
      });
  };
  return (
    <div className="mt-20">
      <table className="w-full table mb-12">
        <thead>
          <tr>
            <th>
              <select
                className="select ml-9"
                ref={officerRef}
                onChange={handleSelectedOfficer}
              >
                <option disabled selected>
                  {" "}
                  ------ Select Officer ------{" "}
                </option>
                {officerOptionLoading ? (
                  <option disabled>Loading ....</option>
                ) : (
                  officerOptions.map((officerOption) => (
                    <option value={officerOption.user_profile_id}>
                      {" "}
                      {officerOption.full_name}{" "}
                    </option>
                  ))
                )}
              </select>
            </th>
          </tr>
        </thead>
      </table>
      {tripReportInfos.length !== 0 && (
        <>
          <div className="mt-6">
            {/* <button className="btn" onClick={print}>Print</button> */}
            <h1 className="text-4xl my-3 text-center font-bold">
              Khulna Development Authority{" "}
            </h1>
            <h1 className="text-2xl my-3 text-center font-bold">
              Shibbari , Khulna - 9000
            </h1>

            <h1 className="text-xl my-3 text-center font-bold">{pageTitle}</h1>

            <div className="overflow-x-auto mt-12 pb-12">
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    <th>SL No</th>
                    <th>From Time </th>
                    <th>To Time</th>
                    <th>Car No</th>
                    <th>Driver Name</th>
                    <th>Pick - Dest</th>
                    <th>Distance </th>
                    <th>Duration </th>
                    <th>Distance Bill</th>
                    <th>Duration Bill</th>
                    <th>Total Bill </th>
                  </tr>
                </thead>
                <tbody>
                  {tripReportInfos.map((trip, index) => (
                    <tr>
                      <th>{index + 1}</th>
                      <td>
                        {moment(trip?.from_date).format("DD/MM/YYYY, h:mm A")}
                      </td>
                      <td>
                        {moment(trip?.to_date).format("DD/MM/YYYY, h:mm A")}
                      </td>
                      <td>{trip?.vehicle_no}</td>
                      <td>{trip?.driver_name}</td>
                      <td>
                        {trip?.pick_location_id +
                          " - " +
                          trip?.destinition_location_id}
                      </td>
                      <td>{trip?.distance} KM</td>
                      <td>{trip?.duration} Hour</td>
                      <td>{parseFloat(trip?.distance_bill).toFixed(2)} Taka</td>
                      <td>{parseFloat(trip?.duration_bill).toFixed(2)} Taka</td>
                      <td>{parseFloat(trip?.total_bill).toFixed(2)} Taka</td>
                    </tr>
                  ))}

                  <tr className="font-bold text-lg">
                    <th></th>
                    <td></td>
                    <td>Total Trip Count</td>
                    <td>{tripReportInfos?.length}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Grand Total</td>
                    <td>{handleGrandTotal()} Taka</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OfficerWiseBills;
