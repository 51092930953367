import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

const AllDriverCategory = () => {
  const [logout] = useLogout();

  // all State

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // update api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  const [driverCatagories, setDriverCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [driverCategoriesLoading, setDriverCategoriesLoading] = useState(true);
  // field State
  const [modalField, setModalField] = useState(false);
  // all Ref
  const nameRef = useRef();
  const shortCodeRef = useRef();
  const activityRef = useRef();

  useEffect(() => {
    fetch(
      "https://kdaback.nescostore.com/api/all-driver-categories",
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDriverCategories(data);
          setDriverCategoriesLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all function
  const handleSelectedCategory = (id) => {
    setSelectedCategory({})
    setModalField(true)
    fetch(`https://kdaback.nescostore.com/api/edit-driver-category/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedCategory(data)
      });
  };

  const handleCategoryEdit = async (driver_cat_id) => {
    const driver_cat_name =
      nameRef?.current.value || selectedCategory.driver_cat_name;
    const driver_cat_short_code =
      shortCodeRef?.current.value || selectedCategory.driver_cat_short_code;
    const is_active = activityRef.current.value;

    const updatedDriverCat = {
      driver_cat_name,
      driver_cat_short_code,
      driver_cat_id,
      is_active,
    };
    
    fetch(
      "https://kdaback.nescostore.com/api/update-driver-category",
      await handlePostApiHeader(updatedDriverCat)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/alldrivercat");
        let updatedDriverCats = [];
        if (flag) {
          driverCatagories.map((c) => {
            if (c.driver_cat_id === driver_cat_id) {
              updatedDriverCats.push(updatedDriverCat);
            } else {
              updatedDriverCats.push(c);
            }
          });

          setDriverCategories(updatedDriverCats);
          setModalField(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });


  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.driver_cat_name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <label className=" btn-link mx-1" for="edit-brand">
          <i
            title="Edit"
            onClick={() => handleSelectedCategory(row.driver_cat_id)}
            class="fa-regular fa-pen-to-square"
          ></i>
        </label>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">
        {" "}
        All Driver Category{" "}
      </h2>
      <DataTable
        pagination
        columns={columns}
        data={driverCatagories}
        progressPending={driverCategoriesLoading}
        selectableRows
      />
      {modalField && (
        <div>
          <input type="checkbox" id="edit-brand" class="modal-toggle" />
          <label for="car-schedule-details" class="modal cursor-pointer">
            <label
              class="modal-box relative w-3/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-brand"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Driver Category Info
                    </h2>
                    <div className="mx-auto lg:w-1/2">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Name</span>
                          <input
                            type="text"
                            ref={nameRef}
                            key={selectedCategory.driver_cat_name}
                            defaultValue={selectedCategory.driver_cat_name}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Short Code</span>
                          <input
                            type="text"
                            ref={shortCodeRef}
                            key={selectedCategory.driver_cat_short_code}
                            defaultValue={
                              selectedCategory.driver_cat_short_code
                            }
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>

                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Activity</span>
                          <select
                            className="select select-bordered w-4/6"
                            ref={activityRef}
                            key={selectedCategory.is_active}
                            defaultValue={selectedCategory.is_active}
                          >
                            <option value="1">Active</option>

                            <option value="0">Not Active</option>
                          </select>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary my-10 mx-auto lg:w-full"
                        onClick={() =>
                          handleCategoryEdit(selectedCategory.driver_cat_id)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
    </div>
  );
};

export default AllDriverCategory;
