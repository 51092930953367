import React from "react";
import { Link, Outlet } from "react-router-dom";

const EditProfile = () => {
  return (
    <div className="drawer drawer-mobile mt-28">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col items-center justify-center mt-[-250px]">
        <label
          htmlFor="my-drawer-2"
          className="btn btn-primary drawer-button lg:hidden"
        >
          Open drawer
        </label>
        
        <Outlet/>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
          <li>
            <Link to="">Update Password</Link>
          </li>
          <li>
            <Link to = "/editprofile/updateinfo">Update Profile Info</Link>
          </li>
          <li>
            <Link to = "/editprofile/profilepicture">Update Profile Picture</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EditProfile;
