import React, { useEffect } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar
} from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLogout } from "../../../helper/customHook";

export const DashboardLayout = ({ children }) => {
  const { toggleSidebar } = useProSidebar();
  // user state
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));

  const [logout] = useLogout();
  useEffect(() => {
    let hours = 12;
    let saved = localStorage.getItem("saved");
    if (saved && new Date().getTime() - saved > hours * 60 * 60 * 1000) {
      localStorage.clear();
      logout();
    }
  }, []);

  const vehicleMenu = [
    { _id: 2, path: "/carentry", name: "New Vehicle" },
    { _id: 1, path: "/allcar", name: "Vehicle List" },
  ];
  const vehicleCatMenu = [
    { _id: 6, path: "/addcarcat", name: "New Vehicle Category" },
    { _id: 13, path: "/allcarcat", name: "All Vehicle Category" },
  ];
  const brandsMenu = [
    { _id: 5, path: "/addbrand", name: "New brand" },
    { _id: 12, path: "/allbrand", name: "All Brand" },
  ];
  const modelsMenu = [
    { _id: 3, path: "/addmodel", name: "New Model" },
    { _id: 9, path: "/allmodel", name: "All Model" },
  ];
  const ownerMenu = [
    { _id: 4, path: "/addowner", name: "New Owner" },
    { _id: 11, path: "/allowner", name: "All Owner" },
  ];
  const fuelMenu = [
    { _id: 15, path: "/addrunon", name: "New Fuel Type" },
    { _id: 16, path: "/allrunon", name: "All Fuel Type" },
  ];

  const locationMenu = [
    { _id: 4, path: "/addlocation", name: "New Location" },
    { _id: 5, path: "/alllocation", name: " List Of Location" },
  ];

  const EmployeeManagementMenu = [
    { _id: 1, path: "/employeereg", name: "New Employee" },
    { _id: 2, path: "/allemployee", name: "Employee List" },
  ];

  const designationMenu = [
    { _id: 1, path: "/adddesignation", name: "New Designation" },
    { _id: 1, path: "/alldesignation", name: "Designation List " },
  ];
  const driverMenu = [
    { _id: 1, path: "/adddrivercat", name: "New Driver Category" },
    { _id: 1, path: "/alldrivercat", name: "Driver Category List" },
  ];

  // all report menu
  const reportMenu = [
    { _id: 1, path: "/completedtripreport", name: "Completed Trip Report" },
    { _id: 2, path: "/rejectedtripreport", name: "Rejected Trip Report" },
    { _id: 2, path: "/officerwisereport", name: "OfficerWise Report" },
  ];

  const allBillMenu = [
    { _id: 1, path: "/officerwisebills", name: "Officer Wise Bill" },
  ];

  // available  === designation_id
  const refuellingMenu = [
    {
      _id: 1,
      available: ["999"],
      path: "/refuelling",
      name: "Refuelling Request",
    },
    {
      _id: 2,
      available: ["7","999"],
      path: "/requestedrefuel",
      name: "All Refuel Request",
    },
    {
      _id: 2,
      available: ["999","7"],
      path: "/approvedrefuel",
      name: "All Approved Request",
    },
    {
      _id: 2,
      available: ["7","999"],
      path: "/completedrefuel",
      name: "All Completed Request",
    },
    {
      _id: 2,
      available: ["7","999"],
      path: "/rejectedrefuel",
      name: "All Rejected Request",
    },
    {
      _id: 2,
      available: ["7","999"],
      path: "/overduerefuel",
      name: "All Overdue Request",
    },
  ];
  const petrolPumpMenu = [
    { _id: 1, path: "/addpetrolpump", name: "New Fuel Pump" },
    { _id: 2, path: "/allpetrolpump", name: "All Fuel Pump" },
  ];

  // trip Field

  // available == for staff category
  const tripMenu = [
    // { _id: 1, available: ["1"], path: "/carschedule", name: "Schedule A Trip" },
    {
      _id: 2,
      available: ["1"],
      path: "/carschedulereq",
      name: "Request A Trip",
    },
    // {
    //   _id: 3,
    //   available: ["1", "2"],
    //   path: "/allcarschedule",
    //   name: "All Schedule",
    // },
    {
      _id: 3,
      available: ["1"],
      path: "/requestedtrips",
      name: "All Requested Trips",
    },
    {
      _id: 4,
      available: ["1", "2"],
      path: "/approvedtrips",
      name: "All Approved Trips",
    },
    {
      _id: 5,
      available: ["1"],
      path: "/ongoingtrips",
      name: "All Ongoing Trips",
    },
    {
      _id: 5,
      available: ["1"],
      path: "/completedtrips",
      name: "All Completed Trips",
    },
    {
      _id: 6,
      available: ["1"],
      path: "/rejectedtrips",
      name: "All Rejected Trips",
    },
  ];

  const filterTripMenu = tripMenu.filter((menu) =>
    menu?.available.includes(user[0]?.data?.staff_cat_id)
  );

  const filterRefuelMenu = refuellingMenu.filter((menu) =>
    menu?.available.includes(user[0]?.data?.designation_id)
  );

  return (
    <div className="h-3/6 overflow-y-scroll" style={{ display: "flex", width: "100%" }}>
      <Sidebar
        backgroundColor="#429ebd"
        rootStyles={{ backgroundColor: "blue" }}
        breakPoint="sm"
      >
        <Menu closeOnClick={true}>
          <Link to={"/dashboard"}>
            <MenuItem>Dashboard</MenuItem>
          </Link>
          <SubMenu rootStyles={{ backgroundColor: "blue" }} label="Schedules">
            {user[0].data?.approval_level === "2" && (
              <>
                <Link to="/addschedulejob">
                  <MenuItem>Schedule Job</MenuItem>
                </Link>
                <Link to="/allschdulejob">
                  <MenuItem>All Schedule Job</MenuItem>
                </Link>
              </>
            )}
            {filterTripMenu.map((menu) => (
              <Link key={menu._id} to={menu.path}>
                <MenuItem>{menu.name}</MenuItem>
              </Link>
            ))}
          </SubMenu>
          {user[0].data?.approval_level !== "3" && (
            <SubMenu  label="Report">
              {reportMenu.map((menu) => (
                <Link key={menu._id} to={menu.path}>
                  <MenuItem>{menu.name}</MenuItem>
                </Link>
              ))}
            </SubMenu>
          )}
          {user[0].data?.designation_id === "14" && (
            <SubMenu label="Bills">
              {allBillMenu.map((menu) => (
                <Link key={menu._id} to={menu.path}>
                  <MenuItem>{menu.name}</MenuItem>
                </Link>
              ))}
            </SubMenu>
          )}

          {filterRefuelMenu.length !== 0 && (
            <SubMenu label="Refuelling">
              {filterRefuelMenu.map((menu) => (
                <Link key={menu._id} to={menu.path}>
                  <MenuItem>{menu.name}</MenuItem>
                </Link>
              ))}
            </SubMenu>
          )}
          {user[0].data?.approval_level !== "3" && (
            <SubMenu label="Utility">
              <SubMenu className="ml-[-20px]" label="Employee Management">
                {EmployeeManagementMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Designations">
                {designationMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Driver Category">
                {driverMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              {/* <SubMenu className="ml-[-20px]" label="Locations">
                {locationMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu> */}
              <SubMenu className="ml-[-20px]" label="Vehicles">
                {vehicleMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Vehicle Categories">
                {vehicleCatMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Brands">
                {brandsMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Models">
                {modelsMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Owners">
                {ownerMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Fuel">
                {fuelMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
              <SubMenu className="ml-[-20px]" label="Fuel Pump">
                {petrolPumpMenu.map((menu) => (
                  <Link key={menu._id} to={menu.path}>
                    <MenuItem className="pl-6 text-sm">{menu.name}</MenuItem>
                  </Link>
                ))}
              </SubMenu>
            </SubMenu>
          )}
        </Menu>
      </Sidebar>
      <main className="mx-auto container flex-1 overflow-y-auto">
        <button
          className="sb-button block lg:hidden"
          onClick={() => toggleSidebar()}
        >
          <i class="fa-solid fa-bars text-4xl"></i>
        </button>
        <div className="">{children}</div>
      </main>
    </div>
  );
};
