import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../features/user/userSlice";
import { usePostApiHeader, usePostApiResponse } from "../../helper/customHook";

const UpdateName = () => {

  // dispatch state 
  const dispatch = useDispatch()

  // post api 
  const [handlePostApiHeader] = usePostApiHeader()
  const [handlePostResponse] = usePostApiResponse()
  // all state
  // user state
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // error state

  const [submitFormDisable, setSubmitFormDisable] = useState(true);

  // ref
  const fullNameRef = useRef();
  const nidRef = useRef();
  const contactNoRef = useRef();

  // navigate
  const handleUpdateInfo = async () => {
    let full_name = fullNameRef?.current?.value || user[0]?.data?.full_name;
    let nid = nidRef?.current?.value || user[0]?.data?.mobile_no;
    let mobile_no = contactNoRef?.current?.value || user[0]?.data?.nid;
    let tempUser = user[0]

    const updatedUser = { ...tempUser,data:{...tempUser.data,mobile_no,nid,full_name}}

    const user_profile_id = user[0]?.data?.user_profile_id

    const updatedInfo = {
      user_profile_id,
      full_name,
      nid,
      mobile_no,
    };

    fetch("https://kdaback.nescostore.com/api/self-profile-update", await  handlePostApiHeader(updatedInfo))
        .then((res) => res.json())
        .then(async (data) => {
           
          dispatch(update(updatedUser))
          
          handlePostResponse(data,"/editprofile/updateinfo")
          
        })
        .catch((error) => {
          console.log("error:", error);
        });

    
    
  };

  return (
    <div>
      <h2 className="text-xl text-center my-4 font-semibold">Update Info</h2>
      <div className="mx-auto ">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Full Name</span>
            <input
              type="text"
              defaultValue={user[0]?.data?.full_name}
              className="input w-4/6 input-bordered"
              ref={fullNameRef}
            />
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Mobile Number</span>
            <input
              type="text"
              defaultValue={user[0]?.data?.mobile_no}
              className="input w-4/6 input-bordered"
              ref={contactNoRef}
            />
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">NID Number</span>
            <input
              type="text"
              defaultValue={user[0]?.data?.nid}
              className="input w-4/6 input-bordered"
              ref={nidRef}
            />
          </label>
        </div>
        <button
          className="btn btn-primary my-10 mx-auto lg:w-44"
          onClick={handleUpdateInfo}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateName;
