import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useGetApiHeader, useLogout, usePostApiHeader, usePostApiResponse } from "../../helper/customHook";

const Refuelling = () => {
  // user state
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();
  // get Header State
  const [getApiHeader] = useGetApiHeader();
  // logout state
  const [logout] = useLogout();
  //   data State
  const [vehicleOptions, setVehicleOptions] = useState([]);
  // loading State
  const [vehicleOptionLoading, setVehicleOptionsLoading] = useState(true);

  // all Error State
  const [mileError, setMileError] = useState("");
  const [fuelError, setFuelError] = useState("");
  // all Use useEffect
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-cars", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setVehicleOptions(data);
          setVehicleOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all ref
  const vehicleRef = useRef();
  const mileRef = useRef();
  const fuelRef = useRef();

  //   all function

  const handleMile = () => {
    let mile = mileRef.current.value;
    if (mile.length) {
      setMileError("");
      if (isNaN(mile)) {
        setMileError("Miles must be a number");
      } else {
        setMileError("");
      }
    } else {
      setMileError("This Field can not be Empty");
    }
  };
  const handleFuel = () => {
    let fuel = fuelRef.current.value;
    if (fuel.length) {
      setFuelError("");
      if (isNaN(fuel)) {
        setFuelError("Fuel Liter must be a number");
      } else {
        setFuelError("");
      }
    } else {
      setFuelError("This Field can not be Empty");
    }
  };

  const handleReFuelRequest = async() => {
    const vehicle_id = vehicleRef.current.value;
    const driver_id = user[0]?.data?.user_profile_id;
    const requested_at_reading = mileRef.current.value;
    const requested_fuel = fuelRef.current.value;

    const newRefuelRequest = {
      vehicle_id,
      driver_id,
      requested_at_reading,
      requested_fuel,
    }
    console.log(newRefuelRequest)


    fetch(
      "https://kdaback.nescostore.com/api/store-refuelling-request",
      await handlePostApiHeader(newRefuelRequest)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/approvedrefuel"))
      .catch((error) => {
        console.log("error:", error);
      });


  };

  return (
    <div>
      <h2 className="text-xl my-4 text-center font-semibold">
        Refuelling Entry
      </h2>
      <div className="mx-auto lg:w-1/2">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Vehicle No</span>
            <select className="select select-bordered w-4/6" ref={vehicleRef}>
              <option disabled selected>
                {" "}
                ------ Select Vehicle ------{" "}
              </option>
              {vehicleOptionLoading ? (
                <option disabled>Loading ....</option>
              ) : (
                vehicleOptions.map((vehicleOption) => (
                  <option value={vehicleOption.car_info_id}>
                    {vehicleOption.vehicle_no}{" "}
                  </option>
                ))
              )}
            </select>
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Meter Reading (KM) </span>
            <input
              type="text"
              placeholder="Meter Reading In KM"
              className="input w-4/6 input-bordered"
              ref={mileRef}
              onBlur={handleMile}
            />
          </label>
        </div>
        {mileError && (
          <div className="alert shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{mileError}</span>
            </div>
          </div>
        )}
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Fuel Needed (L) </span>
            <input
              type="text"
              placeholder="Fuel Loaded in Liter"
              className="input w-4/6 input-bordered"
              ref={fuelRef}
              onBlur={handleFuel}
            />
          </label>
        </div>
        {fuelError && (
          <div className="alert shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{fuelError}</span>
            </div>
          </div>
        )}

        <div className="w-48 mx-auto">
          <button
            className="btn btn-primary text-center w-full my-10 mx-auto"
            onClick={handleReFuelRequest}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Refuelling;
