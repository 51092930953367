import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    usePostApiResponse
} from "../../helper/customHook";

const CarScheduleRequest = () => {
  // all state
  // user State
  const { user, loading, error } = useSelector((state) => ({
    ...state.user,
  }));
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // logout state
  const [logout] = useLogout();
  // error state
  const [fromDateError, setFromDaterror] = useState("");
  const [toDateError, setToDaterror] = useState("");
  const [pickupError, setPickupError] = useState("");
  const [destinationError, setDestinationError] = useState("");

  // disable state and loading State
  const [toDateDisable, setToDateDisable] = useState(true);
  const [carDisable, setCarDisable] = useState(true);
  const [driverDisable, setDriverDisable] = useState(true);
  const [personalOptionDisable, setPersonalOptionDisable] = useState(true);

  // loading State

  const [driverOptionLoading, setDriverOptionLoading] = useState([]);
  const [carOptionLoading, setCarOptionLoading] = useState([]);

  // field visable
  const [juridictionField, setJuridictionField] = useState(false);

  // array value state

  //  option State
  const [driverOptions, setDriverOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);

  // All use Ref
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const pickupRef = useRef();
  const destinationRef = useRef();
  const purposeRef = useRef();

  const carRef = useRef();
  const driverRef = useRef();
  const apprDistRef = useRef();
  const juriDecRef = useRef();
  const descriptionRef = useRef();

  // all useEffect

  // all Function

  const handleFromDate = () => {
    const fromDate = fromDateRef.current.value;
    const dateTime = new Date(fromDate);
    const nowTime = Date.now();

    setPersonalOptionDisable(true);

    toDateRef.current.value = "";
    purposeRef.current.value = "1";

    if (nowTime > dateTime.getTime()) {
      setFromDaterror("From date must be later than today");
      setToDateDisable(true);
      toDateRef.current.value = "";
    } else {
      setFromDaterror("");
      setToDateDisable(false);
      console.log("weakDay", dateTime.getDay(), "hours", dateTime.getHours());
      // console.log(dateTime.getHours()<=23 && dateTime.getHours()>=18)
      // console.log(dateTime.getDay()!==6 && dateTime.getDay()!==5)

      // check office Day or Not
      if (dateTime.getDay() < 5) {
        if (dateTime.getHours() <= 23 && dateTime.getHours() >= 18) {
          setPersonalOptionDisable(false);
        }
      } else {
        setPersonalOptionDisable(false);
      }
    }
  };
  const handleToDate = () => {
    const fromDate = fromDateRef.current.value;
    const toDate = toDateRef.current.value;
    const fromDateTime = new Date(fromDate);
    const toDateTime = new Date(toDate);
    setCarDisable(true);
    setDriverDisable(true);
    if (fromDateTime.getTime() > toDateTime.getTime()) {
      setToDaterror("To date must be later than from date!");
    } else {
      setToDaterror("");
      if (toDate.split("T")[1] && fromDate.split("T")[1]) {
        // get free car
        setCarDisable(false);
        setCarOptionLoading(true);
        setDriverDisable(false);
        setDriverOptionLoading(true);
        
        fetch(
          `https://kdaback.nescostore.com/api/check-avl-vhcl/${fromDate.toString()}/${toDate.toString()}`,
          getApiHeader
        )
          .then((res) => res.json())
          .then((data) => {
            if (!data?.message) {
               ;
              setCarOptions(data);
              setCarOptionLoading(false);
            } else {
              logout();
            }
          });
        // get free Driver
        fetch(
          `https://kdaback.nescostore.com/api/check-avl-drvr/${fromDate.toString()}/${toDate.toString()}`,
          getApiHeader
        )
          .then((res) => res.json())
          .then((data) => {
            if (!data?.message) {
              setDriverOptions(data);
              setDriverOptionLoading(false);
            } else {
              logout();
            }
          });
      }
    }
  };
  const handlePickup = () => {
    const pickup = pickupRef.current.value;
    if (pickup.length) {
      setPickupError("");
    } else {
      setPickupError("This Field cannot be empty");
    }
  };
  const handleDestination = () => {
    const destination = destinationRef.current.value;
    if (destination.length) {
      setDestinationError("");
    } else {
      setDestinationError("This Field cannot be empty");
    }
  };
  const handlePurpose = () => {
    const purpose = purposeRef.current.value;
    
    if (purpose === "2") {
      setJuridictionField(true);
    } else {
      setJuridictionField(false);
    }
  };

  const handleAddSchedule = async () => {
    const destinition_location_id = destinationRef.current.value;
    const created_by = user[0]?.data?.id.toString();
    const from_date = fromDateRef.current.value;
    const to_date = toDateRef.current.value;
    const officer_id = user[0]?.data?.id.toString();
    const car_info_id = carRef.current.value;
    const car_driver_id = driverRef.current.value;
    const pick_location_id = pickupRef.current.value;
    const trip_purpose_id = purposeRef.current.value;
    const tentative_travel_distance = apprDistRef.current.value;
    const description = descriptionRef.current.value;

    let newSchedule = {
      from_date,
      to_date,
      officer_id,
      car_info_id,
      car_driver_id,
      pick_location_id,
      destinition_location_id,
      tentative_travel_distance,
      created_by,
      description,
      trip_purpose_id,
    };

    if (trip_purpose_id === "2") {
      const trip_jurisdiction_id = juriDecRef.current.value;
      newSchedule = { ...newSchedule, ...{ trip_jurisdiction_id } };
    }

    
    fetch(
      "https://kdaback.nescostore.com/api/store-trip-schedule",
      await handlePostApiHeader(newSchedule)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/requestedtrips"))
      .catch((error) => {
        console.log("error:", error);
      });
  };

  return (
    <div className="min-h-screen bg-base-100">
      <div>
        <h3 className="text-xl text-center font-semibold my-5 ">
          Request For Trip{" "}
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
          <div className="">
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">From Date and Time</span>
                <input
                  type="datetime-local"
                  className="input w-4/6 input-bordered"
                  ref={fromDateRef}
                  onBlur={handleFromDate}
                />
              </label>
            </div>
            {fromDateError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{fromDateError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">To Date and Time</span>
                <input
                  type="datetime-local"
                  className="input w-4/6 input-bordered"
                  ref={toDateRef}
                  onBlur={handleToDate}
                  disabled={toDateDisable}
                />
              </label>
            </div>
            {toDateError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{toDateError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Vehicle</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={carRef}
                  disabled={carDisable}
                >
                  <option disabled selected>
                    {" "}
                    ------ Select Vehicle ------{" "}
                  </option>
                  {carOptionLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    carOptions.map((car) => (
                      <option value={car.car_info_id}>
                        {" "}
                        {car.vehicle_no}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            <div className="form-control">
              <label className="input-group">
                <span className="w-2/6">Driver</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={driverRef}
                  disabled={driverDisable}
                >
                  <option disabled selected>
                    {" "}
                    ------ Select Driver ------{" "}
                  </option>
                  {driverOptionLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    driverOptions.map((driver) => (
                      <option value={driver.user_id}>
                        {" "}
                        {driver.full_name}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
          </div>
          <div className="">
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Pickup Point</span>
                <input
                  type="text"
                  className="input w-4/6 input-bordered"
                  ref={pickupRef}
                  onBlur={handlePickup}
                />
              </label>
            </div>
            {pickupError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{pickupError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Destination</span>

                <input
                  type="text"
                  className="input w-4/6 input-bordered"
                  ref={destinationRef}
                  onBlur={handleDestination}
                />
              </label>
            </div>
            {destinationError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{destinationError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Approximate Distance</span>
                <input
                  type="number"
                  className="input w-4/6 input-bordered"
                  placeholder="Approximate Travel Distance in KM"
                  ref={apprDistRef}
                />
              </label>
            </div>
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Purpose</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={purposeRef}
                  onChange={handlePurpose}
                >
                  <option value="1">Official</option>
                  <option value="2" disabled={personalOptionDisable}>
                    Personal
                  </option>
                </select>
              </label>
            </div>
            {juridictionField && (
              <>
                <div className="form-control my-3">
                  <label className="input-group">
                    <span className="w-2/6">Jurisdiction</span>
                    <select
                      className="select select-bordered w-4/6"
                      ref={juriDecRef}
                    >
                      <option value="1">Inside Of KDA Jurisdiction</option>
                      <option value="2">Outside Of KDA Jurisdiction</option>
                    </select>
                  </label>
                </div>
              </>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Description</span>
                <textarea
                  style={{ resize: "none" }}
                  type="text"
                  placeholder="Description "
                  ref={descriptionRef}
                  className="input w-4/6 input-bordered h-28"
                ></textarea>
              </label>
            </div>
          </div>
        </div>
        <div className="w-48 mx-auto">
          <button
            onClick={handleAddSchedule}
            className="btn btn-primary my-10 mx-auto lg:w-44"
          >
            ADD Schedule
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarScheduleRequest;
