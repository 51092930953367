import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetApiHeader, useLogout, usePostApiHeader, useUpdateApiResponse } from "../../helper/customHook";

const AllPetrolPump = () => {
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // update api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  const [logout] = useLogout();

  // all State

  const [petrolPumps, setPetrolPumps] = useState([]);
  const [selectedPetrolPump, setSelectedPetrolPump] = useState({});
  const [petrolPumpsLoading, setPetrolPumpsLoading] = useState(true);
  const [modalField, setModalField] = useState(false);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-petrol-pump", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setPetrolPumps(data);
          setPetrolPumpsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all Ref
  const nameRef = useRef();
  const addressRef = useRef();
  const contactNoRef = useRef();
  const activityRef = useRef();

  // all function
  const handleSelectedPetrolPump = (id) => {
    setModalField(true);
    setSelectedPetrolPump({});
    fetch(
      `https://kdaback.nescostore.com/api/edit-petrol-pump/${id}`,
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        setSelectedPetrolPump(data);
      });
  };

  const handlePetrolPumpEdit = async (pump_id) => {
    const pump_name =
      nameRef.current.value || selectedPetrolPump.pump_name;
    const pump_address =
      addressRef.current.value ||
      selectedPetrolPump.pump_address;
    const contact_no = contactNoRef.current.value || selectedPetrolPump.approval_level;
    const is_active = activityRef.current.value;

    const updatedPump= {
      pump_name,pump_id,pump_address,contact_no,is_active
    };
    

    fetch(
      "https://kdaback.nescostore.com/api/update-petrol-pump",
      await handlePostApiHeader(updatedPump)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allpetrolpump");
        let updatedPumps = [];
        if (flag) {
          petrolPumps.map((p) => {
            if (p.pump_id === pump_id) {
              updatedPumps.push(updatedPump);
            } else {
              updatedPumps.push(p);
            }
          });

          setPetrolPumps(updatedPumps);
          setModalField(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.pump_name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.pump_address,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row.contact_no,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <label className=" btn-link mx-1" for="edit-brand">
          <i
            title="Edit"
            onClick={() => handleSelectedPetrolPump(row.pump_id)}
            class="fa-regular fa-pen-to-square"
          ></i>
        </label>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">
        {" "}
        All Pump{" "}
      </h2>
      <DataTable
        pagination
        columns={columns}
        data={petrolPumps}
        progressPending={petrolPumpsLoading}
        selectableRows
      />
      {modalField && (
        <div>
          <input type="checkbox" id="edit-brand" class="modal-toggle" />
          <label for="car-schedule-details" class="modal cursor-pointer">
            <label
              class="modal-box relative w-3/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-brand"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Pump Info
                    </h2>
                    <div className="mx-auto lg:w-3/4">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Name</span>
                          <input
                            type="text"
                            ref={nameRef}
                            key={selectedPetrolPump.pump_name}
                            defaultValue={selectedPetrolPump.pump_name}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Address</span>
                          <input
                            type="text"
                            ref={addressRef}
                            key={selectedPetrolPump.pump_address}
                            defaultValue={selectedPetrolPump.pump_address}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Contact No</span>
                          <input
                            type="text"
                            ref={contactNoRef}
                            key={selectedPetrolPump.contact_no}
                            defaultValue={selectedPetrolPump.contact_no}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>

                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Activity</span>
                          <select
                            key={selectedPetrolPump.is_active}
                            defaultValue={selectedPetrolPump.is_active}
                            className="select select-bordered w-4/6"
                            ref={activityRef}
                          >
                            <option value="1">Active</option>

                            <option value="0">Not Active</option>
                          </select>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary my-10 mx-auto lg:w-full"
                        onClick={() =>
                          handlePetrolPumpEdit(
                            selectedPetrolPump.pump_id
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
    </div>
  );
};

export default AllPetrolPump;
