import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetApiHeader, useLogout, usePostApiHeader, useUpdateApiResponse } from "../../helper/customHook";

const AllRunOn = () => {
  // get Header State
  const [getApiHeader] = useGetApiHeader();

    // update api
    const [handlePostApiHeader] = usePostApiHeader();
    const [handleUpdateResponse] = useUpdateApiResponse();

  const [logout] = useLogout();

  // all State
  

  const [carRunOns, setCarRunOns] = useState([]);
  const [runOnLoading, setRunOnLoading] = useState(true);
  const [selectedRunOn, setSelectedRunOn] = useState({});
  // field State
  const [modalField, setModalField] = useState(false);

  // all Ref

  const runOnRef = useRef();
  const activityRef = useRef();

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-run-on", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setCarRunOns(data);
          setRunOnLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  const handleSelectedRunOn = (car_run_on_id) => {
    setModalField(true)
    setSelectedRunOn({})
    fetch(`https://kdaback.nescostore.com/api/edit-car-run-on/${car_run_on_id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedRunOn(data)
      });
  };
  const handleEditRunOn = async (car_run_on_id) => {
    const car_run_on_name =
      runOnRef.current.value || selectedRunOn.car_run_on_name;
    const is_active = activityRef.current.value;

    const updatedRunOn = { car_run_on_id, car_run_on_name, is_active };

    fetch(
      "https://kdaback.nescostore.com/api/update-car-run-on",
      await handlePostApiHeader(updatedRunOn)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allrunon");
        let updatedRunOns = [];
        if (flag) {
          carRunOns.map((r) => {
            if (r.car_run_on_id === car_run_on_id) {
              updatedRunOns.push(updatedRunOn);
            } else {
              updatedRunOns.push(r);
            }
          });

          setCarRunOns(updatedRunOns);
          setModalField(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.car_run_on_name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <label className=" btn-link mx-1" for="edit-run-on">
            <i
              title="Edit"
              onClick={() => handleSelectedRunOn(row.car_run_on_id)}
              class="fa-regular fa-pen-to-square"
            ></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold"> All Fuel </h2>
      <DataTable
        pagination
        columns={columns}
        data={carRunOns}
        progressPending={runOnLoading}
        selectableRows
      />
      {modalField&&<div>
        <input type="checkbox" id="edit-run-on" class="modal-toggle" />
        <label for="car-schedule-details" class="modal cursor-pointer">
          <label class="modal-box relative w-3/6 max-w-5xl text-justify" for="">
            <label
              htmlFor="edit-run-on"
              className="btn btn-sm btn-circle absolute right-2 top-4"
            >
              ✕
            </label>
            <div className="py-4">
              <div className="overflow-x-auto">
                <div>
                  <h2 className="text-xl my-4 text-center font-semibold">
                    Edit Fuel
                  </h2>
                  <div className="mx-auto lg:w-1/2">
                    <div className="form-control my-3">
                      <label className="input-group">
                        <span className="w-2/6">Run On</span>
                        <input
                          type="text"
                          ref={runOnRef}
                          key={selectedRunOn?.car_run_on_name}
                          defaultValue={selectedRunOn?.car_run_on_name}
                          className="input w-4/6 input-bordered"
                        />
                      </label>
                    </div>

                    <div className="form-control my-3">
                      <label className="input-group">
                        <span className="w-2/6">Activity</span>
                        <select
                          className="select select-bordered w-4/6"
                          ref={activityRef}
                          key={selectedRunOn?.is_active}
                          defaultValue={selectedRunOn.is_active}
                        >
                          <option value="1">Active</option>

                          <option value="0">Not Active</option>
                        </select>
                      </label>
                    </div>
                    <button
                      onClick={() => handleEditRunOn(selectedRunOn.car_run_on_id)}
                      className="btn btn-primary my-10 mx-auto lg:w-full"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </label>
      </div>}
    </div>
  );
};

export default AllRunOn;
