import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useGetApiHeader } from '../../helper/customHook';

const RejectedTripReport = () => {
    const [rejectedTripReportInfos, setRejectedTripReportInfos] = useState([]);
    // get Header State
  const [getApiHeader] = useGetApiHeader();
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/rejected-trip-report",getApiHeader)
      .then((res) => res.json())
      .then((data) => setRejectedTripReportInfos(data));
  }, []);

  const handlePurpose = (purpose_id, juri_id) => {
    if (purpose_id === "1") {
      return <p>Official</p>;  
    } else {
      if (juri_id === "1")
        return (
          <>
            <p>Personal </p> <p> (Inside KDA jurisdiction)</p>
          </>
        );
      else {
        return (
          <>
            <p>Personal </p> <p> (Outside KDA jurisdiction)</p>
          </>
        );
      }
    }
  };
  if (rejectedTripReportInfos) {
    return (
        <div className="">
        <h1 className="text-4xl my-3 text-center font-bold">
          Khulna Development Authority{" "}
        </h1>
        <h1 className="text-2xl my-3 text-center font-bold">
          Rejected Trip Report
        </h1>
        <h1 className="text-2xl my-3 text-center font-bold">Khulna </h1>
        <h1 className="text-xl my-3 text-center font-bold">
        </h1>
  
        <div className="overflow-x-auto mt-12 pb-12">
          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>SL No</th>
                <th>From Time </th>
                <th>To Time</th>
                <th>Car N0</th>
                <th>Officer Name</th>
                <th>Driver Name</th>
                <th>Pickup </th>
                <th>Destination </th>
                <th>Purpose </th>
                <th>Description </th>
                <th>Status </th>
              </tr>
            </thead>
            <tbody>
              {rejectedTripReportInfos.map((trip, index) => (
                <tr>
                  <th>{index + 1}</th>
                  <td>{moment(trip?.from_date).format("DD/MM/YYYY [\n] h:mm A")}</td>
                  <td>{moment(trip?.to_date).format("DD/MM/YYYY [\n] h:mm A")}</td>
                  <td>{trip?.vehicle_no}</td>
                  <td>{trip?.officer_name}</td>
                  <td>{trip?.driver_name}</td>
                  <td>{trip?.pick_location_id}</td>
                  <td>{trip?.destinition_location_id}</td>
                  <td>{handlePurpose(trip.trip_purpose_id, trip.trip_jurisdiction_id)}</td>
                  <td>{trip.description}</td>
                  <td>{trip.trip_status_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default RejectedTripReport;