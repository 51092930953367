import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useGetApiHeader } from "../../helper/customHook";
import TripReport from "./TripReport";

const CompletedTripReport = () => {
  const [completedTripReportInfos, setCompletedTripReportInfos] = useState([]);

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/completed-trip-report",getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        
        setCompletedTripReportInfos(data)});
  }, []);
  if (completedTripReportInfos.length) {
    return (
      <TripReport
       

        tripReportInfos={completedTripReportInfos}
        pageTitle="Completed Trip Report"
      />
    );
  }
};

export default CompletedTripReport;
