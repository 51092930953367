import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getUser = createAsyncThunk("user/getUser", async (credentials) => {
  return fetch("https://kdaback.nescostore.com/api/user-login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((res) => res.json());
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset: (state) => {
      state.user = [];
      state.loading = false;
      state.error = null;
    },
    update: (state,action) => {
      state.user = [action.payload];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status) {
        state.user = [action.payload];
        state.error = null;
      } else {
        state.error = [action?.payload?.message];
        state.user = [];
      }
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
    },
  },
});
export const { reset,update } = userSlice.actions;

export default userSlice.reducer;
