import moment from "moment";
import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import Login from "../Login/Login";
import AllBrand from "../Car/AllBrand";


const print = () => {
  const string = renderToString(<AllBrand/>);
  const pdf = new jsPDF("p", "mm", "a4");
  

  pdf.html(string);
  pdf.save("hello.pdf");
};

const TripReport = ({tripReportInfos,pageTitle}) => {
  

  const handlePurpose = (purpose_id, juri_id) => {
    if (purpose_id === "1") {
      return <p>Official</p>;
    } else {
      if (juri_id === "1")
        return (
          <>
            <p>Personal </p> <p> (Inside KDA jurisdiction)</p>
          </>
        );
      else {
        return (
          <>
            <p>Personal </p> <p> (Outside KDA jurisdiction)</p>
          </>
        );
      }
    }
  };

  const totalDistance = () => {
    let distance = 0;
    tripReportInfos.map((trip) => {
      distance = distance + (trip.end_milage - trip.start_milage);
    });
    return distance;
  };

  
  

  return (
    <div className="">
      {/* <button className="btn" onClick={print}>Print</button> */}
      <h1 className="text-4xl my-3 text-center font-bold">
        Khulna Development Authority{" "}
      </h1>
      <h1 className="text-2xl my-3 text-center font-bold">
        Shibbari , Khulna - 9000
      </h1>
      
      <h1 className="text-xl my-3 text-center font-bold">
        {pageTitle}
      </h1>

      <div className="overflow-x-auto mt-12 pb-12">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>SL No</th>
              <th>From Time </th>
              <th>To Time</th>
              <th>Car No</th>
              <th>Officer Name</th>
              <th>Driver Name</th>
              <th>Pickup </th>
              <th>Destination </th>
              <th>Start Millage </th>
              <th>End Millage</th>
              <th>Travel Distance </th>
              <th>Purpose </th>
              <th>Description </th>
            </tr>
          </thead>
          <tbody>
            {tripReportInfos.map((trip, index) => (
              <tr>
                <th>{index + 1}</th>
                <td>{moment(trip?.from_date).format("DD/MM/YYYY, h:mm A")}</td>
                <td>{moment(trip?.to_date).format("DD/MM/YYYY, h:mm A")}</td>
                <td>{trip?.vehicle_no}</td>
                <td>{trip?.officer_name}</td>
                <td>{trip?.driver_name}</td>
                <td>{trip?.pick_location_id}</td>
                <td>{trip?.destinition_location_id}</td>
                <td>{trip?.start_milage} KM</td>
                <td>{trip?.end_milage} KM</td>
                <td>
                  {parseInt(trip?.end_milage) - parseInt(trip?.start_milage)} KM
                </td>
                <td>{handlePurpose(trip.trip_purpose_id, trip.trip_jurisdiction_id)}</td>
                <td>{trip.description}</td>
              </tr>
            ))}

            <tr className="font-bold text-lg">
              <th></th>
              <td></td>
              <td>Total Trip Count</td>
              <td>{tripReportInfos?.length}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total Distance</td>
              <td>{totalDistance()} Km</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TripReport;
