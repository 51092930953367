import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import { useGetApiHeader } from "../../helper/customHook";

const Dashboard = () => {
  const [completedTripReportInfos, setCompletedTripReportInfos] = useState([]);

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  useEffect(() => {
    fetch(
      "https://kdaback.nescostore.com/api/completed-trip-report",
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        setCompletedTripReportInfos(data);
      });
  }, []);

  const arr = [1, 2, 3, 4];
  return (
    <div className="grid lg:grid-cols-2 gap-4 grid-cols-1">
      
        <div className="card lg:card-side bg-base-100 shadow-xl">
          <div className="card-body">
          
        <LineChart
          width={400}
          height={300}
          data={completedTripReportInfos}
          
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="officer_name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="distance" stroke="#8884d8" activeDot={{ r: 8 }} />
          
        </LineChart>
          </div>
        </div>
        {/* driver line  */}
        <div className="card lg:card-side bg-base-100 shadow-xl">
          <div className="card-body">
          
        <LineChart
          width={400}
          height={300}
          data={completedTripReportInfos}
          
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="driver_name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="distance" stroke="#8884d8" activeDot={{ r: 8 }} />
          
        </LineChart>
          </div>
        </div>
        {/* driver bar  */}
        <div className="card lg:card-side bg-base-100 shadow-xl">
          <div className="card-body">
          
          <BarChart width={400} height={300} data={completedTripReportInfos}>
          <Bar dataKey="distance" fill="#8884d8" />
          <XAxis dataKey="driver_name" />
          <YAxis />
          <Tooltip/>
        </BarChart>
          </div>
        </div>
        {/* vehicle bar  */}
        <div className="card lg:card-side bg-base-100 shadow-xl">
          <div className="card-body">
          
          <BarChart width={400} height={300} data={completedTripReportInfos}>
          <Bar dataKey="distance" fill="#8884d8" />
          <XAxis dataKey="vehicle_no" />
          <YAxis />
          <Tooltip/>
        </BarChart>
          </div>
        </div>
     
    </div>
  );
};

export default Dashboard;
