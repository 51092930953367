import React, { useRef, useState } from "react";
import { usePostApiHeader, usePostApiResponse } from "../../helper/customHook";

const AddRunOn = () => {
  // all state
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // all error State
  const [runOnError, setRunOnError] = useState("");

  // All use Ref
  const runOnRef = useRef();
  const activityRef = useRef();

  // all Function

  const handleRunOn = () => {
    const milesReading = runOnRef.current.value;
    if (milesReading.length) {
      setRunOnError("");
    } else {
      setRunOnError("This Field can not be Empty");
    }
  };
  const handleAddRunOn = async () => {
    const car_run_on_name = runOnRef.current.value;
    const is_active = activityRef.current.value;
    const newRunOn = { car_run_on_name, is_active };
    fetch(
      "https://kdaback.nescostore.com/api/store-car-run-on",
      await handlePostApiHeader(newRunOn)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/allrunon"))
      .catch((error) => {
        console.log("error:", error);
      });
  };

  return (
    <div>
      <h3 className="text-xl text-center font-semibold my-5">New Fuel </h3>
      <div className="w-2/4 mx-auto">
        <div className="mx-auto my-4">
          <div class="form-control my-4">
            <label className="input-group">
              <span className="w-2/6">Fuel</span>
              <input
                type="text"
                placeholder="Vehicle Fuel Name"
                class="input input-bordered w-4/6"
                ref={runOnRef}
                onBlur={handleRunOn}
              />
            </label>
          </div>
          {runOnError && (
            <div className="alert shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <span>{runOnError}</span>
              </div>
            </div>
          )}
          <div className="form-control my-3">
            <label className="input-group">
              <span className="w-2/6">Activity</span>
              <select
                className="select select-bordered w-4/6"
                ref={activityRef}
              >
                <option value="1">Active</option>

                <option value="0">Not Active</option>
              </select>
            </label>
          </div>
          <div class="form-control my-4">
            <label className="input-group">
              <button onClick={handleAddRunOn} className="btn w-32 mx-auto">
                ADD
              </button>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRunOn;
