import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useGetApiHeader } from "../../helper/customHook";
import TripReport from "./TripReport";

const OfficerWiseReport = () => {
  // get Header State
  const [getApiHeader] = useGetApiHeader();
  // data state
  const [tripReportInfos, setTripReportInfos] = useState([]);

  const [pageTitle, setPageTitle] = useState("");

  // all option state
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [officerOptions, setOfficerOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);

  //   all Loading State

  const [vehicleOptionLoading, setVehicleOptinLoading] = useState(true);
  const [officerOptionLoading, setOfficerOptionLoading] = useState(true);
  const [driverOptionLoading, setDriverOptionLoading] = useState(true);

  //   all ref
  const vehicleRef = useRef();
  const officerRef = useRef();
  const driverRef = useRef();

  //   all use Effect

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-cars", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setVehicleOptions(data);
        setVehicleOptinLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setOfficerOptions(data);
        setOfficerOptionLoading(false);
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-drivers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setDriverOptions(data);
        setDriverOptionLoading(false);
      });
  }, []);
  //   all function

  const handleSelectedCar = async () => {
    const car_info_id = vehicleRef.current.value;
    officerRef.current.value = "0"
    driverRef.current.value = "0"

    fetch(
      `https://kdaback.nescostore.com/api/cw-completed-trip-report/${car_info_id}`,
      await getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        setTripReportInfos(data);
        setPageTitle("Car Wise Trip Report");
        if (data.length === 0) {
          toast.error("No trip done by this Car");
        }
      });
  };
  const handleSelectedOfficer = async () => {
    const user_profile_id = officerRef.current.value;
     vehicleRef.current.value = "0"
     driverRef.current.value = "0"

    fetch(
      `https://kdaback.nescostore.com/api/ow-completed-trip-report/${user_profile_id}`,
      await getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
         ;
        setTripReportInfos(data);
        setPageTitle("Officer Wise Trip Report");
        if (data.length === 0) {
          toast.error("No trip done this Officer");
        }
      });
  };
  const handleSelectedDriver = async () => {
    const user_profile_id = driverRef.current.value;
     vehicleRef.current.value = "0"
     officerRef.current.value = "0"

    fetch(
      `https://kdaback.nescostore.com/api/dw-completed-trip-report/${user_profile_id}`,
      await getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
         ;
        setTripReportInfos(data);
        setPageTitle("Driver Wise Trip Report");
        if (data.length === 0) {
          toast.error("No trip done this driver");
        }
      });
  };

  return (
    <div className="mt-20">
      <table className="w-full table mb-12">
        <thead>
          <tr>
            <th>
              <select
                className="select"
                ref={officerRef}
                onChange={handleSelectedOfficer}
              >
                <option disabled selected value="0">
                  {" "}
                  ------ Select Officer ------{" "}
                </option>
                {officerOptionLoading ? (
                  <option disabled>Loading ....</option>
                ) : (
                  officerOptions.map((officerOption) => (
                    <option value={officerOption.user_profile_id}>
                      {" "}
                      {officerOption.full_name}{" "}
                    </option>
                  ))
                )}
              </select>
            </th>
            <th>
              <select
                className="select"
                ref={driverRef}
                onChange={handleSelectedDriver}
              >
                <option disabled selected value="0">
                  {" "}
                  ------ Select Driver ------{" "}
                </option>
                {driverOptionLoading ? (
                  <option disabled>Loading ....</option>
                ) : (
                  driverOptions.map((driverOption) => (
                    <option value={driverOption.user_profile_id}>
                      {" "}
                      {driverOption.full_name}{" "}
                    </option>
                  ))
                )}
              </select>
            </th>
            <th>
              <select
                className="select"
                ref={vehicleRef}
                onChange={handleSelectedCar}
              >
                <option disabled selected value="0">
                  {" "}
                  ------ Select Vehicle ------{" "}
                </option>
                {vehicleOptionLoading ? (
                  <option disabled>Loading ....</option>
                ) : (
                  vehicleOptions.map((vehicleOption) => (
                    <option value={vehicleOption.car_info_id}>
                      {" "}
                      {vehicleOption.vehicle_no}{" "}
                    </option>
                  ))
                )}
              </select>
            </th>
          </tr>
        </thead>
      </table>
      {tripReportInfos.length !== 0 && (
        <TripReport tripReportInfos={tripReportInfos} pageTitle={pageTitle} />
      )}
    </div>
  );
};

export default OfficerWiseReport;
