import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

const AllEmployee = () => {
  // all State

  const { user, error } = useSelector((state) => ({
    ...state.user,
  }));
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  // error State
  const [nameError, setNameError] = useState("");
  const [employeeIdError, setEmployeeIdError] = useState("");
  const [joiningDateError, setJoiningDateError] = useState("");
  const [drivingLicenseError, setDrivingLicenseError] = useState("");
  const [alternateContactNoError, setAlternateContactNoError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [nidNoError, setNidNoError] = useState("");

  // field State and
  const [driverField, setDriverField] = useState(false);
  const [submitFormDisable, setSubmitFormDisable] = useState(false);
  const [dedicatedField, setDedicatedField] = useState(false);
  const [modalField, setModalField] = useState(false);

  // data state
  const [designationOptions, setDesignationOptions] = useState([]);
  const [driverCategoryOptions, setDriverCategoryOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [officerOptions, setOfficerOptions] = useState([]);

  // loading state
  const [driverCategoryLoading, setDriverCategoryLoading] = useState(true);
  const [designationLoading, setDesignationLoading] = useState(true);
  const [officerOptionsLoading, setOfficerOptionsLoading] = useState(true);
  const [purposeOptionsLoading, setPurposeOptionsLoading] = useState(true);

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // logout state
  const [logout] = useLogout();

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [employeeLoading, setEmployeeLoading] = useState(true);
  // all useEffect

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-designations", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDesignationOptions(data);
          setDesignationLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch(
      "https://kdaback.nescostore.com/api/all-driver-categories",
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDriverCategoryOptions(data);
          setDriverCategoryLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-purposes", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setPurposeOptions(data);
          setPurposeOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-users-selected", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setEmployees(data);
          setEmployeeLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setOfficerOptions(data);
          setOfficerOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // All use Ref
  const nameRef = useRef();
  const employeeIdRef = useRef();
  const joiningDateRef = useRef();
  const staffCategoryRef = useRef();
  const drivingLicenseRef = useRef();
  const contactNoRef = useRef();
  const alternateContactNoRef = useRef();
  const designationRef = useRef();
  const nidNoRef = useRef();
  const drivingLicenseCategoryRef = useRef();
  const statusRef = useRef();
  const availablityRef = useRef();
  const purposeRef = useRef();
  const dedicatedToRef = useRef();

  // all Function

  const handleName = () => {
    const name = nameRef.current.value?.trim();
    if (name.length) {
      if (/^[a-zA-Z .]*$/.test(name)) {
        setNameError("");
        setSubmitFormDisable(false);
      } else {
        setNameError("Invalid Charecter In This field");
        setSubmitFormDisable(false);
      }
    }
  };
  const handleEmployeeId = () => {
    const employeeId = employeeIdRef?.current?.value?.trim();
    if (employeeId.length) {
      if (/^[a-zA-Z0-9-]*$/.test(employeeId)) {
        setEmployeeIdError("");
        setSubmitFormDisable(false);
      } else {
        setEmployeeIdError("Invalid Charecter In This field");
        setSubmitFormDisable(false);
      }
    }
  };

  const handleJoiningDate = () => {
    const joiningDate = joiningDateRef.current.value;
    const joiningDateTime = new Date(joiningDate);
    const nowTime = Date.now();

    if (nowTime < joiningDateTime.getTime()) {
      setJoiningDateError("joining Date must be Earlyer than today");
    } else {
      setJoiningDateError("");
    }
  };
  const handleDrivingLicense = () => {
    const drivingLicense = drivingLicenseRef?.current?.value?.trim();

    if (/^[a-zA-Z0-9]*$/.test(drivingLicense)) {
      setDrivingLicenseError("");
      setSubmitFormDisable(false);
    } else {
      setDrivingLicenseError("Invalid Charecter In This field");
      setSubmitFormDisable(true);
    }
  };

  const handleStaffCategory = () => {
    const staffCategory = staffCategoryRef.current.value;
    if (staffCategory === "2") {
      setDriverField(true);
    } else {
      setDriverField(false);
      setDrivingLicenseError("");
    }
  };
  const handleContactNo = () => {
    const contactNo = contactNoRef.current.value?.trim();
    if (contactNo.length) {
      if (/^[\d]{11}$/.test(contactNo)) {
        setContactNoError("");
        setSubmitFormDisable(false);
      } else {
        setContactNoError(
          "Invalid Charecter or your contact number not 11 digit"
        );
        setSubmitFormDisable(true);
      }
    }
  };
  const handleAlternateContactNo = () => {
    const alternateContactNo = alternateContactNoRef.current.value?.trim();
    if (alternateContactNo.length) {
      if (/^[\d]{11}$/.test(alternateContactNo)) {
        setAlternateContactNoError("");
        setSubmitFormDisable(false);
      } else {
        setAlternateContactNoError(
          "Invalid Charecter or your contact number not 11 digit"
        );
        setSubmitFormDisable(true);
      }
    }
  };
  const handleNidNo = () => {
    const nidNo = nidNoRef.current.value?.trim();
    if (nidNo.length) {
      if (/^[\d]{10,12}$/.test(nidNo)) {
        setNidNoError("");
        setSubmitFormDisable(false);
      } else {
        setNidNoError(
          "Invalid Charecter or your NID number not 10 or 13 digit"
        );
        setSubmitFormDisable(true);
      }
    }
  };
  const handlePurpose = () => {
    let purpose = purposeRef.current.value;
    const dedicatedPurpuse = purposeOptions.filter(
      (purposeOption) => purposeOption.car_purpose_description === "Dedicated"
    );
    if (dedicatedPurpuse.length) {
      if (purpose === dedicatedPurpuse[0].car_purpose_id) {
        setDedicatedField(true);
      } else {
        setDedicatedField(false);
      }
    }
  };

  const handleSelectedEmployee = (id) => {
    setModalField(true);
    setSelectedEmployee({});
    fetch(`https://kdaback.nescostore.com/api/edit-users/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedEmployee(data);
        if (data?.staff_cat_id === "2") {
          setDriverField(true);
          if (data?.purpose_id === "2") {
            setDedicatedField(true);
          } else {
            setDedicatedField(false);
          }
        } else {
          setDriverField(false);
          setDrivingLicenseError("");
        }
      });
  };

  const handleEmployeeEdit = async (event, id) => {
    event.preventDefault();

    const user_profile_id = selectedEmployee.user_profile_id;
    const full_name = nameRef.current.value || selectedEmployee.full_name;
    const employee_id_no =
      employeeIdRef.current.value || selectedEmployee.employee_id_no;
    const joining_date =
      joiningDateRef.current.value || selectedEmployee.joining_date;
    const mobile_no = contactNoRef.current.value || selectedEmployee.mobile_no;
    const alternate_mobile_no =
      alternateContactNoRef.current.value ||
      selectedEmployee.alternate_mobile_no;
    const nid = nidNoRef.current.value || selectedEmployee.nid;
    const staff_cat_id =
      staffCategoryRef.current.value || selectedEmployee.staff_cat_id;
    const is_active = statusRef.current.value || selectedEmployee.is_active;
    let updatedEmployee = {
      user_profile_id,
      full_name,
      employee_id_no,
      joining_date,
      mobile_no,
      alternate_mobile_no,
      nid,
      staff_cat_id,
      is_active,
    };

    if (staff_cat_id === "1") {
      const designation_id =
        designationRef.current.value || selectedEmployee.designation_id;
      updatedEmployee = {
        ...updatedEmployee,
        designation_id,
      };
    } else {
      const driver_lic_no =
        drivingLicenseRef.current.value || selectedEmployee.driver_lic_no;
      const driver_cat_id =
        drivingLicenseCategoryRef.current.value ||
        selectedEmployee.driver_cat_id;
      const driver_availablity =
        availablityRef.current.value || selectedEmployee.driver_availability;
      let purpose_id = purposeRef.current.value;
      updatedEmployee = {
        ...updatedEmployee,
        driver_lic_no,
        driver_cat_id,
        driver_availablity,
        purpose_id,
      };

      if (purpose_id === "2") {
        const dedicated_for_id =
          dedicatedToRef.current.value || selectedEmployee.dedicated_for_id;
        updatedEmployee = { ...updatedEmployee, dedicated_for_id };
      }
    }
    fetch(
      "https://kdaback.nescostore.com/api/user-update",
      await handlePostApiHeader(updatedEmployee)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allemployee");
        if (flag) {
          let updatedEmployes = [];
          employees.map((e) => {
            if (e.user_profile_id === user_profile_id) {
              updatedEmployes.push(updatedEmployee);
            } else {
              updatedEmployes.push(e);
            }
          });

          setEmployees(updatedEmployes);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.full_name,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "Contact No",
      selector: (row) => row.mobile_no,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          onClick={() => handleSelectedEmployee(row.user_profile_id)}
          className="text-base"
        >
          <label className=" btn-link px-2" for="edit-employee">
            <i title="Edit" class="fa-regular fa-pen-to-square"></i>
          </label>
          <label className="btn-link px-2 modal-button" for="model-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold"> All Employee </h2>
      <DataTable
        pagination
        columns={columns}
        data={employees}
        progressPending={employeeLoading}
        selectableRows
      />
      {/* edit form  */}
      {modalField && (
        <div>
          <input type="checkbox" id="edit-employee" class="modal-toggle" />
          <label for="edit-employee" class="modal cursor-pointer">
            <label
              class="modal-box relative w-5/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-employee"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Employee Info
                    </h2>
                    <form onSubmit={handleEmployeeEdit}>
                      <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
                        <div>
                          <div class="form-control my-4">
                            <label className="input-group">
                              <span className="w-2/6">Full Name</span>
                              <input
                                type="text"
                                key={selectedEmployee.full_name}
                                defaultValue={selectedEmployee.full_name}
                                class="input input-bordered w-4/6"
                                ref={nameRef}
                                onBlur={handleName}
                              />
                            </label>
                          </div>
                          {nameError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{nameError}</span>
                              </div>
                            </div>
                          )}
                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">Employee ID</span>
                              <input
                                type="text"
                                key={selectedEmployee.employee_id_no}
                                defaultValue={selectedEmployee.employee_id_no}
                                class="input input-bordered w-4/6"
                                ref={employeeIdRef}
                                onBlur={handleEmployeeId}
                              />
                            </label>
                          </div>
                          {employeeIdError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{employeeIdError}</span>
                              </div>
                            </div>
                          )}

                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">Joining Date</span>
                              <input
                                type="date"
                                key={selectedEmployee?.joining_date}
                                defaultValue={selectedEmployee?.joining_date}
                                class="input input-bordered w-4/6"
                                ref={joiningDateRef}
                                onBlur={handleJoiningDate}
                              />
                            </label>
                          </div>
                          {joiningDateError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{joiningDateError}</span>
                              </div>
                            </div>
                          )}
                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">Mobile Number</span>
                              <input
                                type="text"
                                key={selectedEmployee.mobile_no}
                                defaultValue={selectedEmployee.mobile_no}
                                class="input input-bordered w-4/6"
                                ref={contactNoRef}
                                onBlur={handleContactNo}
                              />
                            </label>
                          </div>
                          {contactNoError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{contactNoError}</span>
                              </div>
                            </div>
                          )}
                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">Alternate Mobile</span>
                              <input
                                type="text"
                                key={selectedEmployee.alternate_mobile_no}
                                defaultValue={
                                  selectedEmployee.alternate_mobile_no
                                }
                                class="input input-bordered w-4/6"
                                ref={alternateContactNoRef}
                                onBlur={handleAlternateContactNo}
                              />
                            </label>
                          </div>
                          {alternateContactNoError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{alternateContactNoError}</span>
                              </div>
                            </div>
                          )}
                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">NID No</span>
                              <input
                                type="text"
                                key={selectedEmployee.nid}
                                defaultValue={selectedEmployee.nid}
                                class="input input-bordered w-4/6"
                                ref={nidNoRef}
                                onBlur={handleNidNo}
                              />
                            </label>
                          </div>
                          {nidNoError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{nidNoError}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">Staff Category</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={staffCategoryRef}
                                key={selectedEmployee.staff_cat_id}
                                defaultValue={selectedEmployee.staff_cat_id}
                                onChange={handleStaffCategory}
                              >
                                <option value="1">Officer</option>
                                <option value="2">Driver</option>
                              </select>
                            </label>
                          </div>
                          {driverField ? (
                            <>
                              <div class="form-control my-4">
                                <label class="input-group">
                                  <span className="w-2/6">Driving License</span>
                                  <input
                                    type="text"
                                    key={selectedEmployee?.driver_lic_no}
                                    defaultValue={
                                      selectedEmployee?.driver_lic_no
                                    }
                                    class="input input-bordered w-4/6"
                                    ref={drivingLicenseRef}
                                    onBlur={handleDrivingLicense}
                                  />
                                </label>
                              </div>
                              {drivingLicenseError && (
                                <div className="alert shadow-lg">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="stroke-current flex-shrink-0 h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                      />
                                    </svg>
                                    <span>{drivingLicenseError}</span>
                                  </div>
                                </div>
                              )}
                              <div class="form-control my-4">
                                <label class="input-group">
                                  <span className="w-2/6">
                                    Licenses Category
                                  </span>
                                  <select
                                    className="select select-bordered w-4/6"
                                    ref={drivingLicenseCategoryRef}
                                    key={selectedEmployee?.driver_cat_id}
                                    defaultValue={
                                      selectedEmployee?.driver_cat_id
                                    }
                                  >
                                    {driverCategoryLoading ? (
                                      <option disabled>Loading ....</option>
                                    ) : (
                                      driverCategoryOptions.map(
                                        (driverCategoryOption) =>
                                          selectedEmployee?.driver_cat_id ===
                                          driverCategoryOption?.driver_cat_id ? (
                                            <option
                                              selected
                                              value={
                                                driverCategoryOption?.driver_cat_id
                                              }
                                            >
                                              {
                                                driverCategoryOption?.driver_cat_name
                                              }
                                            </option>
                                          ) : (
                                            <option
                                              value={
                                                driverCategoryOption?.driver_cat_id
                                              }
                                            >
                                              {
                                                driverCategoryOption?.driver_cat_name
                                              }
                                            </option>
                                          )
                                      )
                                    )}
                                  </select>
                                </label>
                              </div>
                              <div className="form-control my-3">
                                <label className="input-group">
                                  <span className="w-2/6">Purpose</span>
                                  <select
                                    className="select select-bordered w-4/6"
                                    ref={purposeRef}
                                    onChange={handlePurpose}
                                    key={selectedEmployee?.purpose_id}
                                    defaultValue={selectedEmployee?.purpose_id}
                                  >
                                    {purposeOptionsLoading ? (
                                      <option disabled>Loading ....</option>
                                    ) : (
                                      purposeOptions.map((purposeOption) =>
                                        selectedEmployee?.car_purpose_id ===
                                        purposeOption.car_purpose_id ? (
                                          <option
                                            selected
                                            value={purposeOption.car_purpose_id}
                                          >
                                            {
                                              purposeOption.car_purpose_description
                                            }
                                          </option>
                                        ) : (
                                          <option
                                            value={purposeOption.car_purpose_id}
                                          >
                                            {
                                              purposeOption.car_purpose_description
                                            }
                                          </option>
                                        )
                                      )
                                    )}
                                  </select>
                                </label>
                              </div>
                              {dedicatedField && (
                                <>
                                  <div className="form-control my-3">
                                    <label className="input-group">
                                      <span className="w-2/6">
                                        Dedicated To
                                      </span>
                                      <select
                                        className="select select-bordered w-4/6"
                                        key={selectedEmployee.dedicated_for_id}
                                        defaultValue={
                                          selectedEmployee.dedicated_for_id
                                        }
                                        ref={dedicatedToRef}
                                      >
                                        {officerOptionsLoading ? (
                                          <option disabled>Loading ....</option>
                                        ) : (
                                          officerOptions.map((officerOption) =>
                                            selectedEmployee.user_id ===
                                            officerOption.user_id ? (
                                              <option
                                                selected
                                                value={officerOption.user_id}
                                              >
                                                {officerOption.full_name}
                                              </option>
                                            ) : (
                                              <option
                                                value={officerOption.user_id}
                                              >
                                                {officerOption.full_name}
                                              </option>
                                            )
                                          )
                                        )}
                                      </select>
                                    </label>
                                  </div>
                                </>
                              )}
                              <div class="form-control my-4">
                                <label class="input-group">
                                  <span className="w-2/6">Availablity</span>
                                  <select
                                    className="select select-bordered w-4/6"
                                    ref={availablityRef}
                                    key={selectedEmployee.driver_availability}
                                    defaultValue={
                                      selectedEmployee.driver_availability
                                    }
                                  >
                                    <option value="1">Available</option>
                                    <option value="2">On-Leave</option>
                                  </select>
                                </label>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class="form-control my-4">
                                <label class="input-group">
                                  <span className="w-2/6">Designation</span>
                                  <select
                                    className="select select-bordered w-4/6"
                                    ref={designationRef}
                                    key={selectedEmployee.designation_id}
                                    defaultValue={
                                      selectedEmployee.designation_id
                                    }
                                  >
                                    {designationLoading ? (
                                      <option disabled>Loading ....</option>
                                    ) : (
                                      designationOptions?.map(
                                        (designationOption) =>
                                          selectedEmployee.designation_id ===
                                          designationOption.designation_id ? (
                                            <option
                                              selected
                                              key={
                                                designationOption.designation_id
                                              }
                                              value={
                                                designationOption.designation_id
                                              }
                                            >
                                              {designationOption.designation}
                                            </option>
                                          ) : (
                                            <option
                                              key={
                                                designationOption.designation_id
                                              }
                                              value={
                                                designationOption.designation_id
                                              }
                                            >
                                              {designationOption.designation}
                                            </option>
                                          )
                                      )
                                    )}
                                  </select>
                                </label>
                              </div>
                            </>
                          )}

                          <div class="form-control my-4">
                            <label class="input-group">
                              <span className="w-2/6">Status</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={statusRef}
                                key={selectedEmployee.is_active}
                                defaultValue={selectedEmployee.is_active}
                              >
                                <option value={1}>Active</option>
                                <option value={2}>Idle</option>
                              </select>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-control my-4">
                        <input
                          className="btn btn-danger w-48 mx-auto"
                          disabled={submitFormDisable}
                          type="submit"
                          value="Update"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}

      {/* details view  */}
      {selectedEmployee && (
        <div>
          <input type="checkbox" id="model-details" class="modal-toggle" />
          <label for="model-details" class="modal cursor-pointer">
            <label
              class="modal-box relative lg:w-4/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="model-details"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <h3 class="text-lg text-center font-bold">
                Details About This Employee
              </h3>

              <div className="py-4">
                <div className="overflow-x-auto">
                  <table className="table table-zebra overflow-x-scroll w-full">
                    <tbody>
                      <tr>
                        <td>Employee Name</td>
                        <td>{selectedEmployee?.full_name}</td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>{selectedEmployee?.designation}</td>
                      </tr>

                      <tr>
                        <td>Mobile No </td>
                        <td>{selectedEmployee?.mobile_no}</td>
                      </tr>
                      <tr>
                        <td>Alternative Mobile Number </td>
                        <td>{selectedEmployee?.alternate_mobile_no}</td>
                      </tr>
                      <tr>
                        <td>Employee ID </td>
                        <td>{selectedEmployee?.employee_id_no}</td>
                      </tr>
                      <tr>
                        <td>Joining Date </td>
                        <td>
                          {moment(
                            selectedEmployee.tax_token?.toString()
                          ).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                      {selectedEmployee?.staff_cat_id === "2" && (
                        <>
                          <tr>
                            <td>Driver License </td>
                            <td>{selectedEmployee?.driver_lic_no}</td>
                          </tr>
                          <tr>
                            <td>Driver Category </td>
                            <td>{selectedEmployee?.driver_cat_name}</td>
                          </tr>
                          <tr>
                            <td>Purpose </td>
                            <td>{selectedEmployee?.car_purpose_description}</td>
                          </tr>
                          {selectedEmployee?.purpose_id === "2" && (
                            <tr>
                              <td>Dedicated For </td>
                              <td>{selectedEmployee?.dedicated_for_name}</td>
                            </tr>
                          )}

                          <tr>
                            <td>Availability </td>
                            <td>
                              {(selectedEmployee.driver_availability === "2")
                                ? "On-Leave"
                                : "Available"}
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td>Status</td>
                        <td>
                          {selectedEmployee.is_active ? "Active" : "Not Active"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
    </div>
  );
};

export default AllEmployee;
