import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useGetApiHeader, useLogout } from "../../helper/customHook";

const AllLocation = () => {
  // all State
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // logout state
  const [logout] = useLogout();
  const [selectedLocation, setSelectedLocation] = useState({});
  const [locations, setLocations] = useState([]);
  
   // all Ref
   const nameRef = useRef();
   const activityRef = useRef();

  const navigate = useNavigate()

  const [locationLoading, setLocationLoading] = useState(true);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-trip-locations", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setLocations(data);
          setLocationLoading(false);
        } else {
          logout();
        }
      });
  }, []);


   // all function
   const handleSelectedLocation = (id) => {
    const location = locations.filter((l) => l.trip_location_id === id);
    setSelectedLocation(location[0]);
  };

  const handleLocationEdit = () => {
    const trip_location_name =
      nameRef.current.value || selectedLocation.trip_location_name;
    const is_active = activityRef.current.value;

    setSelectedLocation(null);
  };

  const columns = [
    {
      name: "Location Name",
      selector: (row) => row.trip_location_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <label className=" btn-link mx-1" for="edit-brand">
          <i
            title="Edit"
            onClick={() =>handleSelectedLocation(row.trip_location_id)}
            class="fa-regular fa-pen-to-square"
          ></i>
        </label>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 font-semibold"> All Trip Location</h2>{" "}
      <div className="flex justify-end mb-4">
      <h1 className="btn btn-primary text-right" onClick={()=>navigate("/addlocation")} >Add Location </h1>
        </div>
      <DataTable
        pagination
        columns={columns}
        data={locations}
        progressPending={locationLoading}
        selectableRows
      />
            {selectedLocation && (
        <div>
          <input type="checkbox" id="edit-brand" class="modal-toggle" />
          <label for="car-schedule-details" class="modal cursor-pointer">
            <label
              class="modal-box relative w-3/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-brand"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Location Info
                    </h2>
                    <div className="mx-auto lg:w-1/2">
                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Name</span>
                          <input
                            type="text"
                            ref={nameRef}
                            key={selectedLocation.trip_location_name}
                            defaultValue={selectedLocation.trip_location_name}
                            className="input w-4/6 input-bordered"
                          />
                        </label>
                      </div>

                      <div className="form-control my-3">
                        <label className="input-group">
                          <span className="w-2/6">Activity</span>
                          <select
                            className="select select-bordered w-4/6"
                            ref={activityRef}
                          >
                            <option value="1">Active</option>

                            <option value="0">Not Active</option>
                          </select>
                        </label>
                      </div>
                      <button
                        className="btn btn-primary my-10 mx-auto lg:w-full"
                        onClick={() =>
                          handleLocationEdit(selectedLocation.trip_location_id)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
    </div>
  );
};

export default AllLocation;
