import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetApiHeader,
  useLogout,
  usePostApiHeader,
  usePostApiResponse,
} from "../../helper/customHook";

const AddModel = () => {
  // all state
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // logout state
  const [logout] = useLogout();
  // error State
  const [modelError, setModelError] = useState("");
  // data State
  const [brandOptions, setBrandOptions] = useState([]);
  // loading State
  const [brandOptionLoading, setBrandOptionsLoading] = useState(true);

  // ref
  const brandRef = useRef();
  const modelRef = useRef();
  const activityRef = useRef();

  // navigate State
  const navigate = useNavigate();

  // all Use useEffect
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-brands", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setBrandOptions(data);
          setBrandOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  const handleModel = () => {
    let model = modelRef.current.value;
    if (model.length) {
      setModelError("");
    } else {
      setModelError("This Field can not be Empty");
    }
  };

  const handleAddModel = async () => {
    const car_brand_id = brandRef.current.value;
    const car_model_name = modelRef.current.value;
    const is_active = activityRef.current.value;
    const newModel = { car_brand_id, car_model_name, is_active };
    fetch(
      "https://kdaback.nescostore.com/api/store-car-model",
      await handlePostApiHeader(newModel)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/allmodel"))
      .catch((error) => {
        console.log("error:", error);
      });
  };
  return (
    <div>
      <h2 className="text-xl my-4 text-center font-semibold">Add a Model</h2>
      <div className="mx-auto w-1/2">
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Brand</span>
            <select className="select select-bordered w-4/6" ref={brandRef}>
              <option disabled selected>
                {" "}
                ------ Select Brand ------{" "}
              </option>
              {brandOptionLoading ? (
                <option disabled>Loading ....</option>
              ) : (
                brandOptions.map((brand) => (
                  <option value={brand.car_brand_id}>
                    {" "}
                    {brand.car_brand_name}{" "}
                  </option>
                ))
              )}
            </select>
          </label>
        </div>
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Model</span>
            <input
              type="text"
              placeholder="ex: Accord Hybrid"
              className="input w-4/6 input-bordered"
              ref={modelRef}
              onBlur={handleModel}
            />
          </label>
        </div>
        {modelError && (
          <div className="alert shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{modelError}</span>
            </div>
          </div>
        )}
        <div className="form-control my-3">
          <label className="input-group">
            <span className="w-2/6">Activity</span>
            <select className="select select-bordered w-4/6" ref={activityRef}>
              <option value="1">Active</option>

              <option value="0">Not Active</option>
            </select>
          </label>
        </div>
        <div className="w-48 mx-auto">
          <button
            className="btn btn-primary my-10 w-full"
            onClick={handleAddModel}
          >
            ADD Model
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddModel;
