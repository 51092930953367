import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";


const OfficerAuth = ({ children }) => {
    // user state
    const { user, loading, error } = useSelector((state) => ({
      ...state.user,
    }));

  let location = useLocation();
  if(loading){
    return <></>
  }
  if (user[0]?.data?.staff_cat_id!=="1") {
    
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/"/>;
  }

  return children;
};

export default OfficerAuth;
