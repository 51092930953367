import React, { useEffect, useRef, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  useGetApiHeader,
  useLogout,
  usePostApiHeader,
  usePostApiResponse,
} from "../../helper/customHook";
const CarEntry = () => {
  // all state
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handlePostResponse] = usePostApiResponse();

  // logout state
  const [logout] = useLogout();

  // all Data State
  const [owners, setOwners] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [runOnOptions, setRunOnOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);

  // allLoadingState
  const [ownerLoading, setOwnerLoading] = useState(true);
  const [vehicleTypesLoading, setVehicleTypesLoading] = useState(true);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [modelsLoading, setModelsLoading] = useState(true);
  const [userOptionsLoading, setUserOptionsLoading] = useState(true);
  const [runOnOptionsLoading, setRunOnOptionsLoading] = useState(true);
  const [purposeOptionsLoading, setPurposeOptionsLoading] = useState(true);

  // date data state
  const [modelYear, setModelYear] = useState("");
  // errror State
  const [vehicleNoError, setVehicleNoError] = useState("");
  const [chassisNoError, setChassisNoError] = useState("");
  const [ownerError, setOwnerError] = useState("");
  const [seatNoError, setSeatNoError] = useState("");
  const [taxTokenDateError, setTaxTokenDateError] = useState("");
  const [roadPermitDateError, setRoadPermitDateError] = useState("");
  const [fitnessDateError, setFitnessDateError] = useState("");
  const [milesReadingError, setMilesReadingError] = useState("");
  const [modelYearError, setModelYearError] = useState("");

  // field State
  const [dedicatedField, setDedicatedField] = useState(false);
  const [modelField, setModelField] = useState(false);

  // All use Ref
  const vehicleNoRef = useRef();
  const chassisNoRef = useRef();
  const ownerRef = useRef();
  const vehicleTypeRef = useRef();
  const brandRef = useRef();
  const seatNoRef = useRef();
  const taxTokenDateRef = useRef();
  const roadPermitDateRef = useRef();
  const fitnessDateRef = useRef();
  const purposeRef = useRef();
  const milesReadingRef = useRef();
  const modelRef = useRef();
  const modelYearRef = useRef();
  const runOnRef = useRef();
  const dedicatedToRef = useRef();
  const activityRef = useRef();

  // all useEffect
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/actv-owners", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setOwners(data);
          setOwnerLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch(
      "https://kdaback.nescostore.com/api/actv-car-categories",
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setVehicleTypes(data);
          setVehicleTypesLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/actv-car-brands", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setBrands(data);
          setBrandsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setUserOptions(data);
          setUserOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/actv-car-run-on", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setRunOnOptions(data);
          setRunOnOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-purposes", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setPurposeOptions(data);
          setPurposeOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all Function
  const handleVehicleNo = () => {
    const vehicleNo = vehicleNoRef.current.value;
    if (vehicleNo.length) {
      setVehicleNoError("");
    } else {
      setVehicleNoError("This Field can not be Empty");
    }
  };
  const handleChassisNo = () => {
    const chassisNo = chassisNoRef.current.value;
    if (chassisNo.length) {
      setChassisNoError("");
    } else {
      setChassisNoError("This Field can not be Empty");
    }
  };
  const handleBrand = () => {
    const brandId = brandRef.current.value;
    setModelField(true);
    fetch(
      `https://kdaback.nescostore.com/api/brand-wise-model/${brandId}`,
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setModelOptions(data);
          setModelsLoading(false);
        } else {
          logout();
        }
      });
  };
  const handleSeatNo = () => {
    const seatNo = seatNoRef.current.value;
    if (seatNo.length) {
      if (isNaN(seatNo)) {
        setSeatNoError("Seat Capacity must be a number");
      } else {
        setSeatNoError("");
      }
    } else {
      setSeatNoError("This Field can not be Empty");
    }
  };
  const handleTaxTokenDate = () => {
    const taxTokenDate = taxTokenDateRef.current.value;
    const taxTokenDateTime = new Date(taxTokenDate);
    const nowTime = Date.now();

    if (nowTime > taxTokenDateTime.getTime()) {
      setTaxTokenDateError("Can not entry any Tax Token Expried car");
    } else {
      setTaxTokenDateError("");
    }
  };
  const handleRoadPermitDate = () => {
    const roadPermitDate = roadPermitDateRef.current.value;
    const roadPermitDateTime = new Date(roadPermitDate);
    const nowTime = Date.now();

    if (nowTime > roadPermitDateTime.getTime()) {
      setRoadPermitDateError("Can not entry any Route Permit Expried car");
    } else {
      setRoadPermitDateError("");
    }
  };
  const handleFitnessDate = () => {
    const fitnessDate = fitnessDateRef.current.value;
    const fitnessDateTime = new Date(fitnessDate);
    const nowTime = Date.now();

    if (nowTime > fitnessDateTime.getTime()) {
      setFitnessDateError("Can not entry any Fitness Expried car");
    } else {
      setFitnessDateError("");
    }
  };
  const handleMilesReading = () => {
    const milesReading = milesReadingRef.current.value;
    if (milesReading.length) {
      if (isNaN(milesReading)) {
        setMilesReadingError("miles must be a number");
      } else {
        setMilesReadingError("");
      }
    } else {
      setMilesReadingError("This Field can not be Empty");
    }
  };

  const handlePurpose = () => {
    let purpose = purposeRef.current.value;
    const dedicatedPurpuse = purposeOptions.filter(
      (purposeOption) => purposeOption.car_purpose_description === "Dedicated"
    );
    if (dedicatedPurpuse.length) {
      if (purpose === dedicatedPurpuse[0].car_purpose_id) {
        setDedicatedField(true);
      } else {
        setDedicatedField(false);
      }
    }
  };

  const handleModelYear = (date) => {
    if (typeof date === "object") {
      setModelYearError("");
      setModelYear(date.year());
    } else {
      setModelYearError("This is not Valid model Year");
    }
  };

  const handleAddNewCar = async () => {
    const vehicle_no = vehicleNoRef?.current.value;
    const chasis_no = chassisNoRef?.current.value;
    const owner_id = ownerRef?.current.value;
    const car_category_id = vehicleTypeRef?.current.value;
    const seat_capacity = seatNoRef?.current.value;
    const car_brand_id = brandRef?.current.value;
    const car_model_id = modelRef?.current.value;
    const tax_token = taxTokenDateRef.current.value;
    const road_permit = roadPermitDateRef?.current.value;
    const fitness_date = fitnessDateRef?.current.value;
    const purpose = purposeRef?.current.value;
    const initial_milage = milesReadingRef?.current.value;
    const model_year = modelYear;
    const car_run_on_id = runOnRef?.current.value;
    const is_active = activityRef?.current.value;

    let newCar = {};
    const dedicatedPurpuse = purposeOptions.filter(
      (purposeOption) => purposeOption.car_purpose_description === "Dedicated"
    );

    if (dedicatedPurpuse.length) {
      if (purpose === dedicatedPurpuse[0].car_purpose_id) {
        const dedicated_for = dedicatedToRef?.current.value;
        newCar = {
          vehicle_no,
          chasis_no,
          owner_id,
          car_category_id,
          seat_capacity,
          car_brand_id,
          car_model_id,
          tax_token,
          road_permit,
          fitness_date,
          purpose,
          initial_milage,
          model_year,
          car_run_on_id,
          dedicated_for,
          is_active,
        };
      } else {
        newCar = {
          vehicle_no,
          chasis_no,
          owner_id,
          car_category_id,
          seat_capacity,
          car_brand_id,
          car_model_id,
          tax_token,
          road_permit,
          fitness_date,
          purpose,
          initial_milage,
          model_year,
          car_run_on_id,
          is_active,
        };
      }
    }

    fetch(
      "https://kdaback.nescostore.com/api/store-car",
      await handlePostApiHeader(newCar)
    )
      .then((res) => res.json())
      .then((data) => handlePostResponse(data, "/allcar"))
      .catch((error) => {
        console.log("error:", error);
      });
  };

  return (
    <div className="min-h-screen bg-base-100">
      <div>
        <h3 className="text-xl text-center font-semibold my-5">
          Entry New Car
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
          <div className="">
            {/* this part is car entry from input  */}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Vehicle No</span>
                <input
                  type="text"
                  className="input w-4/6 input-bordered"
                  ref={vehicleNoRef}
                  onBlur={handleVehicleNo}
                />
              </label>
            </div>
            {vehicleNoError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{vehicleNoError}</span>
                </div>
              </div>
            )}
            <div className="form-control">
              <label className="input-group">
                <span className="w-2/6">Chassis No</span>
                <input
                  type="text"
                  className="input w-4/6 input-bordered"
                  ref={chassisNoRef}
                  onBlur={handleChassisNo}
                />
              </label>
            </div>
            {chassisNoError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{chassisNoError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Owner</span>
                <select className="select select-bordered w-4/6" ref={ownerRef}>
                  <option disabled selected>
                    {" "}
                    ------ Select Owner ------{" "}
                  </option>
                  {ownerLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    owners.map((own) => (
                      <option value={own.owner_id}> {own.owner_name} </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            {ownerError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{ownerError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Vehicle Type</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={vehicleTypeRef}
                >
                  <option disabled selected>
                    {" "}
                    ------ Select Vehicle Category ------{" "}
                  </option>
                  {vehicleTypesLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    vehicleTypes.map((type) => (
                      <option value={type.car_category_id}>
                        {" "}
                        {type.car_category_name}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Seat Capacity</span>
                <input
                  type="text"
                  placeholder="example : 24"
                  className="input w-4/6 input-bordered"
                  ref={seatNoRef}
                  onBlur={handleSeatNo}
                />
              </label>
            </div>
            {seatNoError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{seatNoError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Brand</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={brandRef}
                  onChange={handleBrand}
                >
                  <option disabled selected>
                    {" "}
                    ------ Select Brand ------{" "}
                  </option>
                  {brandsLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    brands.map((brand) => (
                      <option value={brand.car_brand_id}>
                        {" "}
                        {brand.car_brand_name}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Model</span>
                <select className="select select-bordered w-4/6" ref={modelRef}>
                  {modelField ? (
                    <>
                      <option disabled selected>
                        {" "}
                        ------ Select Model ------{" "}
                      </option>
                      {modelsLoading ? (
                        <p>Loading ....</p>
                      ) : (
                        modelOptions.map((model) => (
                          <option value={model.car_model_id}>
                            {" "}
                            {model.car_model_name}{" "}
                          </option>
                        ))
                      )}
                    </>
                  ) : (
                    <option disabled selected>
                      {" "}
                      select Brand First
                    </option>
                  )}
                </select>
              </label>
            </div>
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6 h-12">Model Year</span>
                <Datetime
                  dateFormat="YYYY"
                  inputProps={{ className: "select w-full input-bordered" }}
                  onClose={(date) => handleModelYear(date)}
                  timeFormat={false}
                />
              </label>
            </div>
            {modelYearError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{modelYearError}</span>
                </div>
              </div>
            )}
          </div>
          <div className="">
            {/* this part is car entry from input  */}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Tax Token Date</span>
                <input
                  type="date"
                  className="input w-4/6 input-bordered"
                  ref={taxTokenDateRef}
                  onBlur={handleTaxTokenDate}
                />
              </label>
            </div>
            {taxTokenDateError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{taxTokenDateError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Road Permit</span>
                <input
                  type="date"
                  className="input w-4/6 input-bordered"
                  placeholder="dd-mm-yyyy"
                  ref={roadPermitDateRef}
                  onBlur={handleRoadPermitDate}
                />
              </label>
            </div>
            {roadPermitDateError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{roadPermitDateError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Fitness Date</span>
                <input
                  type="date"
                  className="input w-4/6 input-bordered"
                  ref={fitnessDateRef}
                  onBlur={handleFitnessDate}
                />
              </label>
            </div>
            {fitnessDateError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{fitnessDateError}</span>
                </div>
              </div>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Purpose</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={purposeRef}
                  onChange={handlePurpose}
                >
                  <option disabled selected>
                    {" "}
                    ------ Select Purpose ------{" "}
                  </option>
                  {purposeOptionsLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    purposeOptions.map((purposeOption) => (
                      <option value={purposeOption.car_purpose_id}>
                        {" "}
                        {purposeOption.car_purpose_description}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>

            {dedicatedField && (
              <>
                <div className="form-control my-3">
                  <label className="input-group">
                    <span className="w-2/6">Dedicated To</span>
                    <select
                      className="select select-bordered w-4/6"
                      ref={dedicatedToRef}
                    >
                      <option disabled selected>
                        {" "}
                        ------ Select Officer ------{" "}
                      </option>
                      {userOptionsLoading ? (
                        <option disabled>Loading ....</option>
                      ) : (
                        userOptions.map((userOption) => (
                          <option value={userOption.user_id}>
                            {" "}
                            {userOption.full_name}{" "}
                          </option>
                        ))
                      )}
                    </select>
                  </label>
                </div>
              </>
            )}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Initial Miles count</span>
                <input
                  type="text"
                  placeholder="23044"
                  className="input w-4/6 input-bordered"
                  ref={milesReadingRef}
                  onBlur={handleMilesReading}
                />
              </label>
            </div>
            {milesReadingError && (
              <div className="alert shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>{milesReadingError}`</span>
                </div>
              </div>
            )}
            {/* <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Model Year</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={modelYearRef}
                >
                  <option value="1">2019</option>
                  <option value="2">2020</option>
                  <option value="3">2021</option>
                  <option value="4">2022</option>
                </select>
              </label>
            </div> */}
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Run On</span>
                <select className="select select-bordered w-4/6" ref={runOnRef}>
                  <option disabled selected>
                    {" "}
                    ------ Select Run On ------{" "}
                  </option>
                  {runOnOptionsLoading ? (
                    <option disabled>Loading ....</option>
                  ) : (
                    runOnOptions.map((runOnOption) => (
                      <option value={runOnOption.car_run_on_id}>
                        {" "}
                        {runOnOption.car_run_on_name}{" "}
                      </option>
                    ))
                  )}
                </select>
              </label>
            </div>
            <div className="form-control my-3">
              <label className="input-group">
                <span className="w-2/6">Activity</span>
                <select
                  className="select select-bordered w-4/6"
                  ref={activityRef}
                >
                  <option value="1">Active</option>

                  <option value="0">Not Active</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        <div className="w-48 mx-auto">
          <button
            onClick={handleAddNewCar}
            className="btn btn-primary my-10 mx-auto lg:w-44"
          >
            ADD Vehicle
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarEntry;
