import React from 'react';

const NotFoundErrorPage = () => {
    return (
        <div className='container'>
            
            <img alt="...Not Found" src="https://www.impactplus.com/hubfs/404-error-page-examples-best.jpg" />
        </div>
    );
};

export default NotFoundErrorPage;