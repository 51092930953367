import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import Datetime from "react-datetime";
import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

//var moment = require('moment');
import moment from "moment";

const AllCar = () => {
  // user State
  // All State
  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // logout state
  const [logout] = useLogout();

  const [cars, setCars] = useState([]);
  const [modelYear, setModelYear] = useState("");
  const [selectedCar, setSelectedCar] = useState([]);

  // all Data State
  const [owners, setOwners] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [runOnOptions, setRunOnOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);

  // allLoadingState
  const [ownerLoading, setOwnerLoading] = useState(true);
  const [vehicleTypesLoading, setVehicleTypesLoading] = useState(true);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [modelsLoading, setModelsLoading] = useState(true);
  const [userOptionsLoading, setUserOptionsLoading] = useState(true);
  const [runOnOptionsLoading, setRunOnOptionsLoading] = useState(true);
  const [purposeOptionsLoading, setPurposeOptionsLoading] = useState(true);

  // errror State
  const [vehicleNoError, setVehicleNoError] = useState("");
  const [chassisNoError, setChassisNoError] = useState("");
  const [seatNoError, setSeatNoError] = useState("");
  const [taxTokenDateError, setTaxTokenDateError] = useState("");
  const [roadPermitDateError, setRoadPermitDateError] = useState("");
  const [fitnessDateError, setFitnessDateError] = useState("");
  const [milesReadingError, setMilesReadingError] = useState("");
  const [modelYearError, setModelYearError] = useState("");

  // field State
  const [dedicatedField, setDedicatedField] = useState(false);
  const [modalField, setModalField] = useState(false);

  // All use Ref
  const vehicleNoRef = useRef();
  const chassisNoRef = useRef();
  const ownerRef = useRef();
  const vehicleTypeRef = useRef();
  const brandRef = useRef();
  const seatNoRef = useRef();
  const taxTokenDateRef = useRef();
  const roadPermitDateRef = useRef();
  const fitnessDateRef = useRef();
  const purposeRef = useRef();
  const milesReadingRef = useRef();
  const modelRef = useRef();
  const runOnRef = useRef();
  const dedicatedToRef = useRef();
  const activityRef = useRef();

  const [vehicleLoading, setVehicleLoading] = useState(true);

  // all useEffect
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-owners", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setOwners(data);
          setOwnerLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-categories", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setVehicleTypes(data);
          setVehicleTypesLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-brands", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setBrands(data);
          setBrandsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-officers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setUserOptions(data);
          setUserOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-run-on", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setRunOnOptions(data);
          setRunOnOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-car-purposes", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setPurposeOptions(data);
          setPurposeOptionsLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    if (selectedCar.car_brand_id) {
      fetch(
        `https://kdaback.nescostore.com/api/brand-wise-model/${selectedCar?.car_brand_id}`,
        getApiHeader
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data?.message) {
            setModelOptions(data);
            setModelsLoading(false);
          } else {
            logout();
          }
        });
    }
  }, [selectedCar?.car_brand_id]);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-cars", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setCars(data);
          setVehicleLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  // all function
  const handleSelectedCar = (id) => {
    setModalField(true);

    fetch(`https://kdaback.nescostore.com/api/edit-car/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedCar(data);
        if (data.purpose === "2") {
          setDedicatedField(true);
        } else {
          setDedicatedField(false);
        }
      });
  };

  const handleVehicleNo = () => {
    const vehicleNo = vehicleNoRef.current.value;
    if (vehicleNo.length) {
      setVehicleNoError("");
    }
  };
  const handleChassisNo = () => {
    const chassisNo = chassisNoRef.current.value;
    if (chassisNo.length) {
      setChassisNoError("");
    }
  };
  const handleBrand = () => {
    const brandId = brandRef.current.value;

    fetch(
      `https://kdaback.nescostore.com/api/brand-wise-model/${brandId}`,
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setModelOptions(data);
        } else {
          logout();
        }
      });
  };
  const handleSeatNo = () => {
    const seatNo = seatNoRef.current.value;
    if (seatNo.length) {
      if (isNaN(seatNo)) {
        setSeatNoError("Seat Capacity must be a number");
      } else {
        setSeatNoError("");
      }
    }
  };
  const handleTaxTokenDate = () => {
    const taxTokenDate = taxTokenDateRef.current.value;
    const taxTokenDateTime = new Date(taxTokenDate);
    const nowTime = Date.now();

    if (nowTime > taxTokenDateTime.getTime()) {
      setTaxTokenDateError("Can not entry any Tax Token Expried car");
    } else {
      setTaxTokenDateError("");
    }
  };
  const handleRoadPermitDate = () => {
    const roadPermitDate = roadPermitDateRef.current.value;
    const roadPermitDateTime = new Date(roadPermitDate);
    const nowTime = Date.now();

    if (nowTime > roadPermitDateTime.getTime()) {
      setRoadPermitDateError("Can not entry any Route Permit Expried car");
    } else {
      setRoadPermitDateError("");
    }
  };
  const handleFitnessDate = () => {
    const fitnessDate = fitnessDateRef.current.value;
    const fitnessDateTime = new Date(fitnessDate);
    const nowTime = Date.now();

    if (nowTime > fitnessDateTime.getTime()) {
      setFitnessDateError("Can not entry any Fitness Expried car");
    } else {
      setFitnessDateError("");
    }
  };
  const handleMilesReading = () => {
    const milesReading = milesReadingRef.current.value;
    if (milesReading.length) {
      if (isNaN(milesReading)) {
        setMilesReadingError("miles must be a number");
      } else {
        setMilesReadingError("");
      }
    }
  };

  const handleModelYear = (date) => {
    if (typeof date === "object") {
      setModelYearError("");
      setModelYear(date.year());
    } else {
      setModelYearError("This is not Valid model Year");
    }
  };

  const handlePurpose = () => {
    let purpose = purposeRef.current.value;
    const dedicatedPurpuse = purposeOptions.filter(
      (purposeOption) => purposeOption.car_purpose_description === "Dedicated"
    );
    if (dedicatedPurpuse.length) {
      if (purpose === dedicatedPurpuse[0].car_purpose_id) {
        setDedicatedField(true);
      } else {
        setDedicatedField(false);
      }
    }
  };

  const handleCarEdit = async (car_info_id) => {
    const vehicle_no = vehicleNoRef?.current.value || selectedCar?.vehicle_no;
    const chasis_no = chassisNoRef?.current.value || selectedCar?.chasis_no;
    const owner_id = ownerRef?.current.value || selectedCar?.owner_id;
    const car_category_id =
      vehicleTypeRef?.current.value || selectedCar?.car_category_id;
    const seat_capacity =
      seatNoRef?.current.value || selectedCar?.seat_capacity;
    const car_brand_id = brandRef?.current.value || selectedCar?.car_brand_id;
    const car_model_id = modelRef?.current.value || selectedCar?.car_model_id;
    const tax_token = taxTokenDateRef.current.value || selectedCar?.tax_token;
    const road_permit =
      roadPermitDateRef?.current.value || selectedCar?.road_permit;
    const fitness_date =
      fitnessDateRef?.current.value || selectedCar?.fitness_date;
    const purpose = purposeRef?.current.value || selectedCar?.purpose;
    const initial_milage =
      milesReadingRef?.current.value || selectedCar?.initial_milage;
    const model_year = modelYear || selectedCar?.model_year;
    const car_run_on_id = runOnRef?.current.value || selectedCar?.car_run_on_id;
    const is_active = activityRef?.current.value || selectedCar?.is_active;

    let updatedCar = {};
    const dedicatedPurpuse = purposeOptions.filter(
      (purposeOption) => purposeOption.car_purpose_description === "Dedicated"
    );

    if (dedicatedPurpuse.length) {
      if (purpose === dedicatedPurpuse[0].car_purpose_id) {
        const dedicated_for =
          dedicatedToRef?.current.value || selectedCar.dedicated_for;
        updatedCar = {
          car_info_id,
          vehicle_no,
          chasis_no,
          owner_id,
          car_category_id,
          seat_capacity,
          car_brand_id,
          car_model_id,
          tax_token,
          road_permit,
          fitness_date,
          purpose,
          initial_milage,
          model_year,
          car_run_on_id,
          dedicated_for,
          is_active,
        };
      } else {
        updatedCar = {
          car_info_id,
          vehicle_no,
          chasis_no,
          owner_id,
          car_category_id,
          seat_capacity,
          car_brand_id,
          car_model_id,
          tax_token,
          road_permit,
          fitness_date,
          purpose,
          initial_milage,
          model_year,
          car_run_on_id,
          is_active,
        };
      }
    }
    fetch(
      "https://kdaback.nescostore.com/api/update-car",
      await handlePostApiHeader(updatedCar)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allcar");
        if (flag) {
          let updatedCars = [];
          cars.map((c) => {
            if (c.car_info_id === car_info_id) {
              updatedCars.push(updatedCar);
            } else {
              updatedCars.push(c);
            }
          });

          setCars(updatedCars);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicle_no,
      sortable: true,
    },
    {
      name: "Chasis No",
      selector: (row) => row.chasis_no,
      sortable: true,
    },
    {
      name: "Fitness Date",
      //selector: (row) => row.fitness_date?.toString(),
      selector: (row) => {
        return moment(row.fitness_date?.toString()).format("DD/MM/YYYY");
      },
      sortable: true,
    },
    {
      name: "Road Permit",
      selector: (row) => {
        return moment(row.road_permit?.toString()).format("DD/MM/YYYY");
      },
      sortable: true,
    },
    {
      name: "Tax Token",
      selector: (row) => {
        return moment(row.tax_token?.toString()).format("DD/MM/YYYY");
      },
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          onClick={() => handleSelectedCar(row.car_info_id)}
          className="text-base"
        >
          <label className=" btn-link mx-1" for="edit-car">
            <i title="Edit" class="fa-regular fa-pen-to-square"></i>
          </label>
          <label className="btn-link mx-2 modal-button" for="car-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl text-center my-8 font-semibold"> All Vehicle </h2>
      <DataTable
        pagination
        columns={columns}
        data={cars}
        progressPending={vehicleLoading}
        selectableRows
      />

      {/* edit option  */}
      {modalField && (
        <div>
          <input type="checkbox" id="edit-car" class="modal-toggle" />
          <label for="" class="modal cursor-pointer">
            <label
              class="modal-box relative w-5/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="edit-car"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <div className="py-4">
                <div className="overflow-x-auto">
                  <div>
                    <h2 className="text-xl my-4 text-center font-semibold">
                      Edit Vehicle Info
                    </h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
                      <div className="">
                        {/* this part is car entry from input  */}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Vehicle No</span>
                            <input
                              type="text"
                              key={selectedCar.vehicle_no}
                              defaultValue={selectedCar.vehicle_no}
                              className="input w-4/6 input-bordered"
                              ref={vehicleNoRef}
                              onBlur={handleVehicleNo}
                            />
                          </label>
                        </div>
                        {vehicleNoError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{vehicleNoError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control">
                          <label className="input-group">
                            <span className="w-2/6">Chassis No</span>
                            <input
                              type="text"
                              key={selectedCar.chasis_no}
                              defaultValue={selectedCar.chasis_no}
                              className="input w-4/6 input-bordered"
                              ref={chassisNoRef}
                              onBlur={handleChassisNo}
                            />
                          </label>
                        </div>
                        {chassisNoError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{chassisNoError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Owner</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={ownerRef}
                              key={selectedCar.owner_id}
                              defaultValue={selectedCar.owner_id}
                            >
                              <option disabled selected>
                                {" "}
                                ------ Select Owner ------{" "}
                              </option>
                              {ownerLoading ? (
                                <option disabled>Loading ....</option>
                              ) : (
                                owners.map((own) =>
                                  selectedCar.owner_id === own.owner_id ? (
                                    <option selected value={own.owner_id}>
                                      {own.owner_name}
                                    </option>
                                  ) : (
                                    <option value={own.owner_id}>
                                      {own.owner_name}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                          </label>
                        </div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Vehicle Type</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={vehicleTypeRef}
                              key={selectedCar.car_category_id}
                              defaultValue={selectedCar.car_category_id}
                            >
                              <option disabled selected>
                                {" "}
                                ------ Select Vehicle Category ------{" "}
                              </option>
                              {vehicleTypesLoading ? (
                                <option disabled>Loading ....</option>
                              ) : (
                                vehicleTypes.map((type) =>
                                  selectedCar.car_category_id ===
                                  type.car_category_id ? (
                                    <option
                                      selected
                                      value={type.car_category_id}
                                    >
                                      {type.car_category_name}
                                    </option>
                                  ) : (
                                    <option value={type.car_category_id}>
                                      {type.car_category_name}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                          </label>
                        </div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Seat Capacity</span>
                            <input
                              type="text"
                              key={selectedCar.seat_capacity}
                              defaultValue={selectedCar.seat_capacity}
                              className="input w-4/6 input-bordered"
                              ref={seatNoRef}
                              onBlur={handleSeatNo}
                            />
                          </label>
                        </div>
                        {seatNoError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{seatNoError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Brand</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={brandRef}
                              key={selectedCar.car_brand_id}
                              defaultValue={selectedCar.car_brand_id}
                              onChange={handleBrand}
                            >
                              {brandsLoading ? (
                                <option disabled>Loading ....</option>
                              ) : (
                                brands.map((brand) =>
                                  selectedCar.car_brand_id ===
                                  brand.car_brand_id ? (
                                    <option selected value={brand.car_brand_id}>
                                      {brand.car_brand_name}
                                    </option>
                                  ) : (
                                    <option value={brand.car_brand_id}>
                                      {brand.car_brand_name}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                          </label>
                        </div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Model</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={modelRef}
                            >
                              {modelsLoading ? (
                                <p>Loading ....</p>
                              ) : (
                                modelOptions.map((model) =>
                                  selectedCar.car_model_id ===
                                  model.car_model_id ? (
                                    <option selected value={model.car_model_id}>
                                      {model.car_model_name}
                                    </option>
                                  ) : (
                                    <option value={model.car_model_id}>
                                      {model.car_model_name}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                          </label>
                        </div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6 h-12">Model Year</span>
                            <Datetime
                              dateFormat="YYYY"
                              inputProps={{
                                className: "select w-full input-bordered",
                              }}
                              key={selectedCar.model_year}
                              initialValue={selectedCar.model_year}
                              onClose={(date) => handleModelYear(date)}
                              timeFormat={false}
                            />
                          </label>
                        </div>
                        {modelYearError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{modelYearError}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="">
                        {/* this part is car entry from input  */}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Tax Token Date</span>
                            <input
                              type="date"
                              key={selectedCar?.tax_token}
                              defaultValue={selectedCar?.tax_token}
                              className="input w-4/6 input-bordered"
                              ref={taxTokenDateRef}
                              onBlur={handleTaxTokenDate}
                            />
                          </label>
                        </div>
                        {taxTokenDateError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{taxTokenDateError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Road Permit</span>
                            <input
                              type="date"
                              key={selectedCar.road_permit}
                              defaultValue={selectedCar.road_permit}
                              className="input w-4/6 input-bordered"
                              ref={roadPermitDateRef}
                              onBlur={handleRoadPermitDate}
                            />
                          </label>
                        </div>
                        {roadPermitDateError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{roadPermitDateError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Fitness Date</span>
                            <input
                              type="date"
                              key={selectedCar.fitness_date}
                              defaultValue={selectedCar.fitness_date}
                              className="input w-4/6 input-bordered"
                              ref={fitnessDateRef}
                              onBlur={handleFitnessDate}
                            />
                          </label>
                        </div>
                        {fitnessDateError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{fitnessDateError}</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Purpose</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={purposeRef}
                              onChange={handlePurpose}
                              key={selectedCar.purpose}
                              defaultValue={selectedCar.purpose}
                            >
                              {purposeOptionsLoading ? (
                                <option disabled>Loading ....</option>
                              ) : (
                                purposeOptions.map((purposeOption) => (
                                  <option value={purposeOption.car_purpose_id}>
                                    {" "}
                                    {purposeOption.car_purpose_description}{" "}
                                  </option>
                                ))
                              )}
                            </select>
                          </label>
                        </div>

                        {dedicatedField && (
                          <>
                            <div className="form-control my-3">
                              <label className="input-group">
                                <span className="w-2/6">Dedicated To</span>
                                <select
                                  className="select select-bordered w-4/6"
                                  ref={dedicatedToRef}
                                  key={selectedCar.dedicated_for}
                                  defaultValue={selectedCar.dedicated_for}
                                >
                                  <option disabled selected>
                                    {" "}
                                    ------ Select Officer ------{" "}
                                  </option>
                                  {userOptionsLoading ? (
                                    <option disabled>Loading ....</option>
                                  ) : (
                                    userOptions.map((userOption) => (
                                      <option value={userOption.user_id}>
                                        {" "}
                                        {userOption.full_name}{" "}
                                      </option>
                                    ))
                                  )}
                                </select>
                              </label>
                            </div>
                          </>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Initial Miles count</span>
                            <input
                              type="text"
                              key={selectedCar.initial_milage}
                              defaultValue={selectedCar.initial_milage}
                              className="input w-4/6 input-bordered"
                              ref={milesReadingRef}
                              onBlur={handleMilesReading}
                            />
                          </label>
                        </div>
                        {milesReadingError && (
                          <div className="alert shadow-lg">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span>{milesReadingError}`</span>
                            </div>
                          </div>
                        )}
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Run On</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={runOnRef}
                              key={selectedCar.car_run_on_id}
                              defaultValue={selectedCar.car_run_on_id}
                            >
                              <option disabled selected>
                                {" "}
                                ------ Select Run On ------{" "}
                              </option>
                              {runOnOptionsLoading ? (
                                <option disabled>Loading ....</option>
                              ) : (
                                runOnOptions.map((runOnOption) =>
                                  selectedCar.car_run_on_id ===
                                  runOnOption.car_run_on_id ? (
                                    <option
                                      selected
                                      value={runOnOption.car_run_on_id}
                                    >
                                      {runOnOption.car_run_on_name}
                                    </option>
                                  ) : (
                                    <option value={runOnOption.car_run_on_id}>
                                      {runOnOption.car_run_on_name}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                          </label>
                        </div>
                        <div className="form-control my-3">
                          <label className="input-group">
                            <span className="w-2/6">Activity</span>
                            <select
                              className="select select-bordered w-4/6"
                              ref={activityRef}
                              key={selectedCar.is_active}
                              defaultValue={selectedCar.is_active}
                            >
                              <option value="1">Active</option>

                              <option value="0">Not Active</option>
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={() => handleCarEdit(selectedCar.car_info_id)}
                      className="btn btn-primary my-10 mx-auto lg:w-72"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}

      {/* details view  */}
      {selectedCar && (
        <div>
          <input type="checkbox" id="car-details" class="modal-toggle" />
          <label for="car-details" class="modal cursor-pointer">
            <label
              class="modal-box relative w-4/6 lg:w-2/6 max-w-5xl text-justify"
              for=""
            >
              <label
                htmlFor="car-details"
                className="btn btn-sm btn-circle absolute right-2 top-4"
              >
                ✕
              </label>
              <h3 class="text-lg text-center font-bold">
                Details About This Vehicle
              </h3>

              <div className="py-4">
                <div className="overflow-x-auto">
                  <table className="table table-zebra w-full overflow-x-scroll">
                    <tbody>
                      <tr>
                        <td>Vehicle No</td>
                        <td>{selectedCar?.vehicle_no}</td>
                      </tr>
                      <tr>
                        <td>Chassis Number</td>
                        <td>{selectedCar?.chasis_no}</td>
                      </tr>
                      <tr>
                        <td>Owner</td>
                        <td>{selectedCar?.owner_name}</td>
                      </tr>
                      <tr>
                        <td>Brand Name</td>
                        <td>{selectedCar?.car_brand_name}</td>
                      </tr>
                      <tr>
                        <td>Model Name</td>
                        <td>{selectedCar?.car_model_name}</td>
                      </tr>
                      <tr>
                        <td>Model Year</td>
                        <td>{selectedCar?.model_year}</td>
                      </tr>
                      <tr>
                        <td>Vehicle Category</td>
                        <td>{selectedCar?.car_category_name}</td>
                      </tr>
                      
                      <tr>
                        <td>Fitness Date</td>
                        <td>
                          {moment(selectedCar?.fitness_date?.toString()).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Tax Token Date</td>
                        <td>
                          {moment(selectedCar?.tax_token?.toString()).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Road Permit Date</td>
                        <td>
                          {moment(selectedCar?.road_permit?.toString()).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Run On </td>
                        <td>{selectedCar?.car_run_on_name}</td>
                      </tr>
                      <tr>
                        <td>Purpose </td>
                        <td>{selectedCar?.car_purpose_description}</td>
                      </tr>
                      {selectedCar.purpose === "2" && (
                        <tr>
                          <td>Dedicated For </td>
                          <td>{selectedCar?.dedicated_for_name}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Initial Milage</td>
                        <td>{selectedCar?.initial_milage}</td>
                      </tr>

                      <tr>
                        <td>Status</td>
                        <td>
                          {(selectedCar.is_active ==="1") ? "Active" : "Not Active"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </label>
          </label>
        </div>
      )}
    </div>
  );
};

export default AllCar;
