import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

import {
    useGetApiHeader,
    useLogout,
    usePostApiHeader,
    useUpdateApiResponse
} from "../../helper/customHook";

const AllCarSchedule = ({ allSchedules, setAllSchedules, pageTitle }) => {
  // all state
  // user State
  const { user, error } = useSelector((state) => ({
    ...state.user,
  }));
  // logout state
  const [logout] = useLogout();

  // get Header State
  const [getApiHeader] = useGetApiHeader();

  // post api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  const [startMilesReadingError, setStartMilesReadingError] = useState("");
  const [endMilesReadingError, setEndMilesReadingError] = useState("");
  const [rejectNoteError, setRejectNoteError] = useState("");
  const [carScheduleDetails, setCarScheduleDetails] = useState({});

  // all error State
  const [fromDateError, setFromDaterror] = useState("");
  const [toDateError, setToDaterror] = useState("");
  const [pickupError, setPickupError] = useState("");
  const [destinationError, setDestinationError] = useState("");

  // disable state and loading State
  const [toDateDisable, setToDateDisable] = useState(true);
  const [modalField, setModalField] = useState(false);
  // loading State
  const [driverOptionLoading, setDriverOptionLoading] = useState([]);
  const [carOptionLoading, setCarOptionLoading] = useState([]);

  // field visable
  const [juridictionField, setJuridictionField] = useState(false);

  // array value state
  //  option State
  const [driverOptions, setDriverOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);

  // loading State
  const [loading, setLoading] = useState(true);

  // All use Ref
  const rejectNoteRef = useRef();
  const startMilesReadingRef = useRef();
  const endMilesReadingRef = useRef();
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const pickupRef = useRef();
  const destinationRef = useRef();
  const purposeRef = useRef();
  const carRef = useRef();
  const driverRef = useRef();
  const apprDistRef = useRef();
  const juriDecRef = useRef();
  const descriptionRef = useRef();

  // all use Effect

  // useEffect(() => {
  //   // for admin and chaiman
  //   const userId = user[0].data.user_profile_id;
  //   if (
  //     user[0].data.approval_level === "2" ||
  //     user[0].data.approval_level === "1"
  //   ) {
  //     fetch("https://kdaback.nescostore.com/api/all-trip-infos", getApiHeader)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setAllSchedules(data);
  //         setLoading(false);
  //       });
  //   }
  //   // for officer
  //   else if (
  //     user[0].data.approval_level === "3" &&
  //     user[0].data.staff_cat_id === "1"
  //   ) {
  //     fetch(
  //       `https://kdaback.nescostore.com/api/all-trip-infos-officer/${userId}`,
  //       getApiHeader
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setAllSchedules(data);
  //         setLoading(false);
  //       });
  //   }
  //   // for driver
  //   else if (
  //     user[0].data.approval_level === "3" &&
  //     user[0].data.staff_cat_id === "2"
  //   ) {
  //     fetch(
  //       `https://kdaback.nescostore.com/api/all-trip-infos-driver/${userId}`,
  //       getApiHeader
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setAllSchedules(data);
  //         setLoading(false);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-cars", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setCarOptions(data);
          setCarOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-drivers", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setDriverOptions(data);
          setDriverOptionLoading(false);
        } else {
          logout();
        }
      });
  }, []);

  const handleRejectNote = () => {
    const rejectNote = rejectNoteRef?.current?.value?.trim();
    if (rejectNote.length) {
      setRejectNoteError("");
    } else {
      setRejectNoteError("This Field can not be Empty");
    }
  };

  const handleStartMilesReading = () => {
    const milesReading = startMilesReadingRef.current.value;
    if (milesReading.length) {
      if (isNaN(milesReading)) {
        setStartMilesReadingError("miles must be a number");
      } else {
        setStartMilesReadingError("");
      }
    } else {
      setStartMilesReadingError("This Field can not be Empty");
    }
  };
  const handleEndMilesReading = () => {
    const milesReading = endMilesReadingRef.current.value;
    if (milesReading.length) {
      if (isNaN(milesReading)) {
        setEndMilesReadingError("miles must be a number");
      } else {
        setEndMilesReadingError("");
      }
    } else {
      setEndMilesReadingError("This Field can not be Empty");
    }
  };

  const handleCarSchedule = (id) => {
    setCarScheduleDetails({});
    setModalField(true);
    fetch(
      `https://kdaback.nescostore.com/api/edit-trip-info/${id}`,
      getApiHeader
    )
      .then((res) => res.json())
      .then((data) => {
        setCarScheduleDetails(data);
        if (data.trip_purpose_id === "2") {
          setJuridictionField(true);
        } else {
          setJuridictionField(false);
        }
      });
  };

  const handleFromDate = () => {
    const fromDate = fromDateRef.current.value;
    const dateTime = new Date(fromDate);
    const nowTime = Date.now();

    if (nowTime > dateTime.getTime()) {
      setFromDaterror("From date must be later than today");
      setToDateDisable(true);
      toDateRef.current.value = "";
    } else {
      setFromDaterror("");
      setToDateDisable(false);
    }
  };
  const handleToDate = () => {
    const fromDate = fromDateRef.current.value;
    const toDate = toDateRef.current.value;
    const fromDateTime = new Date(fromDate);
    const toDateTime = new Date(toDate);

    const link = `https://www.google.com/car?from=${fromDate.toString()}to=${toDate.toString()}`;
    

    if (fromDateTime.getTime() > toDateTime.getTime()) {
      setToDaterror("To date must be later than from date!");
    } else {
      setToDaterror("");
    }
  };
  const handlePickup = () => {
    const pickup = pickupRef.current.value;
    if (pickup.length) {
      setPickupError("");
    } else {
      setPickupError("This Field cannot be empty");
    }
  };
  const handleDestination = () => {
    const destination = destinationRef.current.value;
    if (destination.length) {
      setDestinationError("");
    } else {
      setDestinationError("This Field cannot be empty");
    }
  };
  const handlePurpose = () => {
    const purpose = purposeRef.current.value;
    
    if (purpose === "2") {
      setJuridictionField(true);
    } else {
      setJuridictionField(false);
    }
  };
  const handleAddSchedule = async () => {
    const destinition_location_id = destinationRef.current.value;
    
    const created_by = user[0]?.data?.id.toString();
    const from_date = fromDateRef.current.value;
    const to_date = toDateRef.current.value;
    const officer_id = user[0]?.data?.id.toString();
    const car_info_id = carRef.current.value;
    const car_driver_id = driverRef.current.value;
    const pick_location_id = pickupRef.current.value;
    const trip_purpose_id = purposeRef.current.value;
    const tentative_travel_distance = apprDistRef.current.value;
    const description = descriptionRef.current.value;

    let newSchedule = {
      from_date,
      to_date,
      officer_id,
      car_info_id,
      car_driver_id,
      pick_location_id,
      destinition_location_id,
      tentative_travel_distance,
      created_by,
      description,
      trip_purpose_id,
    };

    if (trip_purpose_id === "2") {
      const trip_jurisdiction_id = juriDecRef.current.value;
      newSchedule = { ...newSchedule, ...{ trip_jurisdiction_id } };
    }

    
  };

  const handleStartTrip = async (trip_info_id) => {
    const meter_reading = startMilesReadingRef.current.value;
    const startTripObj = { trip_info_id, meter_reading };
    
    const updatedTrip = { ...carScheduleDetails, trip_status_id: "5" };

    fetch(
      "https://kdaback.nescostore.com/api/start-trip",
      await handlePostApiHeader(startTripObj)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/ongoingtrips");
        if (flag) {
          let updatedTripInfos = [];
          allSchedules.map((s) => {
            if (s.trip_info_id === trip_info_id) {
              updatedTripInfos.push(updatedTrip);
            } else {
              updatedTripInfos.push(s);
            }
          });

          setAllSchedules(updatedTripInfos);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };
  const handleEndTrip = async (trip_info_id) => {
    const meter_reading = endMilesReadingRef.current.value;
    const endTripObj = { trip_info_id, meter_reading };
    
    const updatedTrip = { ...carScheduleDetails, trip_status_id: "6" };

    fetch(
      "https://kdaback.nescostore.com/api/stop-trip",
      await handlePostApiHeader(endTripObj)
    )
      .then((res) => res.json())
      .then(async (data) => {
         ;
        const flag = await handleUpdateResponse(data, "/completedtrips");
        if (flag) {
          let updatedTripInfos = [];
          allSchedules.map((s) => {
            if (s.trip_info_id === trip_info_id) {
              updatedTripInfos.push(updatedTrip);
            } else {
              updatedTripInfos.push(s);
            }
          });

          setAllSchedules(updatedTripInfos);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };
  const handleApprove = async (trip_info_id, tripInfo) => {
    const confirmStatus = await window.confirm(
      "Are you sure approve this request?"
    );
    const user_profile_id = user[0].data.user_profile_id;
    const approval_level = user[0].data.approval_level;
    const aproveObj = { user_profile_id, approval_level, trip_info_id };
    
    const newStatus = parseInt(tripInfo.trip_status_id) + 1;
    const updatedTrip = { ...tripInfo, trip_status_id: newStatus.toString() };
    
    if (confirmStatus) {
      fetch(
        "https://kdaback.nescostore.com/api/approve-trip",
        await handlePostApiHeader(aproveObj)
      )
        .then((res) => res.json())
        .then(async (data) => {
           ;
          const flag = await handleUpdateResponse(data, "/requestedtrips");
          if (flag) {
            let updatedTripInfos = [];
            allSchedules.map((s) => {
              if (s.trip_info_id === trip_info_id) {
                updatedTripInfos.push(updatedTrip);
              } else {
                updatedTripInfos.push(s);
              }
            });

            setAllSchedules(updatedTripInfos);
            setModalField(false);
          }
        })

        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const handleReject = async (trip_info_id) => {
    const reject_note = rejectNoteRef.current.value;
    const user_profile_id = user[0].data.user_profile_id;
    const approval_level = user[0].data.approval_level;
    const rejectObj = {
      user_profile_id,
      approval_level,
      reject_note,
      trip_info_id,
    };

    
    const updatedTrip = { ...carScheduleDetails, trip_status_id: "7" };

    fetch(
      "https://kdaback.nescostore.com/api/reject-trip",
      await handlePostApiHeader(rejectObj)
    )
      .then((res) => res.json())
      .then(async (data) => {
         ;
        const flag = await handleUpdateResponse(data, "/requestedtrips");
        if (flag) {
          let updatedTripInfos = [];
          allSchedules.map((s) => {
            if (s.trip_info_id === trip_info_id) {
              updatedTripInfos.push(updatedTrip);
            } else {
              updatedTripInfos.push(s);
            }
          });

          setAllSchedules(updatedTripInfos);
          setModalField(false);
        }
      })

      .catch((error) => {
        console.log("error:", error);
      });
  };

  // all button active or deactive function

  const handleAprovePermissonButton = (statusId, officerId) => {
    if (statusId === "2" && user[0].data.approval_level === "2") {
      return true;
    }
    if (statusId === "3" && user[0].data.approval_level === "1") {
      return true;
    }
    if (statusId === "1" && user[0].data.user_profile_id === officerId) {
      return true;
    } else {
      return false;
    }
  };
  const handleEditButton = (statusId, officerId) => {
    if (
      parseInt(statusId) <= 1 &&
      user[0].data.staff_cat_id === "1" &&
      user[0].data.user_profile_id === officerId &&
      false
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleStartTripButton = (statusId, officerId, from_date) => {
    const fiveMinuteBefore = new Date(Date.now() - 5000 * 60);
    const tripTime = new Date(Date.parse(from_date));

    if (
      parseInt(statusId) === 4 &&
      officerId === user[0].data.user_profile_id
    ) {
      return true;
      // if(fiveMinuteBefore.getTime()>=tripTime.getTime()){
      // //   return true
      // // }
    } else {
      return false;
    }
  };
  const handleEndTripButton = (statusId, officerId) => {
    if (
      parseInt(statusId) === 5 &&
      officerId === user[0].data.user_profile_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRejectReason = (trip_status_id) => {
    if (
      trip_status_id === "7" ||
      trip_status_id === "8" ||
      trip_status_id === "9"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      name: "Vehicle Number",
      selector: (row) => row.vehicle_no,
      sortable: true,
    },
    {
      name: "Officer",
      selector: (row) => row.officer_name,
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) => row.driver_name,
      sortable: true,
    },
    {
      name: "From Date and Time",
      selector: (row) =>
        moment(row?.from_date?.toString()).format("DD/MM/YYYY, h:mm A"),
      sortable: true,
    },
    {
      name: "To Date and Time",
      selector: (row) =>
        moment(row?.to_date?.toString()).format("DD/MM/YYYY, h:mm A"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.trip_status_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          className="text-sm flex flex-auto"
          onClick={() => handleCarSchedule(row.trip_info_id)}
        >
          {handleEditButton(row.trip_status_id, row.officer_id) && (
            <label
              title="End"
              className="btn-link px-1 modal-button"
              for="edit-modal"
            >
              <i title="Edit" class="fa-regular fa-pen-to-square"></i>{" "}
            </label>
          )}
          {handleAprovePermissonButton(row.trip_status_id, row.officer_id) && (
            <label
              className="btn-link px-1"
              onClick={() => handleApprove(row?.trip_info_id, row)}
            >
              <i title="Approve" class="fa-solid fa-check"></i>
            </label>
          )}

          {handleAprovePermissonButton(row.trip_status_id, row.officer_id) && (
            <label className="btn-link px-2" for="reject-modal">
              <i title="Reject" class="fa-solid fa-xmark"></i>
            </label>
          )}
          <label
            className="btn-link px-1 modal-button"
            for="car-schedule-details"
          >
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
          {handleStartTripButton(
            row.trip_status_id,
            row.officer_id,
            row.from_date
          ) && (
            <label className="btn-link px-1 modal-button" for="start-modal">
              <i title="Start" class="fa-solid fa-play"></i>
            </label>
          )}
          {handleEndTripButton(row.trip_status_id, row.officer_id) && (
            <label
              title="End"
              className="btn-link px-1 modal-button"
              for="end-modal"
            >
              <i class="fa-solid fa-square"></i>
            </label>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold">{pageTitle}</h2>
      <DataTable
        pagination
        columns={columns}
        data={allSchedules}
        // progressPending={loading}
        selectableRows
      />
      {modalField && (
        <>
          {/* car schedule Details modal */}
          <div>
            <input
              type="checkbox"
              id="car-schedule-details"
              class="modal-toggle"
            />
            <label for="car-schedule-details" class="modal cursor-pointer">
              <label
                class="modal-box relative w-5/12 max-w-5xl text-justify"
                for=""
              >
                <label
                  htmlFor="car-schedule-details"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <h3 class="text-lg font-bold">Details About This Request</h3>

                <div className="py-4">
                  <div className="overflow-x-auto">
                    <table className="table table-zebra overflow-x-scroll w-full">
                      <tbody>
                        <tr>
                          <td>Vehicle No</td>
                          <td>{carScheduleDetails?.vehicle_no}</td>
                        </tr>

                        <tr>
                          <td>Requested By</td>
                          <td>{carScheduleDetails?.officer_name}</td>
                        </tr>
                        <tr>
                          <td>Driver</td>
                          <td>{carScheduleDetails?.driver_name}</td>
                        </tr>
                        <tr>
                          <td>From date</td>
                          <td>
                            {moment(carScheduleDetails?.from_date).format(
                              "DD/MM/YYYY, h:mm A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>To Date</td>
                          <td>
                            {moment(carScheduleDetails?.to_date).format(
                              "DD/MM/YYYY, h:mm A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Pickup</td>
                          <td>{carScheduleDetails.pick_location_id}</td>
                        </tr>
                        <tr>
                          <td>Destination</td>
                          <td>{carScheduleDetails.destinition_location_id}</td>
                        </tr>
                        {carScheduleDetails.trip_status_id === "6" ? (
                          <>
                            <tr>
                              <td>Distance Traveled</td>
                              <td>
                                {parseInt(carScheduleDetails.end_milage) -
                                  parseInt(carScheduleDetails.start_milage)}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td>Tentative Distance</td>
                              <td>
                                {carScheduleDetails.tentative_travel_distance}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td>Created By</td>
                          <td>{carScheduleDetails.created_by_name}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>{carScheduleDetails.trip_status_name}</td>
                        </tr>
                        {handleRejectReason(
                          carScheduleDetails.trip_status_id
                        ) && (
                          <tr>
                            <td>Reject Reason</td>
                            <td>{carScheduleDetails.reject_note}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* reject modal  */}
          <div>
            <input type="checkbox" id="reject-modal" class="modal-toggle" />
            <label for="reject-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-3/6 max-w-5xl text-justify"
                for="reject-modal"
              >
                <label
                  htmlFor="reject-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <h3 class="text-lg font-bold">Reject This Request</h3>

                <div className="py-4">
                  <div className="overflow-x-auto">
                    <div className="mx-auto my-4">
                      <div class="form-control my-4">
                        <label className="input-group">
                          <span className="w-2/6">Rejection Note</span>
                          <textarea
                            type="text"
                            placeholder="Why Reject?"
                            class="input-bordered w-4/6"
                            ref={rejectNoteRef}
                            onBlur={handleRejectNote}
                          ></textarea>
                        </label>
                      </div>
                      {rejectNoteError && (
                        <div className="alert shadow-lg">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="stroke-current flex-shrink-0 h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                              />
                            </svg>
                            <span>{rejectNoteError}</span>
                          </div>
                        </div>
                      )}
                      <div class="form-control my-4">
                        <label className="input-group">
                          <button
                            onClick={() => {
                              handleReject(carScheduleDetails?.trip_info_id);
                            }}
                            className="btn w-32 mx-auto btn-danger"
                          >
                            reject
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>

          {/* {Strat trip Modal} */}
          <div>
            <input type="checkbox" id="start-modal" class="modal-toggle" />
            <label for="start-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-3/6 max-w-5xl text-justify"
                for="start-modal"
              >
                <label
                  htmlFor="start-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>

                <div className="w-2/4 mx-auto">
                  <div className="mx-auto my-4">
                    <div class="form-control my-4">
                      <label className="input-group">
                        <span className="w-2/6">Miles Reading</span>
                        <input
                          type="text"
                          placeholder="Initial Reading of miles"
                          class="input input-bordered w-4/6"
                          ref={startMilesReadingRef}
                          onBlur={handleStartMilesReading}
                        />
                      </label>
                    </div>
                    {startMilesReadingError && (
                      <div className="alert shadow-lg">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                          <span>{startMilesReadingError}</span>
                        </div>
                      </div>
                    )}
                    <div class="form-control my-4">
                      <label className="input-group">
                        <button
                          onClick={() =>
                            handleStartTrip(carScheduleDetails?.trip_info_id)
                          }
                          className="btn w-32 mx-auto"
                        >
                          Start
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* {End trip Modal} */}
          <div>
            <input type="checkbox" id="end-modal" class="modal-toggle" />
            <label for="end-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-3/6 max-w-5xl text-justify"
                for="end-modal"
              >
                <label
                  htmlFor="end-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>

                <div className="w-2/4 mx-auto">
                  <div className="mx-auto my-4">
                    <div className="form-control my-4">
                      <label className="input-group">
                        <span className="w-2/6">Miles Reading</span>
                        <input
                          type="text"
                          ref={endMilesReadingRef}
                          placeholder="Final Miles Reading"
                          className="input input-bordered w-4/6"
                          onBlur={handleEndMilesReading}
                        />
                      </label>
                    </div>
                    {endMilesReadingError && (
                      <div className="alert shadow-lg">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                          <span>{endMilesReadingError}</span>
                        </div>
                      </div>
                    )}

                    <div className="form-control my-4">
                      <label className="input-group">
                        <button
                          onClick={() =>
                            handleEndTrip(carScheduleDetails?.trip_info_id)
                          }
                          className="btn w-32 mx-auto"
                        >
                          End
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
          {/* edit modal */}
          <div>
            <input type="checkbox" id="edit-modal" class="modal-toggle" />
            <label for="edit-modal" class="modal cursor-pointer">
              <label
                class="modal-box relative w-5/6 max-w-5xl text-justify"
                for=""
              >
                <label
                  htmlFor="edit-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-4"
                >
                  ✕
                </label>
                <div className="py-4">
                  <div className="overflow-x-auto">
                    <div>
                      <h3 className="text-xl font-semibold my-5">
                        Request For Vehicle{" "}
                      </h3>
                      <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-3">
                        <div className="">
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">From Date Time</span>
                              <input
                                type="datetime-local"
                                className="input w-4/6 input-bordered"
                                ref={fromDateRef}
                                key={carScheduleDetails?.from_date}
                                defaultValue={carScheduleDetails?.from_date}
                                onBlur={handleFromDate}
                              />
                            </label>
                          </div>
                          {fromDateError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{fromDateError}</span>
                              </div>
                            </div>
                          )}
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">To Date Time</span>
                              <input
                                type="datetime-local"
                                className="input w-4/6 input-bordered"
                                key={carScheduleDetails?.to_date}
                                defaultValue={carScheduleDetails?.to_date}
                                ref={toDateRef}
                                onBlur={handleToDate}
                                disabled={toDateDisable}
                              />
                            </label>
                          </div>
                          {toDateError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{toDateError}</span>
                              </div>
                            </div>
                          )}
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Vehicle</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={carRef}
                                key={carScheduleDetails?.car_info_id}
                                defaultValue={carScheduleDetails?.car_info_id}
                              >
                                <option disabled selected>
                                  {" "}
                                  ------ Select Vehicle ------{" "}
                                </option>
                                {carOptionLoading ? (
                                  <option disabled>Loading ....</option>
                                ) : (
                                  carOptions.map((car) => (
                                    <option value={car.car_info_id}>
                                      {" "}
                                      {car.vehicle_no}{" "}
                                    </option>
                                  ))
                                )}
                              </select>
                            </label>
                          </div>
                          <div className="form-control">
                            <label className="input-group">
                              <span className="w-2/6">Driver</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={driverRef}
                                key={carScheduleDetails?.car_driver_id}
                                defaultValue={carScheduleDetails?.car_driver_id}
                              >
                                <option disabled selected>
                                  {" "}
                                  ------ Select Driver ------{" "}
                                </option>
                                {driverOptionLoading ? (
                                  <option disabled>Loading ....</option>
                                ) : (
                                  driverOptions.map((driver) => (
                                    <option value={driver.user_id}>
                                      {" "}
                                      {driver.full_name}{" "}
                                    </option>
                                  ))
                                )}
                              </select>
                            </label>
                          </div>
                        </div>
                        <div className="">
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Pickup Point</span>
                              <input
                                type="text"
                                className="input w-4/6 input-bordered"
                                ref={pickupRef}
                                onBlur={handlePickup}
                                key={carScheduleDetails.pick_location_id}
                                defaultValue={
                                  carScheduleDetails.pick_location_id
                                }
                              />
                            </label>
                          </div>
                          {pickupError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{pickupError}</span>
                              </div>
                            </div>
                          )}
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Destination</span>

                              <input
                                type="text"
                                className="input w-4/6 input-bordered"
                                ref={destinationRef}
                                onBlur={handleDestination}
                                key={carScheduleDetails.destinition_location_id}
                                defaultValue={
                                  carScheduleDetails.destinition_location_id
                                }
                              />
                            </label>
                          </div>
                          {destinationError && (
                            <div className="alert shadow-lg">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stroke-current flex-shrink-0 h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                                <span>{destinationError}</span>
                              </div>
                            </div>
                          )}
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">
                                Approximate Distance
                              </span>
                              <input
                                type="text"
                                className="input w-4/6 input-bordered"
                                placeholder="Approximate Travel Distance in KM"
                                ref={apprDistRef}
                                key={
                                  carScheduleDetails?.tentative_travel_distance
                                }
                                defaultValue={
                                  carScheduleDetails?.tentative_travel_distance
                                }
                              />
                            </label>
                          </div>
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Purpose</span>
                              <select
                                className="select select-bordered w-4/6"
                                ref={purposeRef}
                                onChange={handlePurpose}
                                key={carScheduleDetails?.trip_purpose_id}
                                defaultValue={
                                  carScheduleDetails?.trip_purpose_id
                                }
                              >
                                <option value="1">Official</option>
                                <option value="2">Personal</option>
                              </select>
                            </label>
                          </div>
                          {juridictionField && (
                            <>
                              <div className="form-control my-3">
                                <label className="input-group">
                                  <span className="w-2/6">Jurisdiction</span>
                                  <select
                                    className="select select-bordered w-4/6"
                                    ref={juriDecRef}
                                    key={
                                      carScheduleDetails?.trip_jurisdiction_id
                                    }
                                    defaultValue={
                                      carScheduleDetails?.trip_jurisdiction_id
                                    }
                                  >
                                    <option value="1">
                                      Inside Of KDA Jurisdiction
                                    </option>
                                    <option value="2">
                                      Outside Of KDA Jurisdiction
                                    </option>
                                  </select>
                                </label>
                              </div>
                            </>
                          )}
                          <div className="form-control my-3">
                            <label className="input-group">
                              <span className="w-2/6">Description</span>
                              <textarea
                                style={{ resize: "none" }}
                                type="text"
                                placeholder="Description "
                                ref={descriptionRef}
                                className="input w-4/6 input-bordered h-28"
                                key={carScheduleDetails?.description}
                                defaultValue={carScheduleDetails?.description}
                              ></textarea>
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={handleAddSchedule}
                        className="btn btn-primary my-10 mx-auto lg:w-44"
                      >
                        ADD Schedule
                      </button>
                    </div>
                  </div>
                </div>
              </label>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default AllCarSchedule;
