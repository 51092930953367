import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetApiHeader, useLogout, usePostApiHeader, useUpdateApiResponse } from "../../helper/customHook";

const AllOwner = () => {
  // all state
  // get Header State
  const [getApiHeader] = useGetApiHeader();

  
  // update api
  const [handlePostApiHeader] = usePostApiHeader();
  const [handleUpdateResponse] = useUpdateApiResponse();

  // logout state
  const [logout] = useLogout();


  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState({});
  const [ownerLoading, setOwnerLoading] = useState(true);
  const [modalField,setModalField] = useState(false)

  // all ref
  const nameRef = useRef();
  const addressRef = useRef();
  const contactNoRef = useRef();
  const activityRef = useRef();

  useEffect(() => {
    fetch("https://kdaback.nescostore.com/api/all-owners", getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        if (!data?.message) {
          setOwners(data);
          setOwnerLoading(false);
        } else {
          logout();
        }
      });
  }, [getApiHeader]);

  // all function

  const handleSelectedOwner = (id) => {
    setModalField(true)
    setSelectedOwner({})
    fetch(`https://kdaback.nescostore.com/api/edit-owner/${id}`, getApiHeader)
      .then((res) => res.json())
      .then((data) => {
        setSelectedOwner(data)
      });
  };
  const handleOwnerEdit = async (owner_id) => {
    const owner_name = nameRef.current.value || selectedOwner.owner_name;
    const address = addressRef.current.value || selectedOwner.address;
    const contact_no = contactNoRef.current.value || selectedOwner.contact_no;
    const is_active = activityRef.current.value;
    const user_id = selectedOwner.user_id
     

    const updatedOwner = { owner_name, address, contact_no, is_active,user_id,owner_id };
    fetch(
      "https://kdaback.nescostore.com/api/update-owner", await handlePostApiHeader(updatedOwner)
    )
      .then((res) => res.json())
      .then(async (data) => {
        const flag = await handleUpdateResponse(data, "/allowner");
        let updatedOwners = []
        if (flag) {
          
            owners.map((o)=>{
              if(o.owner_id === owner_id){
                updatedOwners.push(updatedOwner)
              }
              else{
                updatedOwners.push(o)
              }
            })
          
          setOwners(updatedOwners);
          setModalField(false)
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  const columns = [
    {
      name: "Owner Name",
      selector: (row) => row.owner_name,
      sortable: true,
    },
    {
      name: "Owner Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row.contact_no,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => {
        if (row.is_active==="1") {
          return "Yes";
        } else {
          return "No";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          onClick={() => handleSelectedOwner(row.owner_id)}
          className="text-base"
        >
          <label className=" btn-link mx-1" for="edit-owner">
            <i title="Edit" class="fa-regular fa-pen-to-square"></i>
          </label>
          <label className="btn-link mx-2 modal-button" for="owner-details">
            <i title="Details" class="fa-solid fa-info"></i>
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2 className="text-xl my-8 text-center font-semibold"> All Owners</h2>
      <DataTable
        pagination
        columns={columns}
        data={owners}
        progressPending={ownerLoading}
        selectableRows
      />
      {/* edit option  */}
      {modalField&&<>
      <div>
        <input type="checkbox" id="edit-owner" class="modal-toggle" />
        <label for="car-schedule-details" class="modal cursor-pointer">
          <label class="modal-box relative w-3/6 max-w-5xl text-justify" for="">
            <label
              htmlFor="edit-owner"
              className="btn btn-sm btn-circle absolute right-2 top-4"
            >
              ✕
            </label>
            <div className="py-4">
              <div className="overflow-x-auto">
                <div>
                  <h2 className="text-xl my-4 text-center font-semibold">
                    Edit Owner Info
                  </h2>
                  <div className="mx-auto lg:w-1/2">
                    <div className="form-control my-3">
                      <label className="input-group">
                        <span className="w-2/6">Name</span>
                        <input
                          type="text"
                          ref={nameRef}
                          defaultValue={selectedOwner.owner_name}
                          className="input w-4/6 input-bordered"
                        />
                      </label>
                    </div>
                    <div className="form-control my-3">
                      <label className="input-group">
                        <span className="w-2/6">Address</span>
                        <input
                          type="text"
                          ref={addressRef}
                         defaultValue={selectedOwner.address}
                          className="input w-4/6 input-bordered"
                        />
                      </label>
                    </div>
                    <div className="form-control my-3">
                      <label className="input-group">
                        <span className="w-2/6">Mobile Number</span>
                        <input
                          type="text"
                          ref={contactNoRef}
                         defaultValue={selectedOwner.contact_no}
                          className="input w-4/6 input-bordered"
                        />
                      </label>
                    </div>

                    <div className="form-control my-3">
                      <label className="input-group">
                        <span className="w-2/6">Activity</span>
                        <select
                          className="select select-bordered w-4/6"
                          ref={activityRef}
                          key={selectedOwner.is_active}
                          defaultValue={selectedOwner.is_active}
                        >
                          <option value="1">Active</option>

                          <option value="0">Not Active</option>
                        </select>
                      </label>
                    </div>
                    <button
                      className="btn btn-primary my-10 mx-auto lg:w-full"
                      onClick={() => handleOwnerEdit(selectedOwner.owner_id)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </label>
      </div>
      {/* details view  */}
      <div>
        <input type="checkbox" id="owner-details" class="modal-toggle" />
        <label for="owner-details" class="modal cursor-pointer">
          <label class="modal-box relative w-2/6 max-w-5xl text-justify" for="">
            <label
              htmlFor="owner-details"
              className="btn btn-sm btn-circle absolute right-2 top-4"
            >
              ✕
            </label>
            <h3 class="text-lg text-center font-bold">
              Details About This Owner
            </h3>

            <div className="py-4">
              <div className="overflow-x-auto">
                <table className="table table-zebra overflow-x-scroll w-full">
                  <tbody>
                    <tr>
                      <td>Owner Name</td>
                      <td>{selectedOwner?.owner_name}</td>
                    </tr>
                    <tr>
                      <td>Contact Number</td>
                      <td>{selectedOwner?.contact_no}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{selectedOwner?.address}</td>
                    </tr>

                    <tr>
                      <td>Status</td>
                      <td>
                        {selectedOwner.is_active ? "Active" : "Not Active"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </label>
        </label>
      </div>
      </>}
    </div>
  );
};

export default AllOwner;
